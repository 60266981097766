import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import shared from 'app/shared';
import AddOrderPopup from './addOrderPopup';
import EditOrder from './editOrder';
import { getAll } from '../../../actions/crudActions';
import crudTypes from '../../../config/crudTypes';
import { paginate } from '../../../config/helpers/paginator';
import { getSortState, sortData } from '../../../config/helpers/sorter';
import { filterData } from '../../../config/helpers/searcher';
import { genericFilter } from '../../shared/helpers/filterFunctions';
import MobileFilter from '../../shared/views/mobileFilter/mobileFilter';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';
import SimpleFilterBar from './filterBar';

import { ORDER_STATUS, ORDER_PAYMENT_METHODS } from '../constants';

const {
  views: {
    Table,
    Button,
    Paginator,
    Page,
    Input,
    Icons,
    Card,
    FilterableTableStructure,
  },
  helpers: { resetScroll, colors },
} = shared;

const {
  StatsSectionWrapper,
  ActionBarWrapper,
  SearchWrapper,
  TableWrapper,
  PaginatorWrapper,
} = FilterableTableStructure;

const initialState = {
  filters: {
    selectedPeriod: 'today',
    selectedPaymentStatus: null,
  },
  customDate: {startDate: null, endDate: null},
  pageSize: 10,
  currentPage: 1,
  sort: {
    key: 'orderDate',
    order: -1,
  },
  search: '',
  filteredOrders: '',
  redirect: false,
  getOrdersStatus: ''
};

const TableWrapperTablet = styled(Card)`
  display: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin-top: 25px;
  }
  @media all and (max-width: 768px) {
    display: block;
    margin-top: 25px;
  }
`;

const ButtonWrapper = styled.div`
    @media all and (max-width: 768px) {
      display: none;
    }
`;

class OrdersPage extends React.Component {
  state = initialState;

  componentWillMount(){
    resetScroll();
    this.props.getAll(crudTypes.order, this.setGetOrderStatus);
    this.props.getAll(crudTypes.product, this.setGetOrderStatus);
    this.props.getAll(crudTypes.client, this.setGetOrderStatus);
  }

  setGetOrderStatus = (status) => {
    this.setState({getOrdersStatus: status})
  }

  updateSelectedPeriod = selectedPeriod => {
    if (selectedPeriod !== 'custom') {
      this.setState({
        customDate: {
          startDate: null,
          endDate: null
        },
      });
    }
    this.setState(prevState => ({ filters: { ...prevState.filters, selectedPeriod }, currentPage: 1 }));
  };

  updateCustomDate = customDate => {
    this.setState({customDate: {startDate: customDate.startDate, endDate: customDate.endDate }, currentPage: 1 })
  }

  toggleAddPopup = () => this.setState({ showEditPopup: !this.state.showEditPopup });

  clearFilters = () => {
    this.setState({
      filters: initialState.filters,
      customDate: {startDate: null, endDate: null},
      currentPage: 1,
    });
  };

  updatePaginatorPage = currentPage => {
    this.setState({ currentPage });
  };

  updateSortState = key => {
    this.setState(prevState => ({
      sort: getSortState(prevState.sort, key),
    }));
  };

  updateSelectedPaymentStatus = key => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        selectedPaymentStatus: key,
      },
      currentPage: 1,
    }));
  }

  getTableData = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'FULL NAME',
        renderer: ({ clientName, clientLastName }) => `${clientName} ${clientLastName}`,
        sortable: true,
      },
      {
        label: 'QUANTITY',
        key: 'orderProducts',
        renderer: ({ orderProducts }) => {
          const qty = (orderProducts || []).reduce((qty, order) => qty + (order.qty || 0), 0);
          return (
            <Button.Button
              text={qty}
              size={Button.ButtonSize.medium}
              style={{
                backgroundColor: colors.lightBlue,
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '14px',
                textAlign: 'center',
                color: colors.blue,
              }}
            />
          );
        },
        sortable: true,
      },
      {
        label: 'VALUE',
        key: 'totalValue',
        renderer: ({ totalValue }) => `$${totalValue}`,
        sortable: true,
      },
      {
        label: 'PAYMENT STATUS',
        key: 'orderStatus',
        renderer: ({ orderStatus }) => (ORDER_STATUS[orderStatus]),
        sortable: true,
      },
      {
        label: 'PAYMENT METHOD',
        key: 'paymentMethod',
        renderer: ({ paymentMethod }) => (ORDER_PAYMENT_METHODS[paymentMethod]),
        sortable: true,
      },
      {
        label: 'DATE CREATED',
        key: 'orderDate',
        sortable: true,
        renderer: ({ orderDate }) => orderDate && moment(orderDate).format('YYYY-MM-DD HH:mm'),
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'action',
        sortable: false,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditOrder.path}/${id}`} >
              <Button.Button
                style={{ fontWeight: 600 }}
                text={'View'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  getTableDataTablet = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'FULL NAME',
        renderer: ({ clientName, clientLastName }) => `${clientName} ${clientLastName}`,
        sortable: true,
      },
      {
        label: 'QUANTITY',
        key: 'orderProducts',
        renderer: ({ orderProducts }) => {
          const qty = (orderProducts || []).reduce((qty, order) => qty + (order.qty || 0), 0);
          return (
            <Button.Button
              text={qty}
              size={Button.ButtonSize.medium}
              style={{
                backgroundColor: colors.lightBlue,
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '14px',
                textAlign: 'center',
                color: colors.blue,
              }}
            />
          );
        },
        sortable: true,
      },
      {
        label: 'VALUE',
        key: 'totalValue',
        renderer: ({ totalValue }) => `$${totalValue}`,
        sortable: true,
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'action',
        sortable: false,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditOrder.path}/${id}`} >
              <Button.Button
                style={{ fontWeight: 600 }}
                text={'View'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });


  toggle = () => this.setState({ toggleValue: !this.state.toggleValue });

  setSearchValue = val => {
    this.setState({ search: val, currentPage: 1 });
  };

  createExportCSV = (data) => {
    return () => {
      CSVFileExporter('orders.csv', data);
    };
  };

  render() {
    const {
      state: {
        filters: { selectedPeriod, selectedPaymentStatus },
        currentPage,
        pageSize,
        showEditPopup,
        search,
        sort,
        customDate,
      },
      updateSelectedPeriod,
      clearFilters,
      updatePaginatorPage,
      updateCustomDate,
      toggleAddPopup,
      createExportCSV,
      updateSelectedPaymentStatus,
    } = this;

    const timeFiltered = genericFilter(
      selectedPeriod, 
      this.props.orders.data, 
      customDate, 
      "orderDate", 
      null);

    const statusFiltered = orderStatusFilter(timeFiltered, selectedPaymentStatus);
    const searchData = filterData(statusFiltered || [], search);
    const sortedData = sortData(searchData, sort);
    const paginatedData = paginate(sortedData, currentPage, pageSize);
    return (
      <Page>
        {toggleAddPopup && <AddOrderPopup show={showEditPopup} toggle={toggleAddPopup} />}
        <SimpleFilterBar
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
          updateCustomDate={updateCustomDate}
          customDate={customDate}
          selectedPaymentStatus={selectedPaymentStatus}
          updateSelectedPaymentStatus={updateSelectedPaymentStatus}
        />
        <MobileFilter
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
        />
        <StatsSectionWrapper>
          <ActionBarWrapper>
            <ButtonWrapper>
              <Button.Button
                color={colors.blue}
                text={'Add New'}
                style={{ marginRight: 5 }}
                onClick={toggleAddPopup}
              />
              <Button.WhiteButton
                color={colors.textColor}
                style={{ marginRight: 5 }}
                text={'Export CSV'}
                onClick={createExportCSV(sortedData)}
              />
            </ButtonWrapper>
            <SearchWrapper>
              <Input
                start={<Icons.Search />}
                placeholder={'Search'}
                height={33}
                onChange={e => this.setSearchValue(e.target.value)}
              />
            </SearchWrapper>
          </ActionBarWrapper>
          <TableWrapper>
            <Table.TableView data={this.getTableData(paginatedData.current)} />
          </TableWrapper>
          <TableWrapperTablet>
            <Table.TableView data={this.getTableDataTablet(paginatedData.current)} />
          </TableWrapperTablet>
          <PaginatorWrapper>
            <Paginator
              changePage={updatePaginatorPage}
              numberOfPages={paginatedData.pages}
              page={paginatedData.currentPage}
            />
          </PaginatorWrapper>
        </StatsSectionWrapper>
      </Page>
    );
  }
}

const orderStatusFilter = (data = [], selectedPaymentStatus) => {
  switch (selectedPaymentStatus) {
    case 'pending': return data.filter(item => [0, 4].includes(item.orderStatus));
    case 'payed': return data.filter(item => item.orderStatus === 1);
    case 'cancelled': return data.filter(item => item.orderStatus === 5);
    default:
      return data;
  }
};

OrdersPage.path = '/orders';

const mapStateToProps = state => ({
  orders: state.orders,
});

export default connect(
  mapStateToProps,
  { getAll},
)(OrdersPage);

export { OrdersPage };
