import React, { Component } from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

import BotIllustration from './botIllustration';
import Conversation from './conversation';

const {
  views: { Text:{H1:{H1,}, Paragraph:{P,},}, Page, Button, Toggle },
  helpers: { colors },
} = shared;

const Title = styled(H1)`
  color: ${colors.blue};
`;

const HeadSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  margin-left: 13px;
`;

const ToggleWrapper = styled.div`
  display: flex;
`;

const InfoWrapper = styled.div`
  flex-grow: 1;
  margin-top: 15px;
  max-width: 500px;
`;

const Description = styled(P)`
  color: ${colors.textColor};
`;

const DescriptionWrapper = styled.div`
  padding: 35px 0 40px;
`;

const BlueText = styled(P)`
  color: ${colors.blue};
`;

const ConversationWrapper = styled.div`
  padding-top: 120px;
`;

const ChatbotWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

class ChatBotPage extends Component {
  state = {
    toggleValue: false,
  };

  toggle = () => this.setState({ toggleValue: !this.state.toggleValue });

  render() {
    const {
      state: { toggleValue },
      toggle,
    } = this;

    return (
      <Page>
        <ChatbotWrapper>
          <HeadSection>
            <InfoWrapper>
              <TitleWrapper>
                <Title>Orderoo Chatbot</Title>
                <ToggleWrapper>
                  <Toggle
                    toggle={toggle}
                    show={toggleValue}
                    color={toggleValue === false ? colors.blue : colors.grey}
                  />
                  <ButtonWrapper>
                    <Button.Button
                      text={"Bot Enabled"}
                      color={toggleValue === false ? colors.green : colors.grey}
                      size={Button.ButtonSize.big}
                    />
                  </ButtonWrapper>
                </ToggleWrapper>
              </TitleWrapper>
              <DescriptionWrapper>
                <Description>
                  Your Bot will be created automatically after you add all categories and products
                  of your Restaurant on Orderoo CRM.
                </Description>
              </DescriptionWrapper>
              <BlueText>Follow a real conversation between a client and Orderoo Bot.</BlueText>
            </InfoWrapper>
            <div>
              <BotIllustration state={toggleValue === false ? 'awake' : 'sleep'} />
            </div>
          </HeadSection>
          <ConversationWrapper>
            <Conversation />
          </ConversationWrapper>
        </ChatbotWrapper>
      </Page>
    );
  }
}

ChatBotPage.path = '/chatbot';

export default ChatBotPage;
