import crudTypes from 'config/crudTypes';
import crudReducer from './crudReducer';

const crudProductReducer = crudReducer(crudTypes.product);
const initialState = {
  data: [],
  current: {},
};


export default (state = initialState, action) => {
  switch (action.type) {
    case `UPDATE_PRODUCT_STATUS_OK`:
      return {
        ...state,
        data: state.data.map(p => {
          if (p.id === action.data.id) {
            return action.data;
          }
          return p;
        }),
      };
    
    default:
      return crudProductReducer(state, action);
  }
};
