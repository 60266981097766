import React, { Component } from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

import { MARKETING_TABS } from '../constants';

import FilterBar from '../components/filterBar';
import SearchInput from '../components/searchInput';

const {
  views: {
    Page,
    TabBar,
    FilterableTableStructure: {
      SearchWrapper,
    },
  },
  helpers: { colors },
} = shared;

const MarketingViewWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 25px;
`;

const HeaderBarWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const MarketingTabBar = styled(TabBar)`
  flex: 1;
`;

const MarketingSearchWrapper = styled(SearchWrapper)`
  border-bottom: 1px solid ${colors.lightGrey};

  // display: ${props => props.shouldDisplay ? 'flex' : 'none'};
  
  @media all and (max-width: 768px) { 
    dispaly: none;
  }
`;

class MarketingPage extends Component {
  state = {
    filters: {
      search: '',
      orderMin: '',
      orderMax: '',
      revenueMin: '',
      revenueMax: '',
      customDate: {
        startDate: null,
        endDate: null,
      }
    },
    currentTab: MARKETING_TABS[0],
  };

  updateFilters = changedFilter => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        ...changedFilter,
      }
    }));
  }

  updateCustomDate = customDate => {
    if (customDate) {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          customDate: {
            startDate: customDate.startDate,
            endDate: customDate.endDate,
          },
        }
      }));
    }
  }

  setSearchValue = value => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        search: value,
      },
      currentPage: 1,
    }));
  }

  clearFilters = () => {
    this.setState({
      filters: {
        search: '',
        orderMin: '',
        orderMax: '',
        revenueMin: '',
        revenueMax: '',
        customDate: {
          startDate: null,
          endDate: null,
        }
      }
    })
  }

  updateCurrentTab = value => {
    const currentTab = MARKETING_TABS.find(tab => tab.value === value);
    if (currentTab) {
      this.setState({
        currentTab,
      });
    }
  }

  render() {
    const {
      state: {
        filters,
        currentTab,
      },
      updateFilters,
      updateCustomDate,
      clearFilters,
    } = this;

    const { component: CurrentTabView } = currentTab;

    return (
      <Page>
        <FilterBar
          {...filters}
          updateCustomDate={updateCustomDate}
          updateFilters={updateFilters}
          clearFilters={clearFilters}
        />
        <HeaderBarWrapper>
          <MarketingTabBar
            tabs={MARKETING_TABS}
            selectedElement={currentTab.value}
            updateSelectedElement={this.updateCurrentTab}
          />
          <MarketingSearchWrapper>
            <SearchInput search={filters.search} onSearchChanged={this.setSearchValue} />
          </MarketingSearchWrapper>
        </HeaderBarWrapper>
        <MarketingViewWrapper>
          <CurrentTabView filters={filters} />
        </MarketingViewWrapper>
      </Page>
    );
  }
}

export default MarketingPage;
