export default type => {
  const initialState = {
    data: [],
    current: {},
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case `GET_ALL_${type.tag}_OK`:
        return {
          data: [...action.data],
          current: { ...state.current },
        };
      case `GET_${type.tag}_BY_ID_OK`:
        return {
          data: [ ...state.data ],
          current: {...action.data} ,
        };
      case `POST_${type.tag}_OK`:
        return {
          data: [...state.data, action.data],
          current: { ...state.current },
        };
      case `PUT_${type.tag}_OK`:
        return {
          data: [...state.data.filter(x => x.id !== action.data.id), action.data],
          current: { ...state.current },
        };
      case `DELETE_${type.tag}_OK`:
        return {
          data: [ ...state.data.filter(x => x.id !== action.data) ],
          current: {},
        };
      default:
        return state;
    }
  };
};
