import React, { useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import shared from 'app/shared';
import addCategoryIllustration from 'assets/images/addCategoryIllustration.svg';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { add, getAll } from '../../../actions/crudActions';
import crudTypes from '../../../config/crudTypes';

const {
  views: { Modal, AddItemPopup, Button, Input, UploadInput, Icons},
  helpers: { colors },
} = shared;

const AddItemPopupWrapper = styled.div`
  width: 450px;
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    background: rgba(242,69,61, .2);
    color: #F2453D;
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1px 5px;
    display: ${props => props.show === 'ERROR' ? 'flex' : 'none'}
`;

const WarningWrapper = styled.div`
    width: 100%;
    display: flex;
    background: rgba(255,152,0, .2);
    color: #FF9800;;
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1px 5px;
    margin-top: 10px;
`;

const { useState } = React;

const AddProductPopup = props => {
  const [uri, setUri] = useState();
  const [postStatus, setPostStatus] = useState();

  const {toggle} = props;

  useEffect(() => {
    if (postStatus === 'OK') {
      toggle()
    }
  }, [postStatus, toggle])

  const changePostStatus = (status) => {
    setPostStatus(status);
  }

  const options = [];

  const getOptions = () =>
    props.categories.data && props.categories.data.length > 0
      ? props.categories.data.map(category => ({
          value: category.id,
          label: category.name,
        }))
      : options;

  if (!props.show) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        name: '',
        categoryId: props.preselectCategory,
        price: '',
        description: '',
      }}

      validationSchema={Yup.object().shape({
        name: Yup.string().required('Product Name is required'),
        categoryId: Yup.string().required('Category is required'),
        price: Yup.number('Price must be a number').required('Price is required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        let newValues;
        if(uri) {
           newValues = {
            name: values.name,
            categoryId: values.categoryId,
            price: values.price,
            description: values.description,
            imagePath: uri
          }
        } else {
          newValues = {
            name: values.name,
            categoryId: values.categoryId,
            price: values.price,
            description: values.description,
          };
        }

        props.add(crudTypes.product, newValues, changePostStatus);

      }}
    >

      {innerProps => {
        const { touched, errors, values, handleChange, handleSubmit, setFieldValue } = innerProps;
        return (
          <Modal toggle={props.toggle} show={props.show}>
            <AddItemPopupWrapper>
              <AddItemPopup
                title={'Add New Product'}
                actions={[
                  () => (
                    <Button.Button
                      color={colors.blue}
                      text={'Save'}
                      onClick={() => handleSubmit()}
                    />
                  ),
                  () => (
                    <Button.WhiteButton
                      text={'Cancel'}
                      onClick={() => {
                        props.toggle()
                      }}
                    />
                  ),
                ]}
                illustration={addCategoryIllustration}
              >
                <ErrorWrapper show={postStatus}>
                  <Icons.XIcon fill={colors.red} style={{marginRight: 10}} width={16} />
                  <span>Error adding the product</span>
                </ErrorWrapper>
                <AddItemPopup.InputRow>
                  <UploadInput
                    labelText={'Upload Image'}
                    value={''}
                    uriGenerator = { uri => {
                      setUri(uri)
                    }}
                  />
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow>
                  <Input
                    labelText={'Product Name'}
                    onChange={handleChange}
                    id={'name'}
                  />
                  {errors.name && touched.name ? (
                    <WarningWrapper>  <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} /> {errors.name}</WarningWrapper>
                  ) : null}
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow>
                  <Select
                    name={'categoryId'}
                    options={getOptions()}
                    onChange={val => setFieldValue('categoryId', val.value)}
                    isSearchable={'false'}
                    style={{ width: '-webkit-fill-available' }}
                    value={getOptions().find(opt => opt.value === values.categoryId)}
                  />
                  {
                    errors.categoryId && touched.categoryId ?
                    <WarningWrapper>
                      <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} /> {errors.categoryId}
                    </WarningWrapper> :
                    null
                  }
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow>
                  <Input
                    labelText={'Price'}
                    onChange={handleChange}
                    id={'price'}
                  />
                  {
                    errors.price && touched.price ?
                  <WarningWrapper>
                    <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} />
                    {errors.price}
                  </WarningWrapper> :
                    null
                  }
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow>
                  <Input
                    labelText={'Description'}
                    onChange={handleChange}
                    id={'description'}
                  />
                </AddItemPopup.InputRow>
              </AddItemPopup>
            </AddItemPopupWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => ({
  facebookPages: state.facebookPages,
  categories: state.categories,
  products: state.products,
});

export default connect(
  mapStateToProps,
  { add, getAll },
)(AddProductPopup);

export { AddProductPopup };
