import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import icons from 'app/shared/views/icons';

const {
  BackArrow,
  ForwardArrow,
} = icons;

const CarouselWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CarouselContent = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: ${props => props.resetScroll ? 'auto' : 'smooth'};
  scrollbar-width: none;
`;

const CarouselTotalWidth = styled.div`
  max-height: 0;
  display: block;
`;

const SlideWrapper = styled.div`
  display: flex;
`;

const ArrowWrapper = styled.div`
  display: block;
  cursor: pointer;
  // transform: translateY(-50%);
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);
  padding: 0 4px;

  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: .5;
  }

  &.left {
  }

  &.right {
  }
`;

const Slide = ({ index, activeIndex, slide }) => (
  <SlideWrapper className={index === activeIndex ? 'active' : ''}>
    {React.cloneElement(slide)}
  </SlideWrapper>
);

const Carousel = ({
  className,
  children,
  leftArrow,
  rightArrow,
  showArrows,
  infinite,
  itemWidth,
  totalItems,
  resetScroll,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [totalScrollWidth, setTotalScrollWidth] = useState(0);
  const [hideArrows, setHideArrows] = useState(true);
  const carouselRef = useRef();
  const totalWidthRef = useRef();

  useEffect(() => {
    const outer = carouselRef.current, inner = totalWidthRef.current;
    const totalWidth = outer.offsetWidth - inner.offsetWidth;
    setTotalScrollWidth(totalWidth);
  }, []);

  useEffect(() => {
    if (resetScroll) {
      const el = carouselRef.current;
      if (el) el.scrollTo({
        left: 0,
        behavior: 'auto',
      });
    }
  }, [resetScroll]);

  useEffect(() => {
    if (!totalItems || !itemWidth) {
      return setHideArrows(!showArrows);
    }

    if (totalScrollWidth >= totalItems * itemWidth) {
      setHideArrows(true);
    } else {
      setHideArrows(!showArrows);
    }
  }, [totalScrollWidth, showArrows, totalItems, itemWidth]);

  const updateActiveIndex = (index) => {
    if (index === activeIndex) return;

    const el = carouselRef.current;
    let leftOffset = el.scrollLeft;

    if (index === 0) {
      leftOffset = 0;
    } else {
      leftOffset += (index > activeIndex ? 1 : -1) * (itemWidth + leftOffset % itemWidth);
    }

    el.scroll({
      left: leftOffset,
      behavior: 'smooth'
    });

    setActiveIndex(index);
  };

  const prevSlide = (e) => {
    e.preventDefault();
    let index = activeIndex;

    if (totalItems && index === 0) {
      if (infinite) index = totalItems;
    } else {
      --index;
    }

    updateActiveIndex(index);
  };

  const nextSlide = (e) => {
    e.preventDefault();
    let index = activeIndex;

    if (totalItems && index === totalItems - 1) {
      if (infinite) index = 0;
    } else {
      ++index;
    }

    updateActiveIndex(index);
  };

  return (
    <CarouselWrapper>
      {!hideArrows ? (
        <ArrowWrapper className="left" onClick={prevSlide}>
          {leftArrow}
        </ArrowWrapper>
      ) : null}

      <CarouselContent className={className} ref={carouselRef} resetScroll={resetScroll}>
        <CarouselTotalWidth ref={totalWidthRef} />
        {React.Children.map(children, (slide, index) =>
          <Slide
            key={index}
            index={index}
            activeIndex={activeIndex}
            slide={slide}
          />
        )}
      </CarouselContent>

      {!hideArrows ? (
        <ArrowWrapper className="right" onClick={nextSlide}>
          {rightArrow}
        </ArrowWrapper>
      ) : null}
    </CarouselWrapper>
  );
};

const DefaultLeftArrow = styled(BackArrow)`
  background: #748AA1;
  opacity: 0.6;
  border-radius: 50%;
  width: 21px;
  height: 21px;

  svg {
    height: 12px;
    width: 12px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
`;

const DefaultRightArrow = styled(ForwardArrow)`
  background: #748AA1;
  opacity: 0.6;
  border-radius: 50%;
  width: 21px;
  height: 21px;

  svg {
    height: 12px;
    width: 12px;
    margin-bottom: 5px;
    margin-left: 5px;
  }
`;

Carousel.propTypes = {
  showArrows: PropTypes.bool,
  leftArrow: PropTypes.node,
  rightArrow: PropTypes.node,
  itemWidth: PropTypes.number.isRequired,
  totalItems: PropTypes.number,
  infinite: PropTypes.bool,
};

Carousel.defaultProps = {
  showArrows: true,
  leftArrow: <DefaultLeftArrow fill="white" />,
  rightArrow: <DefaultRightArrow fill="white" />,
  infinite: false,
};

export default Carousel;
