import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import shared from '../../shared';
import colors from 'app/shared/helpers/colors';

const {
  views: { Input, },
} = shared;

const SuggestionWrapper = styled.div`
  background: white;
  border: 1px solid #e1e6ec;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0px 10px 0px 10px;
  margin-top: 5px;
  position: absolute;
  width: 100%;
  z-index: 999;
`;
const SuggestionUl = styled.ul``;

const SuggestionLi = styled.li`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.black};
  height: 30px;
  border-bottom: 1px solid #e9eaf1;
  padding-top: 15px;
  cursor: pointer;
`;

export class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
  };

  static defaultProperty = {
    suggestions: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      disabled: false,
      showSuggestions: false,
      userInput: null,
      suggestionId: '',
      suggestionPrice: '',
      suggestionField: props.suggestionField || 'name',
    };
  }

  onChange = e => {
    const { suggestions,  } = this.props;
    const userInput = e.currentTarget.value;
    const filteredSuggestions = suggestions.filter(
      suggestion => suggestion[this.state.suggestionField] && suggestion[this.state.suggestionField].toLowerCase().indexOf(userInput.toLowerCase()) > -1,
    );
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: userInput.length > 2,
      userInput: e.currentTarget.value,
    });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  onClick = suggestion => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: null,
    });

    console.log('onclick')
    this.props.finalValue(suggestion);
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions, suggestionField } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion][suggestionField],
      });
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: { filteredSuggestions, showSuggestions, userInput, suggestionField },
    } = this;
    let suggestionsListComponent;
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <SuggestionWrapper>
            <SuggestionUl>
              {filteredSuggestions.map((suggestion, index) => {
                return (
                  <SuggestionLi key={suggestion.id} onClick={onClick.bind(null, suggestion)}>
                    {suggestion[suggestionField]}
                  </SuggestionLi>
                );
              })}
            </SuggestionUl>
          </SuggestionWrapper>
        );
      } else {
        suggestionsListComponent = (
          <div>
            <em>No suggestions</em>
          </div>
        );
      }
    }

    const index = this.props.index;
    const { selected, labelText, lableField, name, value } = this.props;
    return (
      <React.Fragment>
        <Input
          name={name}
          type="search"
          onChange={onChange}
          onKeyDown={onKeyDown}
          disabled={this.props.disabled}
          labelText={index === 0 && labelText}
          value={value ? value : userInput != null ? userInput : selected && selected[lableField]}
          autoComplete="none"
          onBlur={() => {
            setTimeout(() => {
              this.setState((prevState) => ({ ...prevState, showSuggestions: false }));
            }, 100);
          }}
        />
        {suggestionsListComponent}
      </React.Fragment>
    );
  }
}
export default Autocomplete;
