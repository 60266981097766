import React from 'react';
import styled from 'styled-components';

import shared from 'app/shared';

const {
  views: {
    Text: {
      H4,
    },
    Alert,
  },
  helpers: {
    colors,
    hexToRGBA
  }
} = shared;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // margin: 0 15px;
  margin: 0 2px;
  overflow: hidden;
  max-width: calc(100vw - 4px);
`;

const StepContent = styled.div`
`;

const StepHead = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 15px;
`;

const StepName = styled(H4)`
  margin-left: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: ${props => props.color};
`;

const StepIcon = styled.span`
  color: ${props => props.color};
  background-color: ${props => hexToRGBA(props.color, 0.2)};
  padding: 4px;
  border: 0;
  border-radius: 50%;

  & > div {
    padding: 2px;
  }
`;

const ErrorAlert = styled(Alert)`
  margin: 10px 15px 0px 15px;
`;

const WizardStep = (props) => {
  const { id, name, icon, step, activeStep, error } = props;
  const IconComponent = icon;

  const isActive = activeStep === id;
  const isVisited = activeStep > id;

  const stepColors = {
    name: (isVisited ? colors.green : isActive ? colors.blue : colors.grey),
    icon: (isVisited ? colors.green : isActive ? colors.blue : colors.grey),
  };

  return (
    <StepWrapper>
      <StepContent>
        <StepHead>
          <StepIcon color={stepColors.icon}>
            <IconComponent fill={stepColors.icon} width="20px" height="16px" />
          </StepIcon>
          <StepName color={stepColors.name}>{name}</StepName>
        </StepHead>
        { isActive || isVisited ? React.cloneElement(step, { isActive, isVisited }) : null}
        {isActive ? <ErrorAlert type="error" message={error && error.message} show={!!error} /> : null}
      </StepContent>
    </StepWrapper>
  );
};

export default WizardStep;
