import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import shared from 'app/shared/';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { changeStripeSettings, getStripeSettings } from '../../../actions/stripeSettingsActions';
import stripeView from '../../../assets/images/stripeView.svg';

const {
    views: { Button, AddItemPopup, Input},
    helpers: { colors },
} = shared;


const SettingsTitleWrapper = styled.div`
  margin-top: 1.45%;
  margin-bottom: 40px;
  font-weight: 500;
  width: 50%
`;

const SettingsTitle = styled.div`
  font-size: 16px;
  color: ${colors.blue};
`;

const InlineFlexWrapper = styled.div`
    display: inline-flex;
    margin-top: 3%;
    width: 60%;
`;

const MainLabel = styled.div`
    display: flex;
    width: 35%;
    height: 100%;
    font-size: 15px;
    color: ${colors.textColor};
`;

const ImageWrapper = styled.div`
    height: 100%;
    marginLeft: 3%;
    width: 10%;
`;

const StripeSettings = props => {
    const [submitFlag, setSubmitFlag] = useState(false);
    const {
        getStripeSettings
    } = props;

    useEffect(() => {
        getStripeSettings();
    } ,[getStripeSettings])

    return (
        <div style={{ display: 'inline-flex' }}>
            <div style={{ width: '85%' }}>
                <SettingsTitleWrapper>
                    <SettingsTitle>Stripe Configuration</SettingsTitle>
                </SettingsTitleWrapper>
                <InlineFlexWrapper>
                    <MainLabel>
                        Add Stripe Credentials
                    </MainLabel>

                    <div style={{ width: '65%' }}>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                publishableKey: (props.stripeSettings.data && props.stripeSettings.data.stripePublicKey) || '',
                                privateKey: (props.stripeSettings.data && props.stripeSettings.data.stripeSecretKey) || '',
                            }}
                            validationSchema={Yup.object().shape({
                                publishableKey: Yup.string().required('Publishable key is required'),
                                privateKey: Yup.string().required('Private key is required'),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                // setTimeout(() => {
                                //     alert(JSON.stringify(values, null, 2));
                                //     setSubmitting(false);
                                // }, 500);

                                props.changeStripeSettings({
                                  stripePublicKey: values.publishableKey,
                                  stripeSecretKey: values.privateKey,
                                  id: 0
                                })
                            }}
                        >
                            {innerProps => {
                                const { values, touched, errors, handleChange, handleSubmit } = innerProps;
                                if(!submitFlag){
                                    props.setExecuteOnSubmit(() =>{ return handleSubmit});
                                    setSubmitFlag(true);
                                }
                                return (
                                    <div>
                                        <AddItemPopup.InputRow>
                                            <Input
                                                labelText="Pubishable key"
                                                value={values.publishableKey}
                                                onChange={handleChange}
                                                id="publishableKey"
                                            />
                                            {errors.publishableKey && touched.publishableKey ? (
                                                <div>{errors.publishableKey}</div>
                                            ) : null}
                                        </AddItemPopup.InputRow>
                                        <AddItemPopup.InputRow>
                                            <Input
                                                labelText="Private key"
                                                value={values.privateKey}
                                                onChange={handleChange}
                                                id="privateKey"
                                            />
                                            {errors.privateKey && touched.privateKey ? <div>{errors.privateKey}</div> : null}
                                        </AddItemPopup.InputRow>
                                        <AddItemPopup.InputRow style={{ textAlign: "left" }}>
                                            <Button.Button color={colors.blue} text="Test" size={Button.ButtonSize.extraMedium} onClick={() => handleSubmit()} />
                                        </AddItemPopup.InputRow>
                                    </div>
                                );
                            }}
                        </Formik>
                    </div>
                </InlineFlexWrapper>
            </div>
            <ImageWrapper>
                <img src={stripeView} alt={'Shows a bloke and a giant iMac with the stripe logo'}/>
            </ImageWrapper>
        </div>
    );
}

const mapStateToProps = state => ({
    stripeSettings: state.stripeSettings
})

export default connect(
    mapStateToProps,
    { changeStripeSettings, getStripeSettings }
)(StripeSettings)

export {StripeSettings};
