import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import { Formik} from 'formik';
import * as Yup from 'yup';
import {add} from '../../../actions/crudActions';
import crudTypes from '../../../config/crudTypes';

import addCategoryIllustration from '../../../assets/images/addCategoryIllustration.svg';

const {
  views: { Modal, AddItemPopup, Button, Input, UploadInput, Icons },
  helpers: { colors },
} = shared;

const AddItemPopupWrapper = styled.div`
  width: 450px;
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    background: rgba(242,69,61, .2);
    color: #F2453D;
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1px 5px;
    display: ${props => props.show === 'ERROR' ? 'flex' : 'none'}
`;

const WarningWrapper = styled.div`
    width: 100%;
    display: flex;
    background: rgba(255,152,0, .2);
    color: #FF9800;;
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1px 5px;
    margin-top: 10px;
`;

const AddCategoryPopup = (props) => {
  const [uri, setUri] = useState();
  const [postStatus, setPostStatus] = useState('');
  const{toggle, show = false } = props;

  useEffect(() => {
    if (postStatus === 'OK') {
      toggle()
    }
  }, [postStatus, toggle])

  const changePostStatus = (status) => {
    setPostStatus(status);
  }


  const postCategory = (data) => {
    data.pageId = JSON.parse(localStorage.getItem("Page"));
    props.add(crudTypes.category, data, changePostStatus);
  }

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Category name is required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        if(uri) {
          values.imagePath = uri
        }
        postCategory(values)
      }}
    >
      {innerProps => {
        const { touched, errors, handleChange, handleSubmit } = innerProps;
        return (
          <div>
            <Modal toggle={toggle} show={show}>
              <AddItemPopupWrapper>
                <AddItemPopup
                  title={"Add New Category"}
                  actions={[
                    () => <Button.Button color={colors.blue} text={"Save"} onClick={handleSubmit} />,
                    () => <Button.WhiteButton text={"Cancel"}
                            onClick={() => {
                              props.toggle();
                            }}
                          />,
                  ]}
                  illustration={addCategoryIllustration}
                >
                  <ErrorWrapper show={postStatus}>
                    <Icons.XIcon fill={colors.red} style={{marginRight: 10}} height={16} />
                    <span>Error adding the category</span>
                  </ErrorWrapper>
                  <AddItemPopup.InputRow>
                    <UploadInput
                      labelText={"Upload Image"}
                      value={""}
                      uriGenerator = { uri => {
                        setUri(uri)
                      }}
                    />
                  </AddItemPopup.InputRow>
                  <AddItemPopup.InputRow>
                    <Input
                      id={"name"}
                      labelText={"Category Name"}
                      onChange={handleChange}
                    />
                    {errors.name && touched.name ? (
                      <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} height={16} /> {errors.name}</WarningWrapper>
                    ) : null}
                  </AddItemPopup.InputRow>
                </AddItemPopup>
              </AddItemPopupWrapper>
            </Modal>
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({categories: state.categories});

export default connect(
 mapStateToProps,
 {add}
)(AddCategoryPopup);

