import React, { Component } from 'react';
import styled from 'styled-components';
import transition from '../helpers/transition';

/**
 * TODO: Refactor all this file
 * @type {React.Context<{selectedElement: null, showNext: boolean}>}
 */
const RadioViewContext = React.createContext({ selectedElement: null, showNext: false });

const ContentWrapper = styled.div`
  position: relative;
  transition: ${transition};
`;

const ElementWrapper = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  // z-index: ${props => (props.show ? 9997 : -1)};
  transition: ${transition};
  width: 100%;
`;

class RadioView extends Component {
  state = {
    showNext: true,
  };

  elementRefs = {};

  elementWrapperRef = null;

  componentDidMount() {
    const {
      props: { selectedElement },
      elementRefs,
    } = this;

    setTimeout(() => {
      if (this.elementWrapperRef)
        this.elementWrapperRef.style.height = `${elementRefs[selectedElement].offsetHeight}px`;
    });
  }

  componentDidUpdate(prevProps) {
    const { selectedElement: prevSelectedElement } = prevProps;
    const { selectedElement: currentSelectedElement } = this.props;

    if (prevSelectedElement !== currentSelectedElement) {
      if (prevSelectedElement !== null) {
        this.elementWrapperRef.style.height =
          this.elementRefs[prevSelectedElement].offsetHeight + 'px';
      }

      this.setState({
        showNext: false,
      });

      setTimeout(() => {
        this.setState(
          {
            showNext: true,
          },
          () => {
            this.elementWrapperRef.style.height =
              this.elementRefs[currentSelectedElement].offsetHeight + 'px';
          },
        );

        // Unlock height and width
      }, 150);
    }
  }

  forceRecalculate = () => {
    const { selectedElement: currentSelectedElement } = this.props;
    this.elementWrapperRef.style.height =
      this.elementRefs[currentSelectedElement].offsetHeight + 'px';
  };

  render() {
    const { children, selectedElement, ...rest } = this.props;
    const { showNext } = this.state;

    return (
      <RadioViewContext.Provider
        value={{
          selectedElement,
          showNext,
          reference: value => el => (this.elementRefs[value] = el),
        }}
      >
        <ContentWrapper {...rest} ref={el => (this.elementWrapperRef = el)}>
          {children}
        </ContentWrapper>
      </RadioViewContext.Provider>
    );
  }
}

RadioView.Element = ({ Element, value, ...rest }) => (
  <RadioViewContext.Consumer>
    {({ selectedElement, showNext, reference }) => (
      <ElementWrapper show={selectedElement === value && showNext === true}>
        <Element reference={reference(value)} isSelected={selectedElement === value} {...rest} />
      </ElementWrapper>
    )}
  </RadioViewContext.Consumer>
);

export default RadioView;
