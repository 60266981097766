import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import text from './text';
import { ButtonWithIcon, GradientButton, ButtonSize } from './buttons';
import Icons from './icons';
import colors from '../helpers/colors';
import TopBarValidator from '../validators/topBar.validator';
import Notification from '../../notification/views/notificationPanel';
import { getAll } from '../../../actions/crudActions';
import { setActivePage } from '../../../actions/facebookAction';
import { getNotifications } from '../../../actions/notificationAction';

const { H5 } = text;

const TopBarWrapper = styled.header`
  display: flex;
  justify-content: space-between;
`;

const ImageAndSelectWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 30px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    > * {
      margin-left: 10px;
    }
  }
`;

const SelectWraper = styled.div`
  width: 220px;

`;
const SelectWrapperTablet = styled.div`
@media all and (min-width: 768px) and (max-width: 1024px) {
  width: 80%;
}
`;

const FreeTrialWrapper = styled.a`
  ont-size: 12px;
  ine-height: 14px;
  olor: ${colors.blue};
  order-bottom: 1px solid transparent;
  ext-decoration: none;
  ont-weight: 500;
  ransition: all 0.15s ease-in-out;

  :hover {
  border-bottom: 1px solid ${colors.blue};
  cursor: pointer;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
   }
`;

const OrderooProWrapper = styled.div`
  width: 117px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4CAF50;
  box-shadow: 0px 12px 16px rgba(200, 208, 216, 0.3);
  border-radius: 3px;
`;

const OrderooProLabel = styled(H5)`
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
`;

const TopBar = props => {
  const handleSelectChange =  val => {
    props.setActivePage(val)
  };

  return (
    <TopBarWrapper>
      <ImageAndSelectWrapper>
        <SelectWraper>
          <SelectWrapperTablet>
            <Select
              options={
                props.facebookPages && props.facebookPages.data
                ? props.facebookPages.data.map(( page ) => (
                  {
                  value: page.id,
                  label: page.pageName,
                }))
                : null
              }
              onChange={val => {handleSelectChange(val.value); }}
              isSearchable={'false'}
              name={'category'}
              value={{
                value: props.facebookPages.activePage ? props.facebookPages.activePage.id : '',
                label: props.facebookPages.activePage ? props.facebookPages.activePage.pageName : ''
              }}
            />
          </SelectWrapperTablet>
        </SelectWraper>
      </ImageAndSelectWrapper>
      <ButtonsWrapper>
        <UpgradeInfo subscription={props.subscription} togglePayment={props.togglePayment} />
        <Notification />
        <NavLink to={'/settings'} style={{ textDecoration: 'none' }}>
          <ButtonWithIcon
            Icon={Icons.Settings}
            color={colors.blue}
            text={props.userDetails.user ? props.userDetails.user.name : null}
          />
        </NavLink>
      </ButtonsWrapper>
    </TopBarWrapper>
  );
};

const UpgradeInfo = ({ subscription, togglePayment }) => {
  if (!subscription) return null;

  const upgradeInfo = subscription.isTrialSubscription || !subscription.isActive ? (
    <GradientButton
      onClick={togglePayment}
      text={'Upgrade'}
      size={ButtonSize.big}
      style={{ boxShadow: '0px 12px 16px rgba(200, 208, 216, 0.3)' }}
    />
  ) : (
    <OrderooProWrapper>
      <OrderooProLabel>Pro Account</OrderooProLabel>
    </OrderooProWrapper>
  );

  return (
    <>
      {subscription.isTrialSubscription ? (
        <FreeTrialWrapper style={{ fontSize: 16 }}>
          Free Trial: { getFreeTrialDaysLeft(subscription) } Days
          Left
        </FreeTrialWrapper>
      ) : null}
      {
        upgradeInfo
      }
    </>
  );
}

const getFreeTrialDaysLeft = (subscription) => {
  const trialStartDate = moment(subscription.subscriptionStartDate).utc();
  const daysSinceTrialStart = moment().utc().diff(trialStartDate, 'days');

  if (daysSinceTrialStart >= 30) {
    return 0;
  } else {
    return 30 - daysSinceTrialStart;
  }
};

TopBar.propTypes = TopBarValidator.TopBarSchema;

const mapStateToProps = state => ({
  facebookPages: state.facebookPages,
  userDetails: state.userDetails,
  categories: state.categories,
  products: state.products,
  notficiations: state.notifications,
  orders: state.orders,
  clients: state.clients,
  subscription: state.subscriptions.activePageSubscription,
});

export default connect(
  mapStateToProps,
  { setActivePage, getAll, getNotifications },
)(TopBar);
