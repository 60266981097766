import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import NotificationList from './notificationList';
import colors from '../../shared/helpers/colors';
import Icons from '../../shared/views/icons';

const NotificationDropdownBox = styled.div`
  background: #ffffff;
  border-radius: 5px;
  font-size: 14px;
  min-width: 250px;
  // height: 250px;
  z-index: 1;
  position: absolute;
  display: ${props => (props.show ? "block" : "none")};
  -webkit-transition: 0.15s ease-in-out;
  -moz-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  margin-top: 10px;
  right: 219px;
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.07);
`;

const DropdownHeader = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  width: 100%;
  color: ${colors.blue};
  border-bottom: 1px solid #E9EAF1;

  display: flex;
  align-items: center;
  text-indent: 20px;
`;

const DropdownFooter = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  width: 100%;
  color: ${colors.blue};
  border-top: 1px solid #E9EAF1;

  display: flex;
  align-items: center;
  text-indent: 20px;
`;

const NotificationDropdown = ({ refer, show, data, markAsRead }) => {
  return (
    <NotificationDropdownBox ref={refer} show={show}>
      <DropdownHeader>
        <span>Recent Notifications</span>
      </DropdownHeader>
      <NotificationList data={data} markAsRead={markAsRead} />
        <DropdownFooter>
          <NavLink to="/notifications"  style={{ display: 'flex', alignItems: 'center' }}>
            See All
            <Icons.Forward width={10} height={10} fill={colors.lightGrey} style={{ paddingLeft: '10px' }} />
          </NavLink>
      </DropdownFooter>
    </NotificationDropdownBox>
  );
};

export default NotificationDropdown;
