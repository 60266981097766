import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const {
  views: { Card, Text, Icons },
  helpers: { colors },
} = shared;

const{
  Paragraph:{P, },
  H5,
} = Text;

const PageCardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;

  ${props => props.disabled ? `
    & ${PhotoContainer} {
      filter: grayscale(90%);
    }

    & ${Value} {
      color: ${colors.grey} !important;
    }

    & ${Title} {
      color: ${colors.grey};
    }

  `: null}
`;

const PhotoContainer = styled.div`
  height: 130px;
  background: url(${props => props.photo}) center center;
  background-size: cover;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

const InfoWrapper = styled.div`
  padding: 5px 10px 10px 10px;
  border-top: 1px solid ${colors.lightGrey}
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(H5)`
  color: ${colors.blue};
`;

const Label = styled(P)`
  font-size: 11px;
  line-height: 22px;
  color: ${colors.textColor};
`;

const Value = styled(P)`
  font-size: 12px;
  line-height: 22px;
`;

const Status = styled(Value)`
  font-weight: bold;
`;

const getLabel = (status, inactiveId) => {
    switch (status) {
        case 1:
            return (
                <Status style={{color: colors.green}}>Paid</Status>
            );
        case 2:
           return (
                <Status style={{color: colors.grey}}>Free Trial</Status>
           );
        case inactiveId:
            return (
              <Status style={{color: colors.red}}>Cancelled</Status>
           );
        default:
            return status;
    }
}

const getIcon = (status, inactiveId) => {   
    const iconSize  = 15;
    const marginTop = '-5px';
    const style = { width: iconSize, height: iconSize, marginTop: marginTop };
    if(status === inactiveId){
        return (
            <Icons.Remove 
            style={style} 
            fill={colors.red} />
        );
    }
    return (
        <Icons.SuccessIcon 
        style={style} 
        fill={colors.green} />
    );
}

const PageCard = ({ title, photo, status, inactiveStatus, ...rest }) => (
  <PageCardWrapper {...rest}>
    <PhotoContainer photo={photo} />
    <InfoWrapper>
      <Row style={{ marginBottom: 5 }}>
        <Title>{title}</Title>
        {getIcon(status, inactiveStatus)}
      </Row>
      <Row>
        <Label>Subscription</Label>
        {getLabel(status, inactiveStatus)}
      </Row>
    </InfoWrapper>
  </PageCardWrapper>
);

export default PageCard;