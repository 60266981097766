import React from 'react';
import { Route} from 'react-router-dom';
import auth from '../../../auth';

const { isAuthenticated } = auth;

const GuestRoute = ({ guestRender,  ...rest }) => {
  return <Route render={() => {
    if (!isAuthenticated()) {
      return guestRender();
    }
    return null;
  }} />
};

export default GuestRoute;
