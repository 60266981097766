import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  getAuthToken,
  updateOrderPaymentMethod,
  startPayment,
  completePayment,
} from 'actions/shoppingActions';

import {
  PAYMENT_CONFIRM_PATH,
  PAYMENT_OPTIONS,
} from '../constants';

import {
  usePendingPayment
} from '../hooks';

import {
  MainButton,
  OutlinedButton
} from './components/buttons';
import InfoPage from './components/infoPage';

import OrderDeclinedGraphic from '../graphics/orderDeclined.svg';

const OrderDeclinedPage = ({
  getAuthToken,
  updateOrderPaymentMethod,
  startPayment,
  completePayment,
}) => {
  const [, setError] = useState('');
  const [redirectTo, setRedirectTo] = useState('');
  const [pendingPayment, setPendingPayment] = usePendingPayment();

  const onUpdatePaymentInfo = () => {
    if (!pendingPayment) {
      setError('Something went wrong!');
      return;
    }

    const { authCode } = pendingPayment;
    setRedirectTo(`/shoppingCart?authCode=${authCode}`);
  };

  const onPayOnDelivery = () => {
    if (!pendingPayment) {
      setError('Something went wrong!');
      return;
    }

    const { authCode, sessionId } = pendingPayment;
    getAuthToken(authCode, (err) => {
      if (err) {
        setError(err.message);
        return;
      }

      updateOrderPaymentMethod(sessionId, PAYMENT_OPTIONS.ON_DELIVERY, (err, order) => {
        if (err) {
          setError(err.message);
          return;
        }
        setPendingPayment(null);

        startPayment(order.id, (err, payment) => {
          if (err) {
            setError(err.message);
            return;
          }

          const { paymentDetails } = payment;
          completePayment(paymentDetails.sessionId, () => {
            // redirect to success
            setRedirectTo(PAYMENT_CONFIRM_PATH);
          });
        });
      });
    });
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <InfoPage
      title="Order Declined"
      info="There was a problem with the payment process. Please check your debit/credit card and try again. If there are still issues contact your bank."
      graphicSrc={OrderDeclinedGraphic}
      actions={
        <>
          <MainButton text="Update Payment Info" onClick={onUpdatePaymentInfo} />
          <OutlinedButton text="Pay on Delivery" onClick={onPayOnDelivery} />
        </>
      }
    />
  );
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  {
    getAuthToken,
    updateOrderPaymentMethod,
    startPayment,
    completePayment,
  }
)(OrderDeclinedPage);
