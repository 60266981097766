import React from 'react';
import styled from 'styled-components';
import hexToRGBA from '../helpers/hexToRGBA';
import transition from '../helpers/transition';
import mediaQueries from '../helpers/mediaQueries';
import colors from '../helpers/colors';

export const BaseButton = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  background-color: ${props => hexToRGBA(props.color, 0.1) || ''};
  user-select: none;
  transition: ${transition};
  font-size: 16px;

  ${mediaQueries.mobile(`
    font-size: 14px;
  `)}

  ${mediaQueries.tablet(`
  font-size: 14px;
`)}

  :hover {
    cursor: pointer;
    background-color: ${props => hexToRGBA(props.color, 0.3) || ''};
  }
`;

const ButtonWithIconWrapper = styled(BaseButton)`
  min-width: 145px;
  height: 36px;
  padding-right: 8px;

  ${mediaQueries.mobile(`
    min-width: 120px;
    height: 25px;
  `)}

  ${mediaQueries.tablet(`
    min-width: 36px;
    height: 36px;
  `)}
`;

const IconWrapper = styled.div`
  margin: 0 11px 0 8px;

  ${mediaQueries.mobile(`
    margin: 0 3px 0 6px;
  `)}
`;

const SpanWrapper = styled.span`
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
`;

export const ButtonWithIcon = ({ Icon, text, color, ...rest }) => (
  <ButtonWithIconWrapper color={color} {...rest}>
    <IconWrapper>
      <Icon fill={color} />
    </IconWrapper>
    <SpanWrapper style={{ color }}>{text}</SpanWrapper>
  </ButtonWithIconWrapper>
);

export const ButtonSize = {
  big: {
    height: 33,
    width: 117,
    fontSize: 16,
  },
  extraMedium: {
    height: 41,
    width: 103,
    fontSize: 16,
  },
  medium: {
    height: 24,
    width: 70,
    fontSize: 12,
  },
  small: {
    height: 18,
    width: 36,
    fontSize: 12,
  },
};

const ButtonWrapper = styled(BaseButton)`
  text-align: center;
`;

const GradientButtonWrapper = styled(ButtonWrapper)`
  background-image: linear-gradient(90deg, #006ccb 0%, #006ccb 75%, #65baff 100%);
  background-size: 400% 100%;
  background-position: top 0% right 0%;
  transition: background-position 0.25s ease-in;

  :hover {
    background-position: top 0% right 100%;
  }

`;

export const GradientButton = ({ color, text, size = ButtonSize.big, style = {}, ...rest }) => (
  <GradientButtonWrapper
    style={{
      height: size.height,
      width: size.width,
      // background: `linear-gradient(90deg, #006CCB, #65BAFF)`,
      color: colors.white,
      ...style,
    }}
    {...rest}
  >
    <span style={{ color, width: '100%', fontSize: size.fontSize, fontWeight: style.fontWeight }}>{text}</span>
  </GradientButtonWrapper>
);

export const Button = ({ color, text, children, size = ButtonSize.big, style = {}, ...rest }) => (
  <ButtonWrapper
    style={{ height: size.height, width: size.width, ...style }}
    color={color}
    {...rest}
  >
    <span style={{ color, width: '100%', fontSize: size.fontSize }}>{text}</span>
  </ButtonWrapper>
);

export const RawButton = ({ text, ...rest }) => (
  <div {...rest}>
    <span>{text}</span>
  </div>
);

export const MainButton = styled(RawButton)`
  position: relative;
  background-color: ${colors.lightBlue};
  color: ${colors.blue};
  border-radius: 3%;
  padding: 2%;
  margin: 0 auto;
  text-align: center;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 100%;
  line-height: 80%;

  display: flex;

  width: 40.5%;
  align-items: center;
  text-align: center;

  :active {
    opacity: 0.6;
  }

  span {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 1024px) {
    display: block;
    width: 80%;
    font-size: 150%;
    padding: 4%;
  }

  cursor: pointer;
`;

export const WhiteButton = styled(Button)`
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};

  @media all and (max-width: 768px) {
    display: none;
  }
`;
