import React, { Component } from 'react';
import { connect } from 'react-redux';
import shared from '../../../../app/shared';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Icon from '../../../shared/views/icons';
import { getTotalsClients } from '../../../../actions/adminActions';
import hexToRGBA from '../../../shared/helpers/hexToRGBA';
import PageCard from './pageCard';
import pageImage from 'assets/images/product_image.png';

const {
  helpers: {
    resetScroll, colors, currencyFormatter, transition
  },
  views: {
    Page, Input, Grid
  },
} = shared;

const ClientHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 768px) {
    justify-content: space-between;
  }
  margin-bottom: 15px;
`;

const BackWrapper = styled.div`
  display: inline-flex;
  width: 200px;
`;

const BackTitle = styled.div`
  font-size: 20px;
  padding-top: 10px;
  color: ${colors.black};
  margin-left: 22px;
  margin-right: 22px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-right: 22px;
  }
  @media all and (max-width: 768px){
    display: none;
  }
`;

const PictureIcon = styled.div`
  border: 1px solid #E9EAF1;
  box-sizing: border-box;
  border-radius: 25px;
  width: 42px;
  height: 42px;
  margin-right: 20px;
  @media all and (max-width: 768px){
    display: none;
  }
`;

const ClientBodyWrapper = styled.div`
  background: ${colors.white}
  display: flex;
  margin-bottom: 45px;
  border: 1px solid #E9EAF1;
  box-sizing: border-box;
  border-radius: 3px;
  flex-direction: column;
  padding: 10px;
`;

const ClientBody = styled.div`
  background: ${colors.white}
  padding: 10px 30px;
  display: flex;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
  }
  @media all and (max-width: 768px){
    flex-direction: column;
  }
`;

const ClientDetail = styled.div`
  padding-right: 55px;
  width: 100%;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    padding-right: 0px;
    margin-right: 10px;
    width: 100%;
  }
  @media all and (max-width: 768px){
    padding-right: 0px;
    margin-right: 10px;
    width: 100%;
  }
  `;

const ClientLabel = styled.div`
  margin-bottom: 30px;
  font-size: ${props => `${props.fontSize}px`};
  line-height: 22px;
  color: ${colors.blue};
`;

const SingleInputRow = styled.div`
  width: ${props => `${props.width}%`};
  margin-right: ${props => `${props.marginRight}px`};
  margin-top: ${props => `${props.marginTop}px`};
`;

const SingleRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 17px;
`;

const ClientStatisticLabel = styled.div`
  width: 100%;
  height: 43px;
  text-align: center;
  font-size: 16px;
  line-height: 23px;
  background-color: ${props => hexToRGBA(props.color, 0.2)};
  border-radius: 3px;
  color: ${props => props.color};
  padding-top: 10px;
`;

const LabelWrapper = styled.div`
  padding-left: 10px;
`;

const Label = styled.label`
  color: ${colors.textColor};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
`;

const SectionTitleWrapper = styled.div`
  color: ${colors.blue};
  font-weight: bold;
  border-bottom: 2px solid ${colors.blue};
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const getSum = (arr, key) => {
  return arr.reduce((a, b) => a + (b[key] || 0), 0);
}

const cardWrapper = Element => styled(Element)`
  transition: ${transition};
  transform: translateY(0);

  :hover {
    cursor: pointer;
    transform: translateY(-5px);
  }
`;

const initialState = {
};

const PageCardWrapper = cardWrapper(PageCard);

class AdminDetailsPage extends Component {
  state = initialState;

  componentWillMount() {
    const pageId = this.props.match.params.id;
    this.props.getTotalsClients(pageId); 
  }

  componentDidMount() {
    resetScroll();
  }

  render() {
    let client = this.props.adminClients.data[0];
    const inactivePageStatusId = 3;
    return (
      <Page>
        <ClientHeaderWrapper>
          {
            client && client.pageAdmins &&
            <BackWrapper>
              <NavLink to={{pathname:`../client/${client.id}`}} style={{paddingTop: 10}}>
              <Icon.BackProducts fill={colors.blue} />
              </NavLink>
              <BackTitle>Back</BackTitle>
              <PictureIcon style={{backgroundImage: `url(${client.pageAdmins[0].pictureURL})`}}></PictureIcon>                                 
            </BackWrapper>              
          }
        </ClientHeaderWrapper>
        <ClientBodyWrapper>
          <ClientBody>
            {
              client && client.pageAdmins &&
              <ClientDetail>
                <ClientLabel fontSize={16}>Admin Details</ClientLabel>
                <SingleRow>
                  <SingleInputRow width={22} marginRight={30}>
                    <Input
                      value={client.pageAdmins[0].name}
                      labelText={'Name'}
                      type={'text'}
                    />
                  </SingleInputRow>      
                  <SingleInputRow width={22} marginRight={30}>
                    <Input
                      value={client.pageAdmins[0].phoneNumber}
                      labelText={'Phone'}
                      type={'text'}
                    />
                  </SingleInputRow>        
                  <SingleInputRow width={22} marginRight={30}>
                    <Input
                      value={client.pageAdmins[0].email}
                      labelText={'Email'}
                      type={'text'}
                    />
                  </SingleInputRow>    
                  <SingleInputRow width={22} marginRight={30}>
                    <Input
                      value={client.pageAdmins[0].location}
                      labelText={'Location'}
                      type={'text'}
                    />
                  </SingleInputRow>                                                                             
                </SingleRow>                                
              </ClientDetail>
            }                          
          </ClientBody>  
          <ClientBody>
              <ClientDetail>
                <ClientLabel fontSize={16}>Total Page Statistics</ClientLabel>
                <SingleRow>
                  <SingleInputRow width={22} marginRight={30}>
                    <LabelWrapper>
                      <Label>Pages</Label>
                    </LabelWrapper>
                    <ClientStatisticLabel color={colors.blue}>
                      {client && client.pageAdmins && client.pageAdmins[0].pages.length}
                    </ClientStatisticLabel>
                  </SingleInputRow>   
                  <SingleInputRow width={22} marginRight={30}>
                    <LabelWrapper>
                      <Label>Clients</Label>
                    </LabelWrapper>
                    <ClientStatisticLabel color={colors.blue}>
                      {client && getSum(client.pageAdmins[0].pages, 'clientCount')}
                    </ClientStatisticLabel>
                  </SingleInputRow>   
                  <SingleInputRow width={22} marginRight={30}>
                    <LabelWrapper>
                      <Label>Orders</Label>
                    </LabelWrapper>
                    <ClientStatisticLabel color={colors.blue}>
                    {client && getSum(client.pageAdmins[0].pages, 'orderCount')}
                    </ClientStatisticLabel>
                  </SingleInputRow>  
                  <SingleInputRow width={22} marginRight={30}>
                    <LabelWrapper>
                      <Label>Revenue</Label>
                    </LabelWrapper>
                    <ClientStatisticLabel color={colors.blue}>
                      {client && currencyFormatter(getSum(client.pageAdmins[0].pages, 'totalRevenue'))}
                    </ClientStatisticLabel>
                  </SingleInputRow>                                                                                                                       
                </SingleRow>                                
              </ClientDetail>              
            </ClientBody>             
        </ClientBodyWrapper>
        <SectionTitleWrapper>
          Active Pages
        </SectionTitleWrapper>
        <Grid>
          {client && client.status !== inactivePageStatusId &&
            <Grid.Section
            key={client.id}
            sizes={{
              desktop: 2,
              tablet: 4,
            }}>
            <NavLink to={{pathname:`../client/${client.id}`}}>
              <PageCardWrapper
                photo={(client.pagePictureURL|| pageImage)}
                title={client.pageName}
                status={client.status}
                inactiveStatus={inactivePageStatusId}
              />
            </NavLink>
            </Grid.Section>
          }          
          {client && client.pageAdmins[0].pages
            .filter(x => { return x.status !== inactivePageStatusId})
            .map(page => (
            <Grid.Section
              key={page.id}
              sizes={{
                desktop: 2,
                tablet: 4,
              }}
            >
            <NavLink to={{pathname:`../client/${page.id}`}}>
              <PageCardWrapper
                photo={(page.pagePictureURL || pageImage)}
                title={page.pageName}
                status={page.status}
                inactiveStatus={inactivePageStatusId}
              />
            </NavLink>
            </Grid.Section>
          ))}
          <Grid.Section
            sizes={{
              desktop: 2,
              tablet: 4,
            }}
          >
          </Grid.Section>
        </Grid>        
        <SectionTitleWrapper>
          Inactive Pages
        </SectionTitleWrapper> 
        <Grid>
        {client && client.status === inactivePageStatusId &&
          <Grid.Section
          key={client.id}
          sizes={{
            desktop: 2,
            tablet: 4,
          }}>
          <NavLink to={{pathname:`../client/${client.id}`}}>
            <PageCardWrapper
              photo={(client.pagePictureURL|| pageImage)}
              title={client.pageName}
              status={client.status}
              inactiveStatus={inactivePageStatusId}
            />
          </NavLink>
          </Grid.Section>
        }
        {client && client.pageAdmins[0].pages
          .filter(x => { return x.status === inactivePageStatusId})
          .map(page => (
            <Grid.Section
              key={page.id}
              sizes={{
                desktop: 2,
                tablet: 4,
              }}
            >
            <NavLink to={{pathname:`../client/${client.id}`}}>
              <PageCardWrapper
                photo={(page.pagePictureURL|| pageImage)}
                title={page.pageName}
                status={page.status}
                inactiveStatus={inactivePageStatusId}
              />
            </NavLink>
            </Grid.Section>
          ))}
          <Grid.Section
            sizes={{
              desktop: 2,
              tablet: 4,
            }}
          >
          </Grid.Section>
        </Grid>               
      </Page>
      );
  }
}

AdminDetailsPage.path = '/admin/details';

const mapStateToProps = state => ({
  adminClients: state.adminClients
});

const mapDispatchToProps = (dispatch, state) => ({
  'getTotalsClients':
    (pageId) => {
      dispatch(getTotalsClients(null, 0, pageId));
    }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminDetailsPage);
