import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const {
  views: { Card },
  helpers: { colors, hexToRGBA, transition },
} = shared;

const IconWrapper = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  background-color: ${props =>
    props.isSelected ? hexToRGBA(colors.white, 0.1) : hexToRGBA(props.color, 0.1)};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  align-self: center;
  transition: ${transition};
`;

const Title = styled.span`
  font-size: 18px;
  line-height: line-height: 22px;
  color: ${props => (props.isSelected ? colors.white : colors.darkestGrey)};
  transition: ${transition};
`;

const SubTitle = styled.span`
  font-size: 16px;
  line-height: 17px;
  color: ${props => (props.isSelected ? colors.white : colors.textColor)};
  transition: ${transition};
`;

const SubTitleWrapper = styled.div`
  margin-top: 5px;
  
`;

const TextColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const CardWrapper = styled(Card)`
  transition: all ${transition};
  perspective: 1000;
  transition: ${transition};
  transform: ${props => (props.isSelected ? 'scale(1.05)' : 'scale(1)')};
  box-shadow: ${props =>
    props.isSelected ? '0px 12px 16px rgba(200, 208, 216, 0.3)' : '0 0 0 rgba(0, 0, 0, 0)'};

  :hover {
    cursor: pointer;
    transform: scale(1.05);
    border: 1px solid transparent;
  }

  @media all and (max-width: 768px) { 
    transform: scale(1);
    :hover {
      transform: scale(1);
    }
  }
`;

const LinearGradient = styled.div`
  perspective: 1000;
  border-radius: 3px;
  display: inline-flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px 20px;
  width: 260px;
  height: 72px;
  background-image: linear-gradient(90deg, #006ccb 0%, #65baff 25%, #fff 50%, #fff 100%);
  background-size: 400% 100%;
  background-position: ${props => (props.isSelected ? 'top 0% right 100%' : 'top 0% right 0%')};

  @media all and (min-width: 768px) and (max-width: 1024px) { 
    width: 140px;
    height: 50px;
    padding: 6px 20px;
    flex-shrink: 1;
  }

  @media all and (max-width: 768px) { 
    width: 100%
    height: 55px;
    padding: 6px 20px;
    flex-shrink: 1;
  }

  transition: background-position 0.25s ease-in;
`;

const StateCard = ({
  title,
  subTitle,
  Icon,
  color = colors.blue,
  updateSelectedElement = () => {},
  isSelected = false,
  ...rest
}) => (
  <CardWrapper onClick={updateSelectedElement} isSelected={isSelected} {...rest}>
    <LinearGradient isSelected={isSelected}>
      <TextColumn>
        <Title isSelected={isSelected}>{title}</Title>
        <SubTitleWrapper>
          <SubTitle isSelected={isSelected}>{subTitle}</SubTitle>
        </SubTitleWrapper>
      </TextColumn>
      <IconWrapper color={color} isSelected={isSelected}>
        <Icon fill={isSelected ? colors.white : colors.blue} />
      </IconWrapper>
    </LinearGradient>
  </CardWrapper>
);

export default StateCard;
