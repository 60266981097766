import React, {useState, useEffect} from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import { format} from 'date-fns';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import styled from 'styled-components';
import shared from 'app/shared';
import Icons from '../../shared/views/icons';
import CategoriesPage from './categories.page';
import { edit,  remove, getAll } from '../../../actions/crudActions';
import crudTypes from '../../../config/crudTypes';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';


const {
  views: { Button, Page, Input, UploadInput, AddItemPopup },
  helpers: { colors },
} = shared;

const EditCategoryHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditCategoryTitle = styled.div`
  font-size: 20px;
  color: ${colors.black};
  margin-left: 22px;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-left: 10px;
    margin-right: 10px;
  }
  @media all and (max-width: 768px){
    display: none;
  }
`;

const EditCategoryDate = styled.div`
  font-size: 12px;
  color: ${colors.grey};
  margin: 10px;
  flex: 1;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const EditCategoryBody = styled.div`
  background: ${colors.white}
  padding: 25px 30px;
  margin-top: 44px;
  display: flex;
  max-height: 700px;
  border: 1px solid #E9EAF1;
  box-sizing: border-box;
  border-radius: 3px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;

const EditCategoryDetail = styled.div`
  width: 30%;
  border-right: 1px solid #e9eaf1;
  padding-right: 55px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    border: none;
    padding-right: 0px;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    border: none;
    padding-right: 0px;
  }
`;

const EditCategoryProducts = styled.div`
  width: 70%;
  padding-left: 55px;
  overflow-y: scroll;

  &::-webkit-scrollbar: {
    width: 3px;
    background-color: ${colors.blue};
    color: ${colors.blue};
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    padding-left: 0px;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    padding-left: 0px;
  }
`;

const SingleProductRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 17px;
  margin-right: 35px;

  @media all and (max-width: 768px) {
     margin-right: 0px;
  }
`;

const SingleInputRow = styled.div`
  width: ${props => `${props.width}%`};
  margin-right: ${props => `${props.marginRight}px`};
  margin-top: ${props => `${props.marginTop}px`};
`;

const EditCategoryLabel = styled.div`
  margin-bottom: 30px;
  font-size: ${props => `${props.fontSize}px`};
  line-height: 22px;
  color: ${colors.blue};

  :hover {
    cursor: ${props => `${props.cursor}`};
  }
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    height: 24px;
    background: rgba(242,69,61, .2);
    color: #F2453D;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding-left: 5px;
    display: ${props => props.show === 'ERROR' ? 'flex' : 'none'}
`;

const EditCategory = props => {
  const [uri, setUri] = useState();
  const [updateStatus, setUpdateStatus] = useState('');
  const [deleteStatus] = useState('');
  const [redirect, setRedirect] = useState('');
  const str = window.location.href;
  const id =   parseInt(str.substring(str.lastIndexOf("/") + 1).trim());
  const filteredCategory = (props.categories && props.categories.data.filter(x => x.id === id)) || [];
  const activeCategory = filteredCategory.length > 0 ? filteredCategory[0]: null;
  const {getAll} = props;

  useEffect(() => {
    if(!activeCategory) {
      getAll(crudTypes.category);
    }
  }, [activeCategory, getAll]);
  
  const changeUpdateStatus = (status) => {
    setUpdateStatus(status);
  }

  // const changeDeleteStatus = (status) => {
  //   setDeleteStatus(status);
  // }
  
  useEffect(() => {
    if (updateStatus === 'OK') {
      setRedirect(true);
    }
  }, [updateStatus])

  const editCategory = data => {
    let category = {
      ...activeCategory,
    };
    category.name = data.name;
    delete category.magProducts
    if(uri) {
      category.imagePath = uri
    }
    props.edit(category.id, crudTypes.category, category, changeUpdateStatus);
  };

  // const deleteProd = () => {
  //   props.remove(activeCategory.id, crudTypes.category, changeDeleteStatus);
  // };

  const exportCSV = () => {
    if (activeCategory) {
      CSVFileExporter(`category-${activeCategory.id}.csv`, [activeCategory]);
    }
  };

  return (
    activeCategory && <Formik
      enableReinitialize
      initialValues={{
        name: (activeCategory && activeCategory.name) || '',
        magProducts: activeCategory == null ? [] : activeCategory.magProducts,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Category name is required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        editCategory(values);
        setSubmitting(false);
      }}
    >
      {innerProps => {
        const { values, touched, errors, handleChange, handleSubmit } = innerProps;
        return (
          <div>
            <Page>
              {(redirect) && <Redirect to={CategoriesPage.path} />}
              <EditCategoryHeaderWrapper>
                <NavLink to={CategoriesPage.path}>
                  <Icons.BackProducts fill={colors.blue} />
                </NavLink>
                <EditCategoryTitle>Edit Category</EditCategoryTitle>
                <EditCategoryDate>Date Created:{`${activeCategory && format(new Date(activeCategory.creationDate), 'MM-DD-YYYY') }`}</EditCategoryDate>
                <Button.WhiteButton
                  color={colors.textColor}
                  text={'Export CSV'}
                  style={{ marginRight: 22 }}
                  onClick={exportCSV}
                />
                <Button.Button color={colors.blue} text={'Save'} onClick={handleSubmit} />
                {/* <Button.Button
                  color={colors.red}
                  text={'Delete'}
                  style={{ marginLeft: 22 }}
                  onClick={() => deleteProd()}
                /> */}
              </EditCategoryHeaderWrapper>
              <EditCategoryBody>
                <EditCategoryDetail>
                  <EditCategoryLabel fontSize={16}>Category Details</EditCategoryLabel>
                  <AddItemPopup.InputRow>
                    <UploadInput 
                    labelText={'Upload Image'} 
                    imagePath= {(activeCategory && activeCategory.imagePath) || null }
                    uriGenerator = { uri => {
                      setUri(uri)
                    }}
                    />
                  </AddItemPopup.InputRow>
                  <AddItemPopup.InputRow>
                    <Input
                      id={'name'}
                      labelText={'Category Name'}
                      onChange={handleChange}
                      value={values.name}
                    />
                    {errors.name && touched.name ? <div>{errors.name}</div> : null}
                  </AddItemPopup.InputRow>
                </EditCategoryDetail>
                <EditCategoryProducts>
                  <ErrorWrapper show={updateStatus}>
                    <Icons.XIcon fill={colors.red} style={{marginRight: 10}} /> 
                    <span>Error editing the category</span>
                  </ErrorWrapper>
                  <ErrorWrapper show={deleteStatus}>
                    <Icons.XIcon fill={colors.red} style={{marginRight: 10}} /> 
                    <span>Error deleting the category</span>
                  </ErrorWrapper>
                  <EditCategoryLabel fontSize={16}>Products</EditCategoryLabel>
                  <FieldArray name={'magProducts'}>
                    {arrayHelpers => (
                      <div>
                        {values.magProducts.map((magProduct, index) => (
                          <div key={index}>
                            <SingleProductRow>
                              <SingleInputRow width={100} marginRight={20}>
                                <Input
                                  name={`magProducts[${index}].name`}
                                  value={magProduct.name}
                                  labelText={index === 0 && 'Item'}
                                  type={'text'}
                                />
                              </SingleInputRow>

                              <SingleInputRow width={20} marginRight={29}>
                                <Input
                                  name={`magProducts[${index}].price`}
                                  value={magProduct.price}
                                  labelText={index === 0 && 'Price'}
                                  type={'number'}
                                />
                              </SingleInputRow>
                              {/* <SingleInputRow marginTop={20} marginRight={20}>
                                <Icons.Cancel
                                  fill={colors.red}
                                  onClick={() => arrayHelpers.remove(index)}
                                  style={{ cursor: 'pointer' }}
                                />
                              </SingleInputRow> */}
                            </SingleProductRow>
                          </div>
                        ))}
                        {/* <EditCategoryLabel
                          fontSize={12}
                          cursor={'pointer'}
                          onClick={() =>
                            arrayHelpers.push({
                              id: 0,
                              name: '',
                              price: '',
                              statusId: 0,
                              deleted: 0,
                            })
                          }
                        >
                          + Add Product
                        </EditCategoryLabel> */}
                      </div>
                    )}
                  </FieldArray>
                </EditCategoryProducts>
              </EditCategoryBody>
            </Page>
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => ({
  categories: state.categories,
  facebookPages: state.facebookPages,
});

EditCategory.path = '/category/editcategory';

export default connect(
  mapStateToProps,
  { edit, remove, getAll },
)(EditCategory);

export { EditCategory };
