import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux'
import personImage from 'assets/images/person_image.png';
import Navigation from './navigation';
import TopBar from './topBar';
import MobileTopBar from './topBar.Mobile';
import Spinner from './spinner';
import mediaQueries from '../helpers/mediaQueries';
 import { setLocalActivePage } from '../../../actions/facebookAction';
import { setLocalUser } from '../../../actions/userActions';
import { getAll } from '../../../actions/crudActions';
import { getNotifications } from '../../../actions/notificationAction';
import { showUpgradeModal } from '../../../actions/subscriptionActions';
import crudTypes from '../../../config/crudTypes';
import * as signalR from '@microsoft/signalr';
import sound from '../../../assets/audio/sound.mp3';

import UpgradeModal from '../../upgrade/views/modal';


const AdminLayoutWrapper = styled.div`
  display: flex;
  position: relative;
`;

const TopBarWrapper = styled.div`
  margin-bottom: 60px;

  ${mediaQueries.mobile(`
    display: none;
  `)}
`;

const MobileTopBarWrapper = styled.div`
  margin-bottom: 10px;
  display: none;

  ${mediaQueries.mobile(`
    display: block;
  `)}
`;

const ContentWrapper = styled.div`
  flex-grow: 2;
  margin: 24px 45px 40px 270px;
  max-width: calc(100vw - 315px);

  @media only screen and (max-width: ${mediaQueries.sizes.phone}px) {
    margin: 10px 15px 40px;
    max-width: calc(100vw - 30px);
  }
`;


const loadInitialData = async (props) => {
  await props.setLocalUser();
  await props.setLocalActivePage();
};

const setupConnection = ({ setConnection, ...props }) => {
  const audio = new Audio(sound);
  setConnection(()=>{
    const modUrl = 'https://api-dev.getmagus.io';
    const hubUrl = `${modUrl}/notificationHub`;

    const connectionHub = new signalR.HubConnectionBuilder()
        .withUrl(hubUrl)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

    const Page = JSON.parse(localStorage.getItem('Page'));
    const method = Page != null ? Page : '';

    connectionHub.on(`${method}`, () => {
      audio.play();
      props.getAll(crudTypes.order);
      props.getNotifications();
    });

    connectionHub.start().catch(err => console.log(err));
    return connectionHub;
  });
}

const AdminLayout = withRouter((props) => {
  const { location, userDetails, activePageSubscription } = props;
  const [redirectTo, setRedirectTo] = useState('');
  const [showNavigation, toggleNavigation] = useState(false);
  const [,setConnection] = useState(null);
  const [actions] = useState({
    getAll: props.getAll,
    setLocalUser: props.setLocalUser,
    setLocalActivePage: props.setLocalActivePage,
    getNotifications: props.getNotifications,
  });

  useEffect(() => {
    const page = localStorage.getItem('Page');
    if (null === page && location.pathname !== '/facebook') {
      setRedirectTo('/facebook');
    }
  }, [location]);

  useEffect(() => {
    if (userDetails.user && location.pathname !== '/upgrade' && location.pathname !== '/settings') {
      if (activePageSubscription && !activePageSubscription.isActive) {
        setRedirectTo('/upgrade');
      }
    }
  }, [userDetails.user, location.pathname, activePageSubscription]);

  useEffect(() => {
    (async () => {
      await loadInitialData({ ...actions });
      await setupConnection({ setConnection, ...actions });
    })();
  }, [actions]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <AdminLayoutWrapper>
      <Navigation
        show={showNavigation}
        toggleNavigation={() => toggleNavigation(!showNavigation)}
      />
      <ContentWrapper>
        <TopBarWrapper>
          <TopBar personImage={personImage} restart={setupConnection}  togglePayment={() => {
            props.showUpgradeModal(true);
          }}/>
        </TopBarWrapper>
        <MobileTopBarWrapper>
          <MobileTopBar toggleNavigation={() => {toggleNavigation(!showNavigation);}} />
        </MobileTopBarWrapper>

        {props.facebookPages.activePage && props.facebookPages.activePage.status === 'Connecting' ? (
          <Spinner size={30} style={{ height: '100px' }} />
        ): props.children}
      </ContentWrapper>
      {activePageSubscription && (activePageSubscription.isTrialSubscription || !activePageSubscription.isActive) ? (
        <UpgradeModal show={props.upgradeModal.show} close={() => {
          props.showUpgradeModal(false);
        }} />
      ) : null}
    </AdminLayoutWrapper>
  );
});

const mapStateToProps = state => ({
  facebookPages: state.facebookPages,
  categories: state.categories,
  products: state.products,
  orders: state.orders,
  clients: state.clients,
  notifications: state.notifications,
  userDetails: state.userDetails,
  upgradeModal: {
    show: state.subscriptions.showUpgradeModal,
  },
  activePageSubscription: state.subscriptions.activePageSubscription,
})

export default connect(mapStateToProps, {
  setLocalUser,
  setLocalActivePage,
  getAll,
  getNotifications,
  showUpgradeModal,
})(AdminLayout);
