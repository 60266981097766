import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import crudTypes from 'config/crudTypes';
import { getAll } from '../../../actions/crudActions';
import StatsSection from './statsSection';
import TopSection from './topSection';
import MobileFilter from '../../shared/views/mobileFilter/mobileFilter';

const {
  helpers: { resetScroll },
  views: { Page, SimpleFilterBar },
} = shared;

const initialState = {
  filters: {
    selectedPeriod: 'today',
  },
  selectedStatCard: 'revenue',
  filteredData: [],
  customDate: {startDate: null, endDate: null},
};

const StatsSectionWrapper = styled.div`
  margin-top: 30px;
`;

const TopSectionWrapper = styled.div`
  margin-top: 60px;
`;

class DashboardPage extends Component {
  state = initialState;

  componentWillMount() {
    this.props.getAll(crudTypes.order);
    this.props.getAll(crudTypes.client);
  }

  componentDidMount() {
    resetScroll();
  }

  updateSelectedPeriod = selectedPeriod => {
    if (selectedPeriod !== 'custom') {
      this.setState({
        customDate: {
          startDate: null,
          endDate: null
        },
      });
    }
    this.setState({ filters: { selectedPeriod } });
  };

  updateCustomDate = customDate => {
    this.setState({customDate: {startDate: customDate.startDate, endDate: customDate.endDate}})
  }
  clearFilters = () => {
    this.setState({ filters: initialState.filters })
    this.setState({ customDate: {startDate: null, endDate: null}})
  };

  updateSelectedStatCard = selectedStatCard => {
    this.setState({ selectedStatCard })
  };

  render() {
    const {
      state: {
        filters: { selectedPeriod },
        selectedStatCard,
        customDate,
      },
      updateSelectedPeriod,
      clearFilters,
      updateSelectedStatCard,
      updateCustomDate,
    } = this;

    const payedOrders = this.props.orders.data.filter(order => {
      return order.orderStatus === 1; // payed orders only
    });

    const totalFilteredOrders = payedOrders;
    const totalFilteredClients = this.props.clients.data

    return (
      <Page>
        <SimpleFilterBar
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
          updateCustomDate={updateCustomDate}
          customDate={customDate}
        />
        <MobileFilter
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
        />
        <StatsSectionWrapper>
          <StatsSection
            selectedElement={selectedStatCard}
            updateSelectedElement={updateSelectedStatCard}
            totalOrders={totalFilteredOrders}
            totalRevenue={totalFilteredOrders}
            totalClients={totalFilteredClients}
            selectedPeriod={selectedPeriod}
            customDates={customDate}
          />
        </StatsSectionWrapper>
        <TopSectionWrapper>
          <TopSection />
        </TopSectionWrapper>
      </Page>
    );
  }
}

DashboardPage.path = '/dashboard';

const mapStateToProps = state => ({
  orders: state.orders,
  clients: state.clients,
});

export default connect(
  mapStateToProps,
  { getAll },
)(DashboardPage);
