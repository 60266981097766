import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux'
import personImage from 'assets/images/person_image.png';
import AdminNavigation from './adminNavigation';
import CentralAdminTopBar from './centralAdminTopBar';
import MobileTopBar from './topBar.Mobile';
import mediaQueries from '../helpers/mediaQueries';
 import { setLocalActivePage } from '../../../actions/facebookAction';
import { setLocalUser } from '../../../actions/userActions';
import { getAll } from '../../../actions/crudActions';
import { getNotifications } from '../../../actions/notificationAction';
import crudTypes from '../../../config/crudTypes';
import * as signalR from '@microsoft/signalr';
import sound from '../../../assets/audio/sound.mp3';


const CentralAdminLayoutWrapper = styled.div`
  display: flex;
  position: relative;
`;

const MobileTopBarWrapper = styled.div`
  margin-bottom: 10px;
  display: none;

  ${mediaQueries.mobile(`
    display: block;
  `)}
`;

const ContentWrapper = styled.div`
  flex-grow: 2;
  margin: 24px 45px 40px 270px;
  max-width: calc(100vw - 315px);

  @media only screen and (max-width: ${mediaQueries.sizes.phone}px) {
    margin: 10px 15px 40px;
    max-width: calc(100vw - 30px);
  }
`;

const loadInitialData = async (props) => {
  await props.setLocalUser();
  await props.setLocalActivePage();
};

const setupConnection = ({ setConnection, ...props }) => {
  const audio = new Audio(sound);
  setConnection(()=>{
    const modUrl = 'https://api-dev.getmagus.io';
    const hubUrl = `${modUrl}/notificationHub`;

    const connectionHub = new signalR.HubConnectionBuilder()
        .withUrl(hubUrl)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

    const Page = JSON.parse(localStorage.getItem('Page'));
    const method = Page != null ? Page : '';

    connectionHub.on(`${method}`, () => {
      audio.play();
      props.getAll(crudTypes.order);
      props.getNotifications();
    });

    connectionHub.start().catch(err => console.log(err));
    return connectionHub;
  });
}

const CentralAdminLayout = (props) => {
  const [showNavigation, toggleNavigation] = useState(false);
  const [,setConnection] = useState(null);
  const [actions] = useState({
    getAll: props.getAll,
    setLocalUser: props.setLocalUser,
    setLocalActivePage: props.setLocalActivePage,
    getNotifications: props.getNotifications,
  });

  useEffect(() => {
    (async () => {
      await loadInitialData({ ...actions });
      await setupConnection({ setConnection, ...actions });
    })();
  }, [actions]);

  if (null === localStorage.getItem('Page')
    && props.location && props.location.pathname !== '/facebook') {
    return <Redirect to="/facebook" />
  }

  return (
    <CentralAdminLayoutWrapper>
      <AdminNavigation
        show={showNavigation}
        toggleNavigation={() => toggleNavigation(!showNavigation)}
      />
      <ContentWrapper>
        <CentralAdminTopBar personImage={personImage} restart={setupConnection}/>
        <MobileTopBarWrapper>
          <MobileTopBar toggleNavigation={() => {toggleNavigation(!showNavigation);}} />
        </MobileTopBarWrapper>
        {props.children}
      </ContentWrapper>
    </CentralAdminLayoutWrapper>
  );
};

const mapStateToProps = state => ({
  facebookPages: state.facebookPages,
  categories: state.categories,
  products: state.products,
  orders: state.orders,
  clients: state.clients,
  notifications: state.notifications
})

export default connect(mapStateToProps, {setLocalUser,setLocalActivePage, getAll, getNotifications})(CentralAdminLayout);
