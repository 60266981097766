import React, {useState} from 'react';
import styled from 'styled-components';
import colors from '../../helpers/colors';
import Icons from '../icons';
import hexToRGBA from '../../helpers/hexToRGBA';
import transition from '../../helpers/transition';

const OverlayWrapper = styled.div`
  @media all and (max-width: 768px) { 
    background: linear-gradient(90deg, ${hexToRGBA('#FAFAFA', 0.9)} 0%, ${hexToRGBA(
      '#fafafa',
      0.5,
    )} 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      position: fixed;
      transition: ${transition};
      visibility: ${props => (props.show ? 'visible' : 'hidden')}
      opacity: ${props => (props.show ? 1 : 0)};
      top: 0;
      left: 0;
      z-index: 9999;    
  }
`;

const FilterPopUpWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.white};
    border: 1px solid #E9EAF1;
    box-sizing: border-box;
    border-radius: 6px;
    max-width: 280px;
    padding-top: 13px;
    display: ${props => (props.show ? 'block' : 'none')}
    @media all and (min-width: 768px){ 
        display: none;
    }
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 280px;
    z-index: 9999;
`;

const FilterPopUpHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;
`;

const FilterPopUpFooter = styled.div`
    display: flex;
    flex-direction: row;
    height: 35px;
    border-top: 1px solid #E9EAF1;
`;

const FilterPopUpBody = styled.div`
  padding-left: 16px;
  padding-top: 35px;
  padding-bottom: 35px;
`;

const RadioButtonLabel = styled.label`
  top: 25%;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
  position: absolute;
`;

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  &:hover ~ ${RadioButtonLabel} {
    background: ${colors.white};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 3px;
      background: ${colors.blue};
    }
  }
  &:checked + ${RadioButtonLabel} {
    background: ${colors.blue};
    border: 1px solid ${colors.blue};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 3px;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
      background: white;
    }
  }
`;

const RadioButtonTxt = styled.div`
    color: ${colors.darkGrey};
    font-size: 12px;
    line-height: 23px;
    font-weight: 500;
    padding-left: 10px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 40px;
  position: relative;
  border-bottom: 1px solid #E9EAF1;

  :last-child {
    border-bottom: none;
  }
`;

const CancelBtn = styled.div`
    background: ${colors.white};
    color: #687B91;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-around;
    border-radius: 0px 0px 5px 0px;
`;
const SearchBtn = styled.div`
    background: ${colors.blue};
    color:  ${colors.white};
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-around;
    border-radius: 0px 0px 5px 0px;
`;

const IconWrapper = styled.div`
    width: 20px;
    height: 20px;
    background-color: rgba(44,152,240,0.1);
    opacity: 0.2;
    border-radius: 3px;
    margin-right: 7px;
`;

const FilterTxtWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #748AA1;
`;

const SelectPeriodWrapper = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;

    /* Text Color */

    color: #748AA1;
`;


const AdminFilterPopUp = props => {
  const [radioSelect, setRadioSelect] = useState(props.selectedPeriod);
  const handleSelectChange = event => {
      const value = event.target.value;
      setRadioSelect(value);
  };
    return (
    <OverlayWrapper show={props.show}>
        <FilterPopUpWrapper show={props.show}>
            <FilterPopUpHeader>
                <FilterTxtWrapper>
                    <IconWrapper>
                        <Icons.FilterIcon fill={colors.blue} />
                    </IconWrapper>
                    <span>Filter</span>
                </FilterTxtWrapper>
                <SelectPeriodWrapper>Select Period</SelectPeriodWrapper>
            </FilterPopUpHeader>
            <FilterPopUpBody>
                <Item>
                    <RadioButton 
                        type="radio"
                        value="today"
                        checked={radioSelect === "'today'"}
                        onChange={event => handleSelectChange(event)}
                        />
                    <RadioButtonLabel />
                    <RadioButtonTxt>Today</RadioButtonTxt>
                </Item>
                <Item>
                  <RadioButton
                    type="radio"
                    value="yesterday"
                    checked={radioSelect === "'yesterday'"}
                    onChange={event => handleSelectChange(event)}
                  />
                  <RadioButtonLabel />
                  <RadioButtonTxt>Yesterday</RadioButtonTxt>
                </Item>
                <Item>
                    <RadioButton 
                        type="radio"
                        value='last-week'
                        checked={radioSelect === "'last-week'"}
                        onChange={event => handleSelectChange(event)}
                        />
                    <RadioButtonLabel />
                    <RadioButtonTxt>Last Week</RadioButtonTxt>
                </Item>
                <Item>
                    <RadioButton 
                        type="radio"
                        value='last-month'
                        checked={radioSelect === "'last-month'"}
                        onChange={event => handleSelectChange(event)}
                        />
                    <RadioButtonLabel />
                    <RadioButtonTxt>Last Month</RadioButtonTxt>
                </Item>
                <Item>
                    <RadioButton 
                        type="radio"
                        value= "total"
                        checked={radioSelect === "'total'"}
                        onChange={event => handleSelectChange(event)}
                        />
                    <RadioButtonLabel />
                    <RadioButtonTxt>Total</RadioButtonTxt>
                </Item>
                {/* <Item>
                    <RadioButton 
                        type="radio"
                        value= "Custom"
                        checked={radioSelect === "'Custom'"}
                        onChange={event => handleSelectChange(event)}
                        />
                    <RadioButtonLabel />
                    <RadioButtonTxt>Custom</RadioButtonTxt>
                </Item> */}
            </FilterPopUpBody>
            <FilterPopUpFooter>
                <CancelBtn onClick={ () => props.toggle() }> Cancel </CancelBtn>
                <SearchBtn 
                    onClick ={ () => { props.updateSelectedPeriod(radioSelect); props.toggle() }}> Search </SearchBtn>
            </FilterPopUpFooter>
        </FilterPopUpWrapper>
    </OverlayWrapper>
    )
}

export default AdminFilterPopUp;
