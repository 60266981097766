import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Page from 'app/shared/views/page';
import Paper from 'app/shared/views/paper';
import text from 'app/shared/views/text';
import colors from 'app/shared/helpers/colors';

import { showUpgradeModal } from 'actions/subscriptionActions';

import {
  UpgradeButton,
  Title,
  Subtitle,
  FeatureList,
} from './elements';

import timeExpired from '../images/timeExpired.svg';

const {
  Paragraph: { P }
} = text;

const Restrict = (props) => {
  const [redirectTo, setRedirectTo] = useState('');

  useEffect(() => {
    if (props.subscription && props.subscription.isActive) {
      setRedirectTo('/dashboard');
    }
  }, [props.subscription]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Page>
      <UpgradePaper>
        <InfoWrapper>
          <ExpiredInfo>Free Trial Expired</ExpiredInfo>
          <TitleWrapper>
            <Title>Upgrade To Orderoo Pro</Title>
          </TitleWrapper>
          <SubtitleWrapper>
            <Subtitle>Orderoo PRO gives you all the tools you need to take care of your customers in Messenger</Subtitle>
          </SubtitleWrapper>
          <FeatureListWrapper>
            <FeatureList>
              <li>Unlimited Storage</li>
              <li>Unlimited Orders</li>
              <li>Unlimited Clients</li>
              <li>Unlimited Products and Categories</li>
              <li>Unlimited Automated Sequences</li>
              <li>Unlimited Facebook Messenger Follow Up Messages</li>
            </FeatureList>
          </FeatureListWrapper>
          <UpgradeButton text="Upgrade" onClick={() => {
            props.showUpgradeModal(true);
          }} />
        </InfoWrapper>
        <IlustrationWrapper>
          <img alt="free trail expired" src={timeExpired} />
        </IlustrationWrapper>
      </UpgradePaper>
    </Page>
  );
};

const UpgradePaper = styled(Paper)`
  padding: 40px 50px 25px 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TitleWrapper = styled.div`
`;

const SubtitleWrapper = styled.div`
  padding-top: 26px;
`;

const IlustrationWrapper = styled.div``;

const ExpiredInfo = styled(P)`
  color: ${colors.textColor};
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
`;

const FeatureListWrapper = styled.div`
  color: ${colors.blue};
  padding: 35px 18px;
`;

const mapStateToProps = state => ({
  subscription: state.subscriptions.activePageSubscription,
});

export default connect(
  mapStateToProps,
  {
    showUpgradeModal,
  }
)(Restrict);
