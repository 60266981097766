import React from 'react';
import { Button } from 'app/shared/views/buttons';
import colors from 'app/shared/helpers/colors';

import Campaigns from './views/campaigns';
// import FbMessenger from './views/fbmessenger';

export const MARKETING_TABLE_HEADS = [
  {
    label: 'FULL NAME',
    key: 'fullName',
    sortable: true,
  },
  {
    label: 'ORDERS',
    key: 'orderCount',
    renderer: ({ orderCount }) => {
      return (
        <Button
          text={orderCount}
          style={{
            backgroundColor: colors.lightGreen,
            textAlign: 'center',
            width: '50%',
            minWidth: '30%',
            padding: '0 auto',
            margin: '0 auto',
            color: colors.green,
            fontSize: '150%',
            fontWeight: 'bold',
          }}
        />
      );
    },
    sortable: true,
  },
  {
    label: 'REVENUE',
    key: 'totalSum',
    renderer: ({ totalSum }) => `$${totalSum}`,
    sortable: true,
  },
  {
    label: 'EMAIL',
    key: 'email',
    sortable: true,
    renderer: ({ email }) => email,
  },
  {
    label: 'PHONE',
    key: 'phone',
    sortable: true,
    renderer: ({ phone }) => phone,
  },
];

export const MARKETING_TABS = [
  {
    text: 'Fb Messenger',
    value: 'messenger',
    component: Campaigns,
  },
  // {
  //   text: 'Campaigns',
  //   value: 'campaings',
  //   component: Campaigns,
  // }
];