import styled from 'styled-components';

const HorizontalList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  overflow-x: visible;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
`;

export default {
  HorizontalList,
};
