import axios from 'axios';
import Url from '../config/api';

const { baseUrl } = Url;

const baseURI = `${baseUrl}/Notification`;

export const getNotifications = (setStatus=null) => {
    return (dipatch) => {
        axios.get(`${baseURI}`)
            .then(res =>{
                if(res.status >= 200 && res.status <= 300){
                    setStatus && setStatus('OK');
                    dipatch({
                        type: 'GET_NOTIFICATIONS_OK',
                        data: res.data
                    })
                }
            })
            .catch(() => {
                setStatus && setStatus('ERROR');
                dipatch({type: 'GET_NOTIFICATIONS_ERROR'})
        })
    }
}

export const markAsRead = (id, setStatus=null) => {
    return (dispatch) => {
        axios.put(`${baseURI}/${id}`)
            .then(res=> {
                if(res.status >= 200 && res.status <= 300){
                    setStatus && setStatus('OK');
                    dispatch({
                        type: 'MARK_AS_READ_OK',
                        id: id
                    })
                }
            })
            .catch(() => {
                setStatus && setStatus('ERROR');
                dispatch({type: 'MARK_AS_READ_ERROR'})
        })
    }
}