import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from '../../../../app/shared';
import { getAllSubscriptions, getAllPages, getAllOrders, getTotalsClients } from '../../../../actions/adminActions';
import StatsSection from './statsSection';
import TopSection from './topSection';
import AdminMobileFilter from '../../../shared/views/mobileFilter/adminMobileFilter';

const {
  helpers: {
    resetScroll
  },
  views: {
    Page,
    AdminSimpleFilterBar
  },
} = shared;

const initialState = {
  filters: {
    selectedPeriod: 'today',
  },
  selectedStatCard: 'revenues',
  filteredData: [],
  customDate: {
    startDate: null,
    endDate: null
  },
};

const StatsSectionWrapper = styled.div`
  margin-top: 30px;
`;

const TopSectionWrapper = styled.div`
  margin-top: 60px;
`;

class AdminDashboardPage extends Component {
  state = initialState;

  componentWillMount() {
    this.props.getAllOrders();
    this.props.getAllPages();
    this.props.getAllSubscriptions();
    this.props.getTotalsClients();
  }

  componentDidMount() {
    resetScroll();
  }

  updateSelectedPeriod = selectedPeriod => {
    if (selectedPeriod !== 'custom') {
      this.setState({
        customDate: {
          startDate: null,
          endDate: null
        },
      });
    }
    this.setState({
      filters: {
        selectedPeriod
      },
    });
  };

  updateCustomDate = customDate => {
    this.setState(
      {
        customDate: {
          startDate: customDate.startDate,
          endDate: customDate.endDate
        }
    })
  }

  clearFilters = () => {
    this.setState({
      filters: initialState.filters
    })
    this.setState({
      customDate: {
        startDate: null,
        endDate: null
      }
    })
  };

  updateSelectedStatCard = selectedStatCard => {
    this.setState({
      selectedStatCard
    })
  };

  render() {
    const {
      state: {
        filters: {
          selectedPeriod
        },
        selectedStatCard,
        customDate,
      },
      updateSelectedPeriod,
      clearFilters,
      updateSelectedStatCard,
      updateCustomDate,
    } = this;

    return (
      <Page>
        <AdminSimpleFilterBar
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
          updateCustomDate={updateCustomDate}
          customDate={customDate}
        />
        <AdminMobileFilter
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
        />
        <StatsSectionWrapper>
          <StatsSection
            selectedElement={selectedStatCard}
            updateSelectedElement={updateSelectedStatCard}
            totalRevenue={this.props.adminSubscriptions.data.filter(x => {return !x.isTrialSubscription && x.isActive})}
            totalPages={this.props.adminPages.data}
            totalSubscriptions={this.props.adminSubscriptions.data.filter(x => {return !x.isTrialSubscription && x.isActive})}
            totalOrders={this.props.adminOrders.data}
            selectedPeriod={selectedPeriod}
            customDates={customDate}
          />
        </StatsSectionWrapper>
        <TopSectionWrapper>
          <TopSection />
        </TopSectionWrapper>
      </Page>
    );
  }
}

AdminDashboardPage.path = '/admin/dashboard';

const mapStateToProps = state => ({
  adminOrders: state.adminOrders,
  adminPages: state.adminPages,
  adminSubscriptions: state.adminSubscriptions,
  adminClients: state.adminClients
});

export default connect(
  mapStateToProps,
  { getAllOrders, getAllPages, getAllSubscriptions, getTotalsClients},
)(AdminDashboardPage);
