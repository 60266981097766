import React, { useState } from 'react';
import styled from 'styled-components';
import PageButton from './pageButton';

const ListWrapper = styled.div`
  overflow: auto;
  max-height: 300px;
`;

const PageList = (props) => {
  const [clicked, setClicked] = useState({
    checked: false,
    id: null,
  });

  const changeState = (id, action, clicked, changeState) => {
    if (clicked.checked) return;
    changeState({
      checked: true,
      id: id,
    });
    action(id);
  };

  return (
    <ListWrapper>
      {props.facebookPages && props.facebookPages.data != null &&
        props.facebookPages.data.map((e, i) => {
          const status =
          props.facebookPages.activePage != null && clicked.id != null && e.page.id === clicked.id
              ? props.facebookPages.activePage.status
              : 'Connect';

          return (
            <PageButton
              key={i}
              title={e.page.pageName}
              pagePicture={e.page.pagePicture}
              {...e}
              click={() => changeState(e.page.id, props.setActivePage, clicked, setClicked)}
              status={status}
            />
          );
        })}
    </ListWrapper>
  );
};

export default PageList ;
