import colors from './helpers/colors';
import hexToRGBA from './helpers/hexToRGBA';
import transition from './helpers/transition';
import menu from './helpers/menu';
import resetScroll from './helpers/resetScroll';
import Navigation from './views/navigation';
import AdminNavigation from './views/adminNavigation';
import TopBar from './views/topBar';
import AdminLayout from './views/admin.layout';
import CentralAdminLayout from './views/centralAdmin.layout';
import FilterBar from './views/filterBar';
import RadioGroup from './views/radioGroup';
import Card from './views/card';
import Text from './views/text';
import TabBar from './views/tabBar';
import { BaseButton, ButtonWithIcon, ButtonSize, Button, GradientButton, WhiteButton, RawButton, MainButton} from './views/buttons';
import Icons from './views/icons';
import Chart from './views/charts/charts';
import Table from './views/table';
import Modal from './views/modal';
import Paginator from './views/paginator';
import Page from './views/page';
import RadioElement from './views/radioElement';
import RadioView from './views/radioView';
import RadioValidator from './validators/radio.validator';
import ChildrenValidator from './validators/children.validator';
import TabsIOS from './views/tabs.ios';
import Input from './views/input';
import creditCardInputMask from './helpers/creditCardInputMask';
import Toggle from './views/toggle';
import AddItemPopup from './views/addItemPopup';
import FilterableTableStructure from './views/filterableTableStructure';
import SimpleFilterBar from './views/simpleFilterBar';
import AdminSimpleFilterBar from './views/adminSimpleFilterBar';
import Grid from './views/grid';
import MainView from './views/mainView';
import CheckBox from './views/checkbox';
import Image from './views/image';
import Hr from './views/hr';
import Spinner from './views/spinner';
import LocationPicker from './views/locationPicker/index';
import Lists from './views/lists';
import Wizard from './views/wizard';
import Alert from './views/alert';
import Paper from './views/paper';
import SelectOption from './views/selectOption'
import currencyFormatter from './helpers/currencyFormatter'

export default {
  views: {
    Image,
    Text,
    MainView,
    Navigation,
    AdminNavigation,
    TopBar,
    ButtonWithIcon,
    Button: {
      BaseButton,
      ButtonSize,
      Button,
      RawButton,
      GradientButton,
      ButtonWithIcon,
      WhiteButton,
      MainButton,
    },
    Hr,
    CheckBox,
    Icons,
    AdminLayout,
    CentralAdminLayout,
    FilterBar,
    RadioGroup,
    Card,
    TabBar,
    Chart,
    Table,
    Paginator,
    Modal,
    Page,
    RadioView,
    RadioElement,
    TabsIOS,
    Input: Input.Input,
    InputArea: Input.InputArea,
    UploadInput: Input.UploadInput,
    Toggle,
    FilterableTableStructure,
    SimpleFilterBar,
    AdminSimpleFilterBar,
    Grid,
    AddItemPopup,
    Spinner,
    LocationPicker,
    Lists,
    Wizard,
    Alert,
    Paper,
    SelectOption,
  },
  helpers: {
    colors,
    hexToRGBA,
    transition,
    menu,
    resetScroll,
    creditCardInputMask,
    currencyFormatter
  },
  validation: {
    RadioValidator,
    ChildrenValidator,
  },
};
