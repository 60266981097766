import React from 'react';
import ReactDOM from 'react-dom';
import NotificationDropdown from './notificationDropdown';
import NotificationButton from './notificiationButton';
import {connect} from 'react-redux';
import {getNotifications, markAsRead} from '../../../actions/notificationAction'


class Notification extends React.Component {
  state = {
    show: false,
    getNotificationsStatus: ''
  };

  constructor(props){
    super(props);
    this.notButton = React.createRef();
    this.notDropdown = React.createRef();
    this.notWrapRef = React.createRef();
  }

  setGetNotificationsStatus = (status) => {
    this.setState({getNotificationsStatus: status})
  }

  componentDidMount (){
    this.props.getNotifications(this.setGetNotificationsStatus);
    this.moveOnScroll();

    document.addEventListener('click', this.outsideClickHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.outsideClickHandler);
  }

  componentDidUpdate () {
    this.moveOnScroll();
  }

  outsideClickHandler = (e) => {
    if (!this.state.show) {
      return;
    }

    const domNode = ReactDOM.findDOMNode(this.refs.wrapper);
    if (!domNode || !domNode.contains(e.target)) {
      this.toggle();
    }
  }

  moveOnScroll(){
    const offsetTop = this.notButton.current.style.offsetTop;
    const offsetHeight = this.notButton.current.style.offsetHeight;

    if(this.notDropdown.current != null){
      this.notDropdown.current.style.top = `${offsetTop + offsetHeight}px`;
    }
  }

  toggle = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { show } = this.state

    let notifications = (this.props.notifications && this.props.notifications.data) || [];
    const unread = notifications.filter(x => x.status === 'UNREAD').length || 0;
    
    notifications.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
    notifications = notifications.slice(0, 7);

    return (
      <div ref={'wrapper'}>
        <NotificationButton refer={this.notButton} onClick={() => this.toggle()} counter={unread} />
        <NotificationDropdown refer={this.notDropdown} markAsRead={this.props.markAsRead} show={show} data={notifications} />
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications
});

export default connect(
  mapStateToProps,
  {
    getNotifications, markAsRead
  }
)(Notification)
