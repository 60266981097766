import React, { Component } from 'react';
import shared from 'app/shared';
import {connect} from 'react-redux';

import MessageWindow from './message.window';
import styled from 'styled-components';

import {getAll} from 'actions/crudActions';
import crudTypes from 'config/crudTypes';
import { filterData } from 'config/helpers/searcher';
import { paginate } from 'config/helpers/paginator';
import { sortData, getSortState } from 'config/helpers/sorter';

import {  MARKETING_TABLE_HEADS } from '../constants';
import { applyCustomFilters } from '../helpers/applyFilters';

const {
  views: {
    Table,
    Card,
    CheckBox,
    FilterableTableStructure: {
      TableWrapper,
      PaginatorWrapper,
    },
    Paginator,
  },
} = shared;

const messageWindowStyle = {
  minWidth: '500px',
  display: 'flex',
};

//styled components
const MessageWindowWrapper = styled.div`
  width: 40%;
  min-height: 100%;
`;

const CampaignsViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CampaignsViewContentWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const CampaignsTableWrapper = styled(TableWrapper)`
  margin-top: unset;
  min-height: 100%;
  width: ${props => props.collapsed ? '60%' : '100%'};
  overflow-y: scroll
`;

class MarketingCampaigns extends Component {
  state = {
    values: [],
    headers: [],
    checked: false,
    keys: [],
    getClientsStatus: '',
    sort: {
      key: 'id',
      order: 1,
    },
    currentPage: 1,
    pageSize: 10,
    selected: [],
  };

  componentWillMount(){
    this.props.getAll(crudTypes.client, (status) => {
      this.setState({getClientsStatus: status});
    });
  }

  componentDidMount() {
    const values = this.props.clients.data.map(el => ({
      ...el,
      checked: this.state.checked,
      executeFunction: () => this.updateCheckedEl(el),
    }));

    this.setState({
      values: values,
      headers: this.getHeaders(this.state.checked),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.setState({
        currentPage: 1,
      });
    }
  }

  getHeaders = checkedHead => {
    const allHead = {
      label: (
        <CheckBox
          checked={checkedHead}
          click={() => this.updateCheckedAll()}
          text={'ALL'}
        />
      ),
      key: 'all',
      renderer: el => {
        if (el === undefined) return;
        const { executeFunction } = el;
        return (
          <div>
            <CheckBox
              checked={this.state.keys.indexOf(el.id) >= 0}
              click={() => executeFunction(el)}
              style={{ marginTop: '18px', width: '18px' }}
            />
            {el.id}
          </div>
        );
      },
      sortable: true,
    };
    return [allHead, ...MARKETING_TABLE_HEADS];
  }

  getValues = (applyFilters = true) => {
    const {
      filters,
    } = this.props;

    let clients = (this.props.clients.data || [])
      .filter(x => x.facebookId !== undefined && x.facebookId != null);

    if (applyFilters) {
      clients = clients.filter(applyCustomFilters(filters))
      clients = filterData(clients, filters.search);
    }

    return clients.map(el => ({
      ...el,
      checked: this.state.checked,
      executeFunction: () => this.updateCheckedEl(el),
    }));
  }


  updateCheckedAll = () => {
    const check = !this.state.checked;

    const keys = check
      ? this.getValues().map(e => {
          return e.id;
        })
      : [];

    this.setState({
      checked: check,
      headers: this.getHeaders(check),
      keys: keys,
    });
  }


  updateCheckedEl = el => {
    const keys = [...this.state.keys];
    const index = this.state.keys.indexOf(el.id);
    const values = this.getValues();
    const length = values.length;

    if (index >= 0) keys.splice(index, 1);
    else keys.push(el.id);


    const check = keys.length === length;

    this.setState({
      checked: check,
      headers: this.getHeaders(check),
      keys: keys,
    });
  }

  updatePaginationPage = page => {
    this.setState({
      currentPage: page,
    });
  }

  updateSort = key => {
    key = key === 'all' ? 'id' : key;
    this.setState(prevState => ({
      sort: getSortState(prevState.sort, key),
    }));
  }

  render() {
    const {
      state: {
        currentPage,
        pageSize,
        sort,
      },
    } = this;

    const shouldDisplay = this.state.keys.length > 0;

    const headers = shouldDisplay
      ? [ ...this.state.headers.slice(0, 4)]
      : this.state.headers;

    const values = this.getValues();
    const sortedValues = sortData([...values], sort);
    const paginatedValues = paginate(sortedValues, currentPage, pageSize);

    const messageWindow = shouldDisplay ? (
      <MessageWindowWrapper>
        <Card style={messageWindowStyle}>
          <MessageWindow
            keys={this.state.keys}
            data={values}
            allData={this.getValues(false)}
            onClickExecute={this.updateCheckedEl}
          />
        </Card>
      </MessageWindowWrapper>
    ) : null;

    return (
      <CampaignsViewWrapper>
        <CampaignsViewContentWrapper>
          <CampaignsTableWrapper collapsed={shouldDisplay}>
            <Table.TableView 
              data={{
                header: headers,
                values: paginatedValues.current,
                action: this.updateSort,
              }} />
          </CampaignsTableWrapper>
          {messageWindow}
        </CampaignsViewContentWrapper>
        <PaginatorWrapper>
          <Paginator
            changePage={this.updatePaginationPage}
            numberOfPages={paginatedValues.pages}
            page={paginatedValues.currentPage}
          />
        </PaginatorWrapper>
      </CampaignsViewWrapper>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.clients
})

export default connect(
  mapStateToProps,
  {getAll}
)(MarketingCampaigns);
