import crudTypes from 'config/crudTypes';
import crudReducer from './crudReducer';

const crudOrderReducer = crudReducer(crudTypes.order);
const initialState = {
  data: [],
  current: {},
};


export default (state = initialState, action) => {
  switch (action.type) {
    case `ORDER_REFUND_OK`:
      return {
        ...state,
        data: state.data.map(order => {
          if (order.id === action.data.id) {
            return action.data;
          }
          return order;
        }),
      };
    
    default:
      return crudOrderReducer(state, action);
  }
};
