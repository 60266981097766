import axios from 'axios';
import Url from '../config/api';

const { baseUrl} = Url;

const baseURI = `${baseUrl}/StripeCredentials`;


export const changeStripeSettings = (data, setStatus=null) => {
    return (dispatch) => {
        axios.post(baseURI, data)
            .then(res => {
                if(res.status === 200){
                    setStatus && setStatus('OK');
                    dispatch({
                        type: 'CHANGE_STRIPE_CONF_OK',
                        data: res.data
                    });
                }
                else{
                    setStatus && setStatus('ERROR');
                    dispatch({
                        type: 'CHANGE_STRIPE_CONF_ERROR'
                    })
                }
            })
            .catch(()=>{
                setStatus && setStatus('ERROR');
                dispatch({
                    type: 'CHANGE_STRIPE_CONF_ERROR'
                })
            })
    } 
}

export const getStripeSettings = (setStatus=null) => {
    return dispatch => {
        axios.get(baseURI)
            .then(res => {
                if(res.status === 200){
                    setStatus && setStatus('OK');
                    dispatch({
                        type: 'GET_STRIPE_CONF_OK',
                        data: res.data
                    })
                }
                else{
                    setStatus && setStatus('ERROR');
                    dispatch({
                        type: 'GET_STRIPE_CONF_ERROR'
                    })
                }
            })
            .catch(()=>{
                setStatus && setStatus('ERROR');
                dispatch({
                    type: 'GET_STRIPE_CONF_ERROR'
                })
            })
    }
}
