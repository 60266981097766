import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import axios from 'axios';

const{
  views: {
    Button:{
      RawButton,
    },
  },
  helpers: {colors}
} = shared;

const BodyWrapper = styled.div`
  min-height: 200px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${colors.lightGrey};
`;

const TextAreaWrapper = styled.textarea`

    border: none;
    overflow: auto;
    outline: none;
    box-shadow: none;
    min-height: 120px;
    height: auto;
    resize: none;
    width: 100%;
    padding: 20px;
    font-size: 17px;
    color: ${colors.darkestGrey};
`;

const SendButton = styled(RawButton)`
  width: 90px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px 15px auto;

  color: ${colors.blue};
  background-color: ${colors.lightBlue};
  font-weight: bold;

  :active {
    opacity: 0.6;
  }
  
  border-radius: 2px;
`;


class MessageInputSection extends React.Component{

  state={
    input: ''
  };

  updateText = (text)=>{
    this.setState({input: text});
  }

  sendText =() =>{

    const messagesToBeSent = this.props.getSelectedNames().map(x => ({
      facebookId: x.facebookId,
      message: this.state.input
    }));

    axios.post('https://api-dev.getmagus.io/api/Marketing', messagesToBeSent).then(() => alert('SENT')).catch(()=> alert('ERROR'))

    this.props.send(this.state.input);
    this.updateText('');
  }

 

  render(){
    return(
      <BodyWrapper>
        <TextAreaWrapper
          placeholder={'Type a message...'}
          value={this.state.input}
          onChange={(e) => this.updateText(e.target.value)}
        />

        <SendButton text={'Send'} onClick={this.sendText}/>
      </BodyWrapper>
    );
  }
}

export default MessageInputSection;
