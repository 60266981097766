import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import shared from 'app/shared';

import CategoryCard from '../components/categoryCard';
import ProductCard from '../components/productCard';
import OrderPanel from '../components/orderPanel';
import Carousel from '../components/carousel';

const{
  views:{
    Text:{
      H4,
    },
    InputArea,
  },
  helpers: {
    colors,
  }
} = shared;

const CategoriesWrapper = styled.div`
`;

const ProductsWrapper = styled.div`
`;

const NoProductsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
`;

const Section = styled(H4)`
  color: ${colors.textColor};
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
  margin: 12px 0 6px 15px;
  ${() => !isMobile() ? `
    margin-left: 38px;
  ` : null}
`;

const CategoryCardStyled = styled(CategoryCard)`
  margin: 0 10px;
`;

const ProductCardStyled = styled(ProductCard)`
  margin: 0 10px;
`;

const OrderDetailsWrapper = styled.div`
  margin: 10px 15px 0 15px;
`;

const OrderDetailsArea = styled(InputArea)`
  & textarea:focus {
    border: 1px solid ${colors.blue};
  }
`;

const ProductSelectStep = ({
  categories,
  order,
  addProduct,
  removeProduct,
  isActive,
  canUpdate,
  orderDescription,
  setOrderDescription,
}) => {
  const [activeCategories] = useState(() => {
    const activeCategories = [...categories].map(category => {
      const activeProducts = category.magProducts.filter(p => !p.disabled);
      return {
        ...category,
        magProducts: activeProducts,
        productCount: activeProducts.length,
      };
    });
    
    return activeCategories.filter(category => category.productCount > 0);
  });
  const [selectedCategory, setSelectedCategory] = useState(activeCategories[0]);
  const [resetProductsScroll, setResetProductsScroll] = useState(false);

  useEffect(() => {
    setResetProductsScroll(true);
  }, [selectedCategory]);

  useEffect(() => {
    if (resetProductsScroll) {
      setResetProductsScroll(false);
    }
  }, [resetProductsScroll]);

  if (!activeCategories.length) {
    return (
      <NoProductsWrapper>
        No products
      </NoProductsWrapper>
    );
  }

  return (
    <>
      {isActive ? (
        <CategoriesWrapper>
          <Section>Category</Section>
          <Carousel showArrows={!isMobile()} nrOfSlides={categories.length} itemWidth={160} selectedCategory={selectedCategory}>
            {
              activeCategories.map(category => (
                <CategoryCardStyled
                  key={category.id}
                  title={category.name}
                  photo={category.imagePath}
                  totalProducts={category.productCount}
                  selected={category.id === selectedCategory.id}
                  qty={order.orderProducts.filter(p => p.categoryId === category.id).reduce((qty, p) => qty += p.qty, 0)}
                  onClick={() => setSelectedCategory(category)}
                />
              ))
            }
          </Carousel>
        </CategoriesWrapper>
      ) : null}
      {isActive && selectedCategory ? (
        <ProductsWrapper>
          <Section>Products</Section>
          <Carousel showArrows={!isMobile()} nrOfSlides={selectedCategory.magProducts.length} itemWidth={160} resetScroll={resetProductsScroll}>
            {
              selectedCategory.magProducts.map(product => (
                <ProductCardStyled
                  key={product.id}
                  title={product.name}
                  photo={product.imagePath}
                  price={product.price}
                  sales={0}
                  qty={(order.orderProducts.find(p => p.id === product.id) || {}).qty || 0}
                  description={product.description}
                  addProduct={() => addProduct(product)}
                  removeProduct={() => removeProduct(product)}
                />
              ))
            }
          </Carousel>
        </ProductsWrapper>
      ) : null}
      <OrderPanel categories={categories} order={order} canUpdate={canUpdate} addProduct={addProduct} removeProduct={removeProduct} />
      {isActive ? (
        <OrderDetailsWrapper>
          <OrderDetailsArea labelText="Order Details" value={orderDescription} max={100}
            onChange={e => {
              const value = e.target.value;
              console.log(value);
              setOrderDescription(value);
            }}
          />
        </OrderDetailsWrapper>
      ): null}
    </>
  );
};

const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
};

export default ProductSelectStep;
