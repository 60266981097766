const initialState = {
    data: []
}

export default (state = initialState, action) => {
    switch(action.type){
        case 'GET_NOTIFICATIONS_OK':
            return {
                data: action.data
            }
        
        case 'MARK_AS_READ_OK':
            return {
                data: state.data.map(x => {
                   if( x.id !== action.id) return x;

                   x.status = "READ";
                   return x;
                })
            }
        default:
            return state
    }
}