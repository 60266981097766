import React, { Component } from 'react';
import styled from 'styled-components';
import isEscape from '../helpers/isEscape';
import slideFromTopAnimation from '../helpers/slideFromTopAnimation';

import Card from './card';
import Icons from './icons';
import transition from '../helpers/transition';
import ChildrenValidator from '../validators/children.validator';
import ToggleValidator from '../validators/toggle.validator';
import hexToRGBA from '../helpers/hexToRGBA';

const OverlayWrapper = styled.div`
  background: linear-gradient(90deg, ${hexToRGBA('#1e7ae5', 0.5)} 0%, ${hexToRGBA(
  '#42bff5',
  0.5,
)} 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  transition: ${transition};
  visibility: ${props => (props.show ? 'visible' : 'hidden')}
  opacity: ${props => (props.show ? 1 : 0)};
  top: 0;
  left: 0;
  z-index: 9999;
`;

/**
 * TODO: Fix color by name
 */
const CustomCard = styled(Card)`
  border-radius: 6px;
  box-shadow: 0px 16px 28px rgba(63, 86, 104, 0.1);
`;

const ContentWrapper = styled.div`
  transition: 0.4s ease-in-out;
  transform: translateY(${props => (props.show ? 0 : '-20px')});
  max-height: 90vh;
  position: relative;
  opacity: ${props => (props.show ? 1 : 0)};
  animation: ${props => (props.show ? slideFromTopAnimation : '')} 0.4s ease-in-out;
`;

const CloseIcon = styled(Icons.Close)`
  width: 42px;
  height: 42px;
  position: absolute;
  top: -40px;
  right: -40px;

  :hover {
    cursor: pointer;
  }
`;

class Overlay extends Component {
  componentDidMount = () => document.addEventListener('keyup', this.toggleOnEscape);

  componentWillUnmount = () => document.removeEventListener('keyup', this.toggleOnEscape);

  toggleOnEscape = event => {
    if (event.defaultPrevented) return;
    const { toggle, show } = this.props;

    if (isEscape(event) && show) toggle();
  };

  render() {
    const { children, show, toggle } = this.props;

    return (
      <OverlayWrapper show={show} onClick={toggle}>
        <ContentWrapper show={show} onClick={event => event.stopPropagation()}>
          <CustomCard>{children}</CustomCard>
          <CloseIcon fill={"white"} onClick={toggle} />
        </ContentWrapper>
      </OverlayWrapper>
    );
  }
}

Overlay.propTypes = {
  children: ChildrenValidator.ChildrenRule.isRequired,
  ...ToggleValidator.ToggleSchema,
};

export default Overlay;
