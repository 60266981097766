import React from 'react';
import shared from 'app/shared';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import productImage from 'assets/images/product_image.png';
import CategoryCard from './categoryCard';
import AddCategoryCard from './addCategoryCard';
import AddCategoryPopup from './addCategoryPopup';
import EditCategory from './editCategory';
import { getAll } from '../../../actions/crudActions';
import crudTypes from '../../../config/crudTypes';
import { paginate } from '../../../config/helpers/paginator';
import { getSortState, sortData } from '../../../config/helpers/sorter';
import { filterData } from '../../../config/helpers/searcher';
import { genericFilter } from 'app/shared/helpers/filterFunctions';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';

const {
  views: {
    Table,
    Button,
    Paginator,
    Page,
    Input,
    Icons,
    Toggle,
    Card,
    FilterableTableStructure,
    RadioView,
    Grid,
    SimpleFilterBar,
  },
  helpers: { colors, transition },
} = shared;

const {
  StatsSectionWrapper,
  ToggleWrapper,
  ActionBarWrapper,
  SearchWrapper,
  TableWrapper,
  PaginatorWrapper,
} = FilterableTableStructure;

const initialState = {
  filters: {
    selectedPeriod: 'today',
  },
  customDate: {startDate: null, endDate: null},
  sort: {
    key: 'id',
    order: 1,
  },
  search: '',
  showAddPopup: false,
  currentPage: 1,
  pageSize: 10,
  toggleValue: true,
  getStatus: ''
};

const GridWrapper = styled.div`
  padding-top: 30px;
`;

const TableWrapperTablet = styled(Card)`
  display: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin-top: 25px;
  }
  @media all and (max-width: 768px) {
    display: block;
    margin-top: 25px;
  }
`;

const cardWrapper = Element => styled(Element)`
  transition: ${transition};
  transform: translateY(0);

  :hover {
    cursor: pointer;
    transform: translateY(-5px);
  }
`;

const ButtonWrapper = styled.div`
    @media all and (max-width: 768px) {
      display: none;
    }
`;

const AddCategoryCardWrapper = cardWrapper(AddCategoryCard);
const CategoryCardWrapper = cardWrapper(CategoryCard);

class CategoriesPage extends React.Component {
  state = initialState;

  setGetStatus = (status) => {
    this.setState({getStatus: status})
  }

  componentWillMount =  () => {
     this.props.getAll(crudTypes.category, this.setGetStatus);
  }

  getTableData = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'ID',
        key: 'id',
        renderer: ({ id }) => id,
        sortable: false,
      },
      {
        label: 'CATEGORY NAME',
        key: 'name',
        sortable: false,
        renderer: ({ name }) => name,
      },
      {
        label: 'NUMBER OF PRODUCTS',
        key: 'productCount',
        sortable: false,
        renderer: ({ productCount }) => (
          <div style={{ textAlign: 'center', width: '35%' }}>
            <Button.Button
              text={`${productCount}`}
              style={{
                backgroundColor: colors.lightBlue,
                textAlign: 'center',
                width: '50%',
                minWidth: '50%',
                padding: '0 auto',
                margin: '0 auto',
                color: colors.blue,
                fontSize: '150%',
                fontWeight: 'bold',
              }}
            />
          </div>
        ),
      },
      {
        label: 'DATE CREATED',
        key: 'creationDate',
        sortable: false,
        renderer: ({ creationDate }) => creationDate,
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'action',
        sortable: false,
        renderer: ({ id }) => {
          return (
            <NavLink
              to={`${EditCategory.path}/${id}`}
              style={{ textDecoration: 'none' }}
            >
              <Button.Button
                style={{ fontWeight: 600 }}
                text={'View'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  getTableDataTablet = (values = []) => ({
    action: this.updateSortState,
    header: [

      {
        label: 'CATEGORY NAME',
        key: 'name',
        sortable: false,
        renderer: ({ name }) => name,
      },
      {
        label: 'PRODUCT COUNT',
        key: 'productCount',
        sortable: false,
        renderer: ({ productCount }) => (
          <div style={{ textAlign: 'center', width: '35%' }}>
            <Button.Button
              text={`${productCount}`}
              style={{
                backgroundColor: '#F6D4FF',
                textAlign: 'center',
                width: '50%',
                minWidth: '50%',
                padding: '0 auto',
                margin: '0 auto',
                color: colors.purple,
                fontSize: '150%',
                fontWeight: 'bold',
              }}
            />
          </div>
        ),
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'action',
        sortable: false,
        renderer: ({ id }) => {
          return (
            <NavLink
              to={`${EditCategory.path}/${id}`}
              style={{ textDecoration: 'none' }}
            >
              <Button.Button
                style={{ fontWeight: 600 }}
                text={'View'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  toggleAddPopup = () => this.setState({ showEditPopup: !this.state.showEditPopup });

  updateSelectedPeriod = selectedPeriod => {
    this.setState(prevState => ({ filters: { ...prevState.filters, selectedPeriod }, currentPage: 1 }));
  };

  updateCustomDate = customDate => {
    this.setState({customDate: {startDate: customDate.startDate, endDate: customDate.endDate}, currentPage: 1 })
  }

  clearFilters = () => {
    this.setState({
      filters: initialState.filters,
      customDate: initialState.customDate,
      currentPage: 1,
    });
    this.setSearchValue('');
  };

  updatePaginatorPage = currentPage => {
    this.setState({ currentPage });
  };

  setSearchValue = val => {
    this.setState({ search: val.trim(), currentPage: 1 });
  };


  toggle = () => this.setState({ toggleValue: !this.state.toggleValue });

  updateSortState = key => {
    this.setState(prevState => ({
      sort: getSortState(prevState.sort, key),
    }));
  };

  createExportCSV = (data) => {
    return () => {
      CSVFileExporter('categories.csv', data);
    };
  };

  render() {
    const {
      state: {
        filters: {
          selectedPeriod,
        },
        customDate,
        currentPage,
        pageSize,
        sort,
        search,
        toggleValue,
        showEditPopup,
      },
      updatePaginatorPage,
      toggle,
      toggleAddPopup,
      createExportCSV,
      updateSelectedPeriod,
      updateCustomDate,
      clearFilters,
    } = this;

    const data =
      (this.props.categories.data &&
        this.props.categories.data.length > 0 && [...this.props.categories.data]) ||
      [];

    const timeFileredData = genericFilter(
      selectedPeriod, 
      data, 
      customDate,
      "creationDate",
      null);

    const searchData = filterData(timeFileredData, search);
    const sortedData = sortData(searchData, sort);
    const paginatedData = paginate(sortedData, currentPage, pageSize);

    return (
      <Page>
        <AddCategoryPopup show={showEditPopup} toggle={toggleAddPopup} />
        <SimpleFilterBar
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
          updateCustomDate={updateCustomDate}
          customDate={customDate}
        />
        <StatsSectionWrapper>
          <ActionBarWrapper>
            <ButtonWrapper>
              <Button.Button
                color={colors.blue}
                text={'Add New'}
                onClick={toggleAddPopup}
                style={{ marginRight: 22 }}
              />
              <Button.WhiteButton
                color={colors.textColor}
                text={'Export CSV'}
                onClick={createExportCSV(sortedData)}
              />
            </ButtonWrapper>
            <ToggleWrapper>
              <Toggle
                show={toggleValue}
                toggle={toggle}
                showLabel={'Grid'}
                hiddenLabel={'List'}
              />
            </ToggleWrapper>
            <SearchWrapper>
              <Input
                start={<Icons.Search />}
                placeholder={'Search'}
                height={33}
                value={search}
                onChange={e => this.setSearchValue(e.target.value)}
              />
            </SearchWrapper>
          </ActionBarWrapper>
          <RadioView selectedElement={toggleValue === true ? 'grid' : 'table'}>
            <RadioView.Element
              Element={({ reference }) => (
                <div ref={reference}>
                  <TableWrapper>
                    <Table.TableView data={this.getTableData(paginatedData.current)} />
                  </TableWrapper>
                  <TableWrapperTablet>
                    <Table.TableView data={this.getTableDataTablet(paginatedData.current)} />
                  </TableWrapperTablet>
                  <PaginatorWrapper>
                    <Paginator
                      changePage={updatePaginatorPage}
                      numberOfPages={paginatedData.pages}
                      page={paginatedData.currentPage}
                    />
                  </PaginatorWrapper>
                </div>
              )}
              value={'table'}
            />
            <RadioView.Element
              Element={({ reference }) => {
                return (
                  <GridWrapper ref={reference}>
                    <Grid>
                      {searchData &&
                        searchData.map(category => (
                          <Grid.Section
                            key={category.id}
                            sizes={{
                              desktop: 2,
                              tablet: 4,
                            }}
                          >
                            <NavLink
                              to={`${EditCategory.path}/${category.id}`}
                              style={{ textDecoration: 'none' }}
                            >
                              <CategoryCardWrapper
                                title={category.name}
                                totalProducts={category.productCount}
                                photo={(category && category.imagePath) || productImage}
                              />
                            </NavLink>
                          </Grid.Section>
                        ))}

                      <Grid.Section
                        sizes={{
                          desktop: 2,
                          tablet: 2,
                        }}
                      >
                        <AddCategoryCardWrapper onClick={toggleAddPopup} />
                      </Grid.Section>
                    </Grid>
                  </GridWrapper>
                );
              }}
              value={'grid'}
            />
          </RadioView>
        </StatsSectionWrapper>
      </Page>
    );
  }
}

CategoriesPage.path = '/categories';

const mapStateToProps = state => ({
  categories: state.categories,
});

export default connect(
  mapStateToProps,
  { getAll },
)(CategoriesPage);

export { CategoriesPage };
