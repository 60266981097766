import React, {useEffect, useState} from 'react';
import { format} from 'date-fns';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import shared from 'app/shared';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Autocomplete } from './autocomplete';
import OrdersPage from './orders.page';
import { edit,  remove, getAll } from '../../../actions/crudActions';
import addOrderPopup from '../../../assets/images/addOrderPopup.svg';
import crudTypes from '../../../config/crudTypes';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';
import { refundOrder } from 'actions/orderActions';

import { ORDER_STATUS, ORDER_PAYMENT_METHODS } from '../constants';

const {
  views: { Page, AddItemPopup, Button, Input, Icons },
  helpers: { colors },
} = shared;

const EditOrderWrapper = styled.div`
  background: ${colors.white};
  border: 1px solid #e9eaf1;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  padding: 0px 30px 0px 30px;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
  }

  @media all and (max-width: 768px){
    flex-direction: column;
  }
`;

const ClientDetailsBodyWrapper = styled.div`
  border-right: 1px solid #e9eaf1;
  padding-top: 30px;
  padding-right: 30px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    border: none;
  }

  @media all and (max-width: 768px){
    border: none;
  }
`;

const AddOrderLabel = styled.div`
  font-size: 24px;
  padding-bottom: 30px;
  color: ${colors.blue};
  font-weight: 500;
  display: flex;
  align-items: center;

  & > * {
    margin-left: 10px;
  }
`;

const OrderDetailsWrapper = styled.div`
  padding-top: 30px;
  width: -webkit-fill-available;
`;

const OrderDetailsHeaderWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-left: 30px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-left: 0px;
  }

  @media all and (max-width: 768px) {
    margin-left: 0px;
  }
`;

const ImgWrapper = styled.div`
  display: inline-flex;
`;

const OrderDetailsBody = styled.div`
  background: ${colors.white}
  display: flex;
  max-height: 300px;

`;

const OrderDetailsItems = styled.div`
  padding-left: 30px;
  overflow-y: scroll;
  width: -webkit-fill-available;
  &::-webkit-scrollbar: {
    width: 3px;
    background-color: ${colors.blue};
    color: ${colors.blue};
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    padding-left: 0px;
  }
  @media all and (max-width: 768px){
    padding-left: 0px;
  }
`;

const SingleProductRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 17px;
`;

const SingleInputRow = styled.div`
  width: ${props => `${props.width}%`};
  margin-right: ${props => `${props.marginRight}px`};
  margin-top: ${props => `${props.marginTop}px`};
`;

// const AddProductLabel = styled.div`
//   margin-bottom: 30px;
//   font-size: ${props => `${props.fontSize}px`};
//   line-height: 22px;
//   color: ${colors.blue};

//   :hover {
//     cursor: ${props => `${props.cursor}`};
//   }
// `;

const TotalLabelWrapper = styled.div`
  width: auto;
  text-align: right;
  margin-right: 50px;
  margin-bottom: 20px;
`;

const TotalLabel = styled.label`
  color: ${props => (props.textColor ? props.textColor : colors.darkGrey)};
  padding-right: 10px;
`;

const OrderDetailsFooter = styled.div`
  margin-left: 24px;
  margin-top: 50px;
  box-shadow: 0px -8px 32px rgba(127, 127, 127, 0.07);
  padding: 5px 20px;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-left: 0px;
  }
  @media all and (max-width: 768px){
    margin-left: 0px;
  }
`;

const EditOrderHeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  align-items: center;
  justify-content: space-around;
  @media all and (max-width: 768px) {
    justify-content: space-between;
  }
`;

const EditOrderTitle = styled.div`
  font-size: 20px;
  color: ${colors.black};
  margin-left: 22px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-right: 22px;
  }
  @media all and (max-width: 768px){
    display: none;
  }
`;

const EditOrderHeaderLabel = styled.div`
  font-size: 12px;
  color: ${colors.grey};
  & > * {
    margin-left: 30px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
  @media all and (max-width: 768px){
    display: none;
  }
`;

const DateCreatedWrapper = styled.span`
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
`;

const AddressInfoWrapper = styled.div`
  display: flex;

  & > * {
    margin-right: 20px;

    :last-child {
      margin-right: 0;
    }
  }
`;

const ErrorWrapper = styled.div`
    width: 95%;
    display: flex;
    height: 24px;
    background: rgba(242,69,61, .2);
    color: #F2453D;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1px 5px;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: ${props => props.show === 'ERROR' ? 'flex' : 'none'}
`;

const WarningWrapper = styled.div`
    width: 100%;
    display: flex;
    background: rgba(255,152,0, .2);
    color: #FF9800;;
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1px 5px;
    margin-top: 10px;
`;

const EditOrder = props => {

  const [updateStatus, setUpdateStatus] = useState('');
  const [, setRefundStatus] = useState('');
  const [deleteStatus] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [total, setTotal] = useState(0);
  const str = window.location.href;
  const id =   parseInt(str.substring(str.lastIndexOf("/") + 1).trim());
  const filteredOrder = (props.orders && props.orders.data.filter(x => x.id === id)) || [];
  const activeOrder = filteredOrder.length > 0 ? filteredOrder[0]: null;
  const { getAll } = props;

  useEffect(() => {
    if(!activeOrder) {
      getAll(crudTypes.order);
      getAll(crudTypes.product);
    }
  }, [getAll, activeOrder]);

  let orderProductsInit =
    activeOrder ? activeOrder.orderProducts.map(prod => ({
      id: prod.id,
      item: prod.name,
      price: prod.price,
      qty: prod.qty,
      productId: prod.productId,
      orderId: prod.orderId,
    })): [];

  const changeUpdateStatus = (status) => {
    setUpdateStatus(status);
  };

  // const changeDeleteStatus = (status) => {
  //   setDeleteStatus(status);
  // }

  useEffect(() => {
    if (updateStatus === 'OK') {
      setRedirect(true);
    }
  }, [updateStatus])

  const exportCSV = () => {
    if (activeOrder) {
      CSVFileExporter(`order-${activeOrder.id}.csv`, [activeOrder]);
    }
  };

  const recalculateTotal = array => {
    const newTotal = array
      .map(orderProduct => {
        return +(orderProduct.qty ? orderProduct.qty : 1) * orderProduct.price;
      })
      .reduce((tot, current) => tot + current, 0);
    setTotal(newTotal);
  };

  const refundOrder = () => {
    if (activeOrder) {
      props.refundOrder(activeOrder.id, setRefundStatus);
    }
  };

  // const deleteProd = () => {
  //  props.remove(activeOrder.id, crudTypes.order, changeDeleteStatus);
  // };

  return (
    <Formik
        enableReinitialize
        initialValues={{
          id: (activeOrder && activeOrder.id) || '',
          clientName: (activeOrder && activeOrder.clientName) || '',
          clientLastName: (activeOrder && activeOrder.clientLastName) || '',
          clientId: (activeOrder && activeOrder.clientId) || '',
          clientPhone: (activeOrder && activeOrder.clientPhone) || '',
          clientEmail: (activeOrder && activeOrder.clientEmail) || '',
          orderLocation: (activeOrder && activeOrder.orderLocation) || '',
          orderLocation2: (activeOrder && activeOrder.orderLocation2),
          orderProducts: [...orderProductsInit],
          pageId: (activeOrder && activeOrder.pageId) || '',
          description: (activeOrder && activeOrder.description) || '',
          customerDescription: (activeOrder && activeOrder.customerDescription) || '',
        }}
        validationSchema={Yup.object().shape({
          clientName: Yup.string().required('First Name is required'),
          clientLastName: Yup.string().required('Last Name is required'),
          clientPhone: Yup.number().required('Phone number is required'),
          clientEmail: Yup.string().email('Email is invalid').nullable(),
          orderLocation: Yup.string().required('Order location can not be empty'),
        })}

        onSubmit={(values, { setSubmitting }) => {
          const newValues = { ...values };
          // delete newValues.clientName;
          // delete newValues.phone;
          // delete newValues.email;
          setSubmitting(true);

          if (!newValues.clientEmail) {
            newValues.clientEmail = null;
          }

          newValues.orderProducts.forEach(product => delete product.item);
          props.edit(newValues.id, crudTypes.order, newValues, changeUpdateStatus);
        }}
      >
        {innerProps => {
          const { values, touched, errors, handleChange, setFieldValue } = innerProps;
          recalculateTotal(values.orderProducts);

          return (
            <div>
              <Page>
                { (redirect) && (<Redirect to={OrdersPage.path} />)}
                <EditOrderHeaderWrapper>
                  <NavLink to={OrdersPage.path}>
                    <Icons.BackProducts fill={colors.blue} />
                  </NavLink>
                  <EditOrderTitle>Edit Order</EditOrderTitle>
                  <EditOrderHeaderLabel style={{ flex: 1 }}>
                    <DateCreatedWrapper>
                      Order ID: {`${activeOrder && activeOrder.id}`}
                    </DateCreatedWrapper>
                    <DateCreatedWrapper>
                      Date Created: {`${activeOrder && format(new Date(activeOrder.orderDate), 'MM-DD-YYYY') }`}
                    </DateCreatedWrapper>
                  </EditOrderHeaderLabel>
                  <Button.WhiteButton
                    color={colors.textColor}
                    text={'Export CSV'}
                    style={{ marginRight: 22 }}
                    onClick={exportCSV}
                  />
                  {/* <Button.Button color={colors.blue} text={'Save'} onClick={handleSubmit} /> */}
                  {/* <NavLink to={OrdersPage.path} onClick={() => deleteProd()} >
                    <Button.Button
                      color={colors.red}
                      text={'Delete'}
                      style={{ marginLeft: 22 }}
                    />
                  </NavLink> */}

                  {/* Only paid orders can be refunded */}
                  {activeOrder && activeOrder.orderStatus === 1 ? (<Button.Button
                    onClick={refundOrder}
                    color={colors.green}
                    text="Reject and Refund"
                    style={{ marginRight: '22px' }}
                  />) : null}
                </EditOrderHeaderWrapper>
                <EditOrderWrapper>
                  <ClientDetailsBodyWrapper>
                    <AddOrderLabel> Client Details</AddOrderLabel>
                    <AddItemPopup.InputRow>
                      <Input labelText={'First Name'} value={values.clientName} id={'clientName'} />
                      {errors.clientName && touched.clientName ? (
                        <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} />{errors.clientName}</WarningWrapper>
                      ) : null}
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow>
                      <Input labelText={'Last Name'} value={values.clientLastName} id={'clientLastName'} />
                      {errors.clientLastName && touched.clientLastName ? (
                        <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} />{errors.clientLastName}</WarningWrapper>
                      ) : null}
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow>
                      <Input labelText={'Phone'} value={values.clientPhone} id={'phone'} />
                      {errors.clientPhone && touched.clientPhone ? <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} />{errors.clientPhone}</WarningWrapper> : null}
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow>
                      <Input labelText={'Email'} value={values.clientEmail} id={'clientEmail'} />
                      {errors.clientEmail && touched.clientEmail ? <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} />{errors.clientEmail}</WarningWrapper> : null}
                    </AddItemPopup.InputRow>
                  </ClientDetailsBodyWrapper>
                  <OrderDetailsWrapper>
                    <OrderDetailsHeaderWrapper>
                      <AddOrderLabel>
                        Order Details
                        {activeOrder && [0,1,5].includes(activeOrder.orderStatus) ? <Button.Button
                          text={`
                            ${ORDER_STATUS[activeOrder.orderStatus]}
                            ${activeOrder.orderStatus === 1 ? ` with ${ORDER_PAYMENT_METHODS[activeOrder.paymentMethod]}` : ''}
                          `}
                          size={Button.ButtonSize.medium}
                          style={{
                            backgroundColor: colors.lightBlue,
                            fontWeight: 'bold',
                            fontSize: '12px',
                            lineHeight: '12px',
                            textAlign: 'center',
                            color: colors.blue,
                            width: 'unset',
                            padding: '0 5px'
                          }}
                        /> : null}
                      </AddOrderLabel>
                      <ImgWrapper>
                        <img src={addOrderPopup} alt={'stripe'} />
                      </ImgWrapper>
                    </OrderDetailsHeaderWrapper>
                    <ErrorWrapper show={updateStatus}>
                      <Icons.XIcon fill={colors.red} style={{marginRight: 10}} width={16} />
                      <span>Error editing the order</span>
                    </ErrorWrapper>
                    <ErrorWrapper show={deleteStatus}>
                      <Icons.XIcon fill={colors.red} style={{marginRight: 10}} width={16} />
                      <span>Error deleting the order</span>
                    </ErrorWrapper>
                    <OrderDetailsBody>
                      <OrderDetailsItems>
                        <FieldArray name={'orderProducts'}>
                          {arrayHelpers => (
                            <div>
                              {values.orderProducts.map((orderItem, index) => (
                                <div key={index}>
                                  <SingleProductRow>
                                    <SingleInputRow width={100} marginRight={20}>
                                      <Autocomplete
                                        index={index}
                                        name={`orderProducts[${index}].name`}
                                        finalValue={privateValue => {
                                          const { name, price, id } = privateValue;
                                          arrayHelpers.replace(index, {
                                            item: name,
                                            price,
                                            productId: id,
                                            qty: 1,
                                            orderId: activeOrder.id,
                                            id: 0,
                                          });
                                        }}
                                        disabled
                                        suggestions={props.products.data}
                                        selected={orderItem}
                                        labelText={'Item'}
                                        lableField={'item'}
                                      />
                                    </SingleInputRow>
                                    <SingleInputRow width={20} marginRight={29}>
                                      <Input
                                        name={`orderItems[${index}].qty`}
                                        onChange={(e) => {
                                          const val = Number(e.target.value.replace('-', ''));
                                          if (isNaN(val) || val === 0) return;
                                          setFieldValue(`orderProducts[${index}].qty`, val);
                                        }}
                                        disabled
                                        value={orderItem.qty}
                                        labelText={index === 0 && 'Quantity'}
                                        type={'number'}
                                        required
                                      />
                                    </SingleInputRow>
                                    <SingleInputRow width={20} marginRight={29}>
                                      <Input
                                        disabled
                                        name={`orderItems[${index}].price`}
                                        onChange={handleChange}
                                        value={orderItem.price}
                                        labelText={index === 0 && 'Price'}
                                        type={'number'}
                                        required
                                      />
                                    </SingleInputRow>
                                    {/* <SingleInputRow marginTop={20} marginRight={20}>
                                      <Icons.Cancel
                                        fill={colors.red}
                                        onClick={() => arrayHelpers.remove(index)}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </SingleInputRow> */}
                                  </SingleProductRow>
                                </div>
                              ))}
                              {/* <AddProductLabel
                                fontSize={12}
                                cursor={'pointer'}
                                onClick={() =>
                                  arrayHelpers.push({
                                    item: '',
                                    price: '',
                                    qty: '',
                                  })
                                }
                              >
                                + Add Product
                              </AddProductLabel> */}
                              <TotalLabelWrapper>
                                <TotalLabel>Tot.</TotalLabel>
                                <TotalLabel textColor={colors.blue}>$ {total}</TotalLabel>
                              </TotalLabelWrapper>
                            </div>
                          )}
                        </FieldArray>
                      </OrderDetailsItems>
                    </OrderDetailsBody>
                    <OrderDetailsFooter>
                      <AddressInfoWrapper>
                        <AddItemPopup.InputRow style={{width:"40%"}}>
                          <Input
                            disabled
                            labelText={'Address'}
                            value={values.orderLocation}
                            name={'orderLocation'}
                          />
                          {errors.orderLocation && touched.orderLocation ? <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} />{errors.orderLocation}</WarningWrapper> : null}
                        </AddItemPopup.InputRow>
                        <AddItemPopup.InputRow style={{ flex: 1 }}>
                          <Input
                            disabled
                            labelText={'Address Details'}
                            value={values.orderLocation2}
                            name={'orderLocation2'}
                          />
                        </AddItemPopup.InputRow>
                      </AddressInfoWrapper>
                      <AddItemPopup.InputRow>
                        <Input disabled labelText={'Note'} value={values.description || values.customerDescription} id={'description'} />
                      </AddItemPopup.InputRow>
                    </OrderDetailsFooter>
                  </OrderDetailsWrapper>
                </EditOrderWrapper>
              </Page>
            </div>
          );
        }}
      </Formik>
  );
};

const mapStateToProps = state => ({
  orders: state.orders,
  products: state.products,
});

EditOrder.path = '/order/editorder';
export default connect(
  mapStateToProps,
  { edit, remove, refundOrder, getAll },
)(EditOrder);
