import React, { Component } from 'react';
import { connect } from 'react-redux';
import shared from '../../../shared';
import { NavLink } from 'react-router-dom';
import AdminClientsPage from './clients.page';
import styled from 'styled-components';
import Icon from '../../../shared/views/icons';
import hexToRGBA from '../../../shared/helpers/hexToRGBA';
import { format } from "date-fns";
import { getTotalsClients, getAllSubscriptions, getSubscriptionInvoices } from '../../../../actions/adminActions';
import SubscriptionsSection from './subscriptionsSection'

const {
  helpers: {
    resetScroll, colors, currencyFormatter
  },
  views: {
    Page,
    Input,
    Toggle
  },
} = shared;

const initialState = {
  toggleValue: true,
};

const ClientHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 768px) {
    justify-content: space-between;
  }
  margin-bottom: 15px;
`;

const BackWrapper = styled.div`
  display: inline-flex;
  width: 200px;
`;

const BackTitle = styled.div`
  font-size: 20px;
  padding-top: 10px;
  color: ${colors.black};
  margin-left: 22px;
  margin-right: 22px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-right: 22px;
  }
  @media all and (max-width: 768px){
    display: none;
  }
`;

const PictureIcon = styled.div`
  border: 1px solid #E9EAF1;
  box-sizing: border-box;
  border-radius: 25px;
  width: 42px;
  height: 42px;
  margin-right: 20px;
  @media all and (max-width: 768px){
    display: none;
  }
`;

const ClientBodyWrapper = styled.div`
  background: ${colors.white}
  display: flex;
  margin-bottom: 45px;
  border: 1px solid #E9EAF1;
  box-sizing: border-box;
  border-radius: 3px;
  flex-direction: column;
  padding: 10px;
`;

const ClientBody = styled.div`
  background: ${colors.white}
  padding: 10px 30px;
  display: flex;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
  }
  @media all and (max-width: 768px){
    flex-direction: column;
  }
`;

const ClientDetail = styled.div`
  padding-right: 55px;
  width: 100%;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    padding-right: 0px;
    margin-right: 10px;
    width: 100%;
  }
  @media all and (max-width: 768px){
    padding-right: 0px;
    margin-right: 10px;
    width: 100%;
  }
  `;

const ClientLabel = styled.div`
  margin-bottom: 30px;
  font-size: ${props => `${props.fontSize}px`};
  line-height: 22px;
  color: ${colors.blue};
`;

const SingleInputRow = styled.div`
  width: ${props => `${props.width}%`};
  margin-right: ${props => `${props.marginRight}px`};
  margin-top: ${props => `${props.marginTop}px`};
`;

const SingleRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 17px;
`;

const ClientStatisticLabel = styled.div`
  width: 100%;
  height: 43px;
  text-align: center;
  font-size: 16px;
  line-height: 23px;
  background-color: ${props => hexToRGBA(props.color, 0.2)};
  border-radius: 3px;
  color: ${props => props.color};
  padding-top: 10px;
`;

const LabelWrapper = styled.div`
  padding-left: 10px;
`;

const Label = styled.label`
  color: ${colors.textColor};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
`;

class ViewClientPage extends Component {
    toggle = () => this.setState({ toggleValue: !this.state.toggleValue });

    state = initialState;

    componentWillMount() {
      let pageId = this.props.match.params.id;
      this.props.getTotalsClients(pageId); 
      this.props.getAllSubscriptions(pageId);  
      this.props.getSubscriptionInvoices(pageId);
    }

    componentDidMount() {
      resetScroll();
    }    

    render() {
      const {
        state: {
          toggleValue
        },
        toggle
      } = this;  

      let client = this.props.adminClients.data[0];
      let subs = this.props.subscriptions;
      let subInvoices = this.props.adminSubInvoices;

      return (
        <Page>
          <ClientHeaderWrapper>
            <BackWrapper>
              <NavLink to={AdminClientsPage.path} style={{paddingTop: 10}}>
                      <Icon.BackProducts fill={colors.blue} />
              </NavLink>
              <BackTitle>Back</BackTitle>
              {client &&
                <PictureIcon style={{backgroundImage: `url(${client.pagePictureURL})`, backgroundSize: 'contain'}}></PictureIcon> 
              } 
            </BackWrapper>
            <Toggle
              show={toggleValue}
              toggle={toggle}
              showLabel={'Page Active'}
              hiddenLabel={'Inactive'}
            />
          </ClientHeaderWrapper>
          <ClientBodyWrapper>
            <ClientBody>
              <ClientDetail>
                <ClientLabel fontSize={16}>Page Admins</ClientLabel>
                <SingleRow>
                  {client && client.pageAdmins &&
                    client.pageAdmins.map((admin, index) => {
                      return (<>
                        <SingleInputRow width={5} marginRight={15}>
                        <PictureIcon style={{backgroundImage: `url(${admin.pictureURL})`}}></PictureIcon>
                        </SingleInputRow> 
                        <SingleInputRow width={30} marginRight={10}>
                          {
                            index === 0 &&
                            <NavLink to={{pathname:`../details/${client.id}`}}>
                              {admin.name + ' (Creator)'}
                            </NavLink>
                          }
                          { index > 0 && admin.name}
                        </SingleInputRow>   
                        </>
                      )
                    })
                  }       
                </SingleRow>                       
              </ClientDetail>              
            </ClientBody>
            <ClientBody>
              <ClientDetail>
                <ClientLabel fontSize={16}>Page Details</ClientLabel>
                <SingleRow>
                  <SingleInputRow width={22} marginRight={30}>
                    <Input
                      value={client && client.pageName}
                      labelText={'Page Name'}
                      type={'text'}
                    />
                  </SingleInputRow>      
                  <SingleInputRow width={22} marginRight={30}>
                    <Input
                      value={client && client.pageId}
                      labelText={'Page ID'}
                      type={'text'}
                    />
                  </SingleInputRow>        
                  <SingleInputRow width={22} marginRight={30}>
                    <Input
                      value={format(client && client.registrationDate, 'YYYY-MM-DD')}
                      labelText={'Date Registered'}
                      type={'text'}
                    />
                  </SingleInputRow>    
                  <SingleInputRow width={22} marginRight={30}>
                    <Input
                      value={client && client.location}
                      labelText={'Location'}
                      type={'text'}
                    />
                  </SingleInputRow>                                                                             
                </SingleRow>                                
              </ClientDetail>              
            </ClientBody>              
            <ClientBody>
              <ClientDetail>
                <ClientLabel fontSize={16}>Page Statistics</ClientLabel>
                <SingleRow>
                  <SingleInputRow width={22} marginRight={30}>
                    <LabelWrapper>
                      <Label>Clients</Label>
                    </LabelWrapper>
                    <ClientStatisticLabel color={colors.blue}>
                      {client && client.clientCount}
                    </ClientStatisticLabel>
                  </SingleInputRow>   
                  <SingleInputRow width={22} marginRight={30}>
                    <LabelWrapper>
                      <Label>Orders</Label>
                    </LabelWrapper>
                    <ClientStatisticLabel color={colors.blue}>
                      {client && client.orderCount}
                    </ClientStatisticLabel>
                  </SingleInputRow>  
                  <SingleInputRow width={22} marginRight={30}>
                     <LabelWrapper>
                      <Label>Revenue</Label>
                    </LabelWrapper>
                    <ClientStatisticLabel color={colors.blue}>
                      {currencyFormatter(client && client.totalRevenue)}
                    </ClientStatisticLabel>
                  </SingleInputRow>      
                  <SingleInputRow width={22} marginRight={30}>
                     <LabelWrapper>
                      <Label>Paid</Label>
                    </LabelWrapper>
                    <ClientStatisticLabel color={colors.blue}>
                      {currencyFormatter(client && client.totalSubPaid)}
                    </ClientStatisticLabel>
                  </SingleInputRow>                                                                                                                                     
                </SingleRow>                                
              </ClientDetail>              
            </ClientBody>    
          </ClientBodyWrapper>  
            {
              subs && subs.data &&
              subInvoices && subInvoices.data &&
              <SubscriptionsSection subs={subs.data} subInvoices={subInvoices.data}> 
              </SubscriptionsSection>
            }
        </Page>
      );
    }
}

ViewClientPage.path = '/admin/client';

const mapStateToProps = state => ({
  adminClients: state.adminClients,
  subscriptions: state.subscriptions,
  adminSubInvoices: state.adminSubInvoices
});

const mapDispatchToProps = (dispatch, state) => ({
  'getTotalsClients':
    (pageId) => {
      dispatch(getTotalsClients(null, 0, pageId));
    },
  'getAllSubscriptions':
    (pageId) => {
      dispatch(getAllSubscriptions(null, pageId))
    },    
  'getSubscriptionInvoices':
    (pageId) => {
      dispatch(getSubscriptionInvoices(null, pageId))
    }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewClientPage);
