import React, { useEffect } from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import { Formik, FieldArray } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import stripe from '../../../assets/images/stripe.svg';
import addOrderPopup from '../../../assets/images/addOrderPopup.svg';
import { Autocomplete } from './autocomplete';
import crudTypes from '../../../config/crudTypes';
import { add, getAll } from '../../../actions/crudActions';

const MAX_SHOWN_ADDRESSES = 3;

const {
  views: { Modal, AddItemPopup, Button, Input, Icons },
  helpers: { colors },
} = shared;

const AddOrderWrapper = styled.div`
  display: flex;
  padding-right: 30px;
  border-bottom: 1px solid #e9eaf1;
`;

const ClientDetailsWrapper = styled.div`
  border-right: 1px solid #e9eaf1;
`;

const AddOrderLabel = styled.div`
  font-size: 24px;
  padding: 30px 30px;
  color: ${colors.blue};
  font-weight: 500;
`;

const ClientDetailsBodyWrapper = styled.div`
  margin: 30px;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #bebebe;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      background: #eeeeee;
    }
  }
  &:checked + ${RadioButtonLabel} {
    background: ${colors.blue};
    border: 1px solid ${colors.blue};
    &::after {
      content: '';
      display: block;
      margin: auto auto;
      border-radius: 50%;
      width: 50%;
      height: 50%;
      margin-top: 25%;
      margin-left: 25%;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
      background: white;
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`;

const RadioButtonText = styled.label`
  color: ${colors.darkGrey};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
  padding-left: 10px;
`;

const OrderDetailsWrapper = styled.div``;

const OrderDetailsHeaderWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const ImgWrapper = styled.div`
  margin-right: 60px;
  display: inline-flex;
`;

const OrderDetailsBody = styled.div`
  background: ${colors.white}
  margin-top: 44px;
  display: flex;
  max-height: 400px;
`;

const OrderDetailsItems = styled.div`
  padding-left: 30px;
  overflow-y: scroll;

  &::-webkit-scrollbar: {
    width: 3px;
    background-color: ${colors.blue};
    color: ${colors.blue};
  }
`;

const SingleProductRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 17px;
`;

const SingleInputRow = styled.div`
  width: ${props => `${props.width}%`};
  margin-right: ${props => `${props.marginRight}px`};
  margin-top: ${props => `${props.marginTop}px`};
  position: relative;
`;

const AddProductLabel = styled.div`
  margin-bottom: 30px;
  font-size: ${props => `${props.fontSize}px`};
  line-height: 22px;
  color: ${colors.blue};

  :hover {
    cursor: ${props => `${props.cursor}`};
  }
`;

const TotalLabelWrapper = styled.div`
  width: auto;
  text-align: right;
  margin-right: 50px;
  margin-bottom: 20px;
`;

const TotalLabel = styled.label`
  color: ${props => (props.textColor ? props.textColor : colors.darkGrey)};
  padding-right: 10px;
`;

const AddOrderFooter = styled.div`
  margin: 25px 30px;
  display: flex;
  text-align: right;
  flex-flow: row-reverse;
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    background: rgba(242,69,61, .2);
    color: #F2453D;
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1px 5px;
    display: ${props => props.show === 'ERROR' ? 'flex' : 'none'}
    width: 90%;
    margin-left: 30px;
`;

const WarningWrapper = styled.div`
    width: 100%;
    display: flex;
    background: rgba(255,152,0, .2);
    color: #FF9800;;
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1px 5px;
    margin-top: 10px;
`;

const { useState } = React;

const AddOrderPopup = props => {
  const [locations, setLocations] = useState([]);
  const [total, setTotal] = useState(0);
  const [customAddress, setCustomAddress] = useState(true);
  const [postStatus, setPostStatus] = useState('');
  const {toggle} = props;

  useEffect(() => {
    if (postStatus === 'OK') {
      toggle()
    }
  }, [postStatus, toggle])

  const changePostStatus = (status) => {
    setPostStatus(status);
  }

  // const handleRadioChange = event => {
  //   const value = event.target.value;
  //   setRadioSelect(value);
  //   setCustomAddress(false)
  // };

  // const handleCustomChange = () => {
  //   setRadioSelect('');
  //   setCustomAddress(true)
  // }

  const recalculateTotal = array => {
    const newTotal = array
      .map(orderProduct => {
        return +(orderProduct.qty ? orderProduct.qty : 1) * orderProduct.price;
      })
      .reduce((tot, current) => tot + current, 0);
    setTotal(newTotal);
  };

  return (
    <Formik
      initialValues={{
        id:0,
        clientName: '',
        clientLastName: '',
        clientId: '',
        clientPhone: '',
        clientEmail: '',
        orderLocation: '',
        orderProducts: [
          {
            item: '',
            price: '',
            qty: '',
            productId: '',
          },
        ],
        address: '',
      }}
      validationSchema={Yup.object().shape({
        clientName: Yup.string().required('First Name is required'),
        clientLastName: Yup.string().required('Last Name is required'),
        clientPhone: Yup.number().required('Phone number is required'),
        clientEmail: Yup.string().email('Email is invalid').nullable(),
        address: Yup.string().required('Order address location is required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        const newValues = { ...values };
        newValues.orderProducts.forEach(product => delete product.item);
        newValues.orderLocation = newValues.address
        delete newValues.address;

        props.add(crudTypes.order, newValues, (status) => {
          if (!newValues.clientId) {
            props.getAll(crudTypes.client);
          }
          changePostStatus(status);
        });
      }}
    >
      {innerProps => {
        const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = innerProps;
        recalculateTotal(values.orderProducts);
        return (
          <div>
            <Modal toggle={props.toggle} show={props.show}>
              <AddOrderWrapper>
                <ClientDetailsWrapper>
                  <AddOrderLabel> Client Details</AddOrderLabel>
                  <ClientDetailsBodyWrapper>
                    <AddItemPopup.InputRow style={{position:'relative'}}>
                      <Autocomplete
                        index={0}
                        id={'clientName'}
                        name={'clientName'}
                        onChange={handleChange}
                        value={values.clientName}
                        labelText={'First Name'}
                        suggestions={props.clients.data}
                        suggestionField={'fullName'}
                        finalValue={privateValue => {
                          values.clientId = privateValue.id;
                          values.fullName = `${privateValue.name} ${privateValue.surname}`;

                          setFieldValue('clientName', privateValue.name);
                          setFieldValue('clientLastName', privateValue.surname);
                          setFieldValue('clientPhone', privateValue.phone || null);
                          setFieldValue('clientEmail', privateValue.email || null);
                          setFieldValue('address', privateValue.locations ? privateValue.locations[0].locationName : '');
                          
                          setLocations([...privateValue.locations]);
                          if (privateValue.locations && privateValue.locations.length) {
                            setCustomAddress(false);
                          } else {
                            setCustomAddress(true);
                          }
                        }}
                        lableField='name'
                      />
                      {errors.clientName && touched.clientName ? (
                        <WarningWrapper><Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} />{errors.clientName}</WarningWrapper>
                      ) : null}
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow style={{position:'relative'}}>
                      <Autocomplete
                        index={0}
                        id={'clientLastName'}
                        name={'clientLastName'}
                        onChange={handleChange}
                        value={values.clientLastName}
                        labelText={'Last Name'}
                        suggestions={props.clients.data}
                        suggestionField={'fullName'}
                        finalValue={privateValue => {
                          values.clientId = privateValue.id;
                          values.fullName = `${privateValue.name} ${privateValue.surname}`;

                          setFieldValue('clientName', privateValue.name);
                          setFieldValue('clientLastName', privateValue.surname);
                          setFieldValue('clientPhone', privateValue.phone || null);
                          setFieldValue('clientEmail', privateValue.email || null);
                          setFieldValue('address', privateValue.locations ? privateValue.locations[0].locationName : '');
                          
                          setLocations([...privateValue.locations]);
                          if (privateValue.locations && privateValue.locations.length) {
                            setCustomAddress(false);
                          } else {
                            setCustomAddress(true);
                          }
                        }}
                        lableField='surname'
                      />
                      {errors.clientName && touched.clientLastName ? (
                        <WarningWrapper><Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} />{errors.clientLastName}</WarningWrapper>
                      ) : null}
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow>
                      <Input labelText={'Phone'} onChange={handleChange} value={values.clientPhone} id={'clientPhone'} />
                      {errors.clientPhone && touched.clientPhone ? <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} /> {errors.clientPhone}</WarningWrapper> : null}
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow>
                      <Input labelText={'Email'} onChange={handleChange} value={values.clientEmail} id={'clientEmail'} />
                      {errors.clientEmail && touched.clientEmail ? <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} /> {errors.clientEmail}</WarningWrapper> : null}
                    </AddItemPopup.InputRow>
                    <RadioButtonText>Address History</RadioButtonText>
                    {locations.slice(0, MAX_SHOWN_ADDRESSES).map(location => {
                      return (
                        <Item key={location.locationName}>
                          <RadioButton
                            type={'radio'}
                            name={'radio'}
                            value={location.locationName}
                            checked={values.address === location.locationName}
                            onChange={event => {
                              setFieldValue('address', event.target.value);
                              setCustomAddress(false);
                            }}
                          />
                          <RadioButtonLabel />
                          <RadioButtonText>{location.locationName}</RadioButtonText>
                        </Item>
                      );
                    })}
                    <Item>
                      <RadioButton
                        id={'checkbox1'}
                        type={'radio'}
                        name={'radio'}
                        value={'Custom'}
                        checked={customAddress === true}
                        onChange={(e) => {
                          setFieldValue('address', '');
                          setCustomAddress(true)
                        }}
                      />
                      <RadioButtonLabel />
                      <RadioButtonText>Custom</RadioButtonText>
                    </Item>
                  </ClientDetailsBodyWrapper>
                </ClientDetailsWrapper>
                <OrderDetailsWrapper>
                  <OrderDetailsHeaderWrapper>
                    <AddOrderLabel> Order Details</AddOrderLabel>
                    <ImgWrapper>
                      <img src={stripe} alt={'stripe'} />
                    </ImgWrapper>
                    <ImgWrapper>
                      <img src={addOrderPopup} alt={'stripe'} />
                    </ImgWrapper>
                  </OrderDetailsHeaderWrapper>
                  <ErrorWrapper show={postStatus}>
                    <Icons.XIcon fill={colors.red} style={{marginRight: 10}} />
                    <span>Error adding the order</span>
                  </ErrorWrapper>
                  <OrderDetailsBody>
                    <OrderDetailsItems>
                      <FieldArray name={'orderProducts'}>
                        {arrayHelpers => (
                          <div>
                            {values.orderProducts.map((orderItem, index) => (
                              <div key={index}>
                                <SingleProductRow>
                                  <SingleInputRow width={100} marginRight={20}>
                                    <Autocomplete
                                      index={index}
                                      name={`orderProducts[${index}].item`}
                                      finalValue={privateValue => {
                                        const { name, price, id } = privateValue;
                                        arrayHelpers.replace(index, {
                                          item: name,
                                          price,
                                          productId: id,
                                          id: 0,
                                          qty: 1
                                        });
                                      }}
                                      suggestions={props.products.data}
                                      selected={orderItem}
                                      labelText={'Item'}
                                      lableField={'item'}
                                    />
                                  </SingleInputRow>
                                  <SingleInputRow width={20} marginRight={29}>
                                    <Input
                                      name={`orderProducts[${index}].qty`}
                                      onChange={e => {
                                        const val = Number(e.target.value.replace('-', ''));
                                        if (isNaN(val) || val === 0) return;
                                        setFieldValue(`orderProducts[${index}].qty`, val);
                                      }}
                                      value={orderItem.qty}
                                      labelText={index === 0 && 'Quantity'}
                                      type={'number'}
                                      min={1}
                                    />
                                  </SingleInputRow>
                                  <SingleInputRow width={20} marginRight={29}>
                                    <Input
                                      name={`orderProducts[${index}].price`}
                                      value={orderItem.price}
                                      labelText={index === 0 && 'Price'}
                                      type={'text'}
                                      enableReinitialize="true"
                                      readonly={true}
                                    />
                                  </SingleInputRow>
                                  <SingleInputRow marginTop={20} marginRight={20}>
                                    <Icons.Cancel
                                      fill={colors.red}
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                        recalculateTotal(values.orderProducts);
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </SingleInputRow>
                                </SingleProductRow>
                              </div>
                            ))}
                            <AddProductLabel
                              fontSize={12}
                              cursor={'pointer'}
                              onClick={() =>
                                arrayHelpers.push({
                                  item: '',
                                  price: '',
                                  qty: '',
                                  orderId: 0,
                                  id: 0,
                                })
                              }
                            >
                              + Add Product
                            </AddProductLabel>
                            <TotalLabelWrapper>
                              <TotalLabel>Tot.</TotalLabel>
                              <TotalLabel textColor={colors.blue}>$ {total}</TotalLabel>
                            </TotalLabelWrapper>
                          </div>
                        )}
                      </FieldArray>
                    </OrderDetailsItems>
                  </OrderDetailsBody>
                  <div style={{ width: '92%', paddingBottom: 20, paddingLeft: 30 }}>
                    {customAddress ? <AddItemPopup.InputRow>
                      <Input labelText={'Address'} onChange={customAddress ? handleChange : null} id={'address'} name="address" disabled={!customAddress} />
                      {errors.address && touched.address ? <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} /> {errors.address}</WarningWrapper> : null}
                    </AddItemPopup.InputRow> : null}
                    <AddItemPopup.InputRow>
                      <Input labelText={'Note'} onChange={handleChange} id={'description'} />
                    </AddItemPopup.InputRow>
                  </div>
                </OrderDetailsWrapper>
              </AddOrderWrapper>
              <AddOrderFooter>
                <Button.Button color={colors.blue} text={'Place Order'} onClick={handleSubmit} />
                <Button.WhiteButton
                  color={colors.textColor}
                  text={'Cancel'}
                  style={{ marginRight: 22 }}
                  onClick={() => {
                    props.toggle()
                  }}
                />
              </AddOrderFooter>
            </Modal>
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => ({
  products: state.products,
  clients: state.clients,
});

export default connect(mapStateToProps, { add, getAll })(AddOrderPopup);
