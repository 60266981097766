import React, { useState, useEffect } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Select from 'react-select';
import { format} from 'date-fns';
import * as Yup from 'yup';
import styled from 'styled-components';
import shared from 'app/shared';
import Icon from '../../shared/views/icons';
import ProductsPage from './products.page';
import hexToRGBA from '../../shared/helpers/hexToRGBA';
import { edit, remove, getAll } from '../../../actions/crudActions';
import { toggleProductStatus } from '../../../actions/productActions';
import crudTypes from '../../../config/crudTypes';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';

const {
  views: { Button, Page, Input, UploadInput, AddItemPopup, Icons, Toggle },
  helpers: { colors },
} = shared;

const EditProductHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
`;

const ProductHeaderActions = styled.div`
`;

const EditProductTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${colors.black};
  margin-right: 10px;

  & > * {
    margin-right: 22px;
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`;

const EditProductDate = styled.div`
  font-size: 12px;
  color: ${colors.grey};

  @media all and (min-width: 768px) and (max-width: 1024px) { 
    display: none;
  }
  @media all and (max-width: 768px){ 
    display: none;
  }
`;

const StatusWrapper = styled.div`
  font-size: 12px;
  color: ${colors.grey};
  display: flex;
  align-items: center;

  & > * {
    padding-left: 15px;
  }
`;

// const ProductsRevenue = styled.label`
//   font-size: 24px;
//   line-height: 23px;
//   flex: 1;
//   color: ${colors.green};
//   margin-right: 5px;
// `;

const EditProductyBody = styled.div`
  background: ${colors.white}
  padding: 25px 30px;
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  max-height: 700px;
  border: 1px solid #E9EAF1;
  box-sizing: border-box;
  border-radius: 3px;

  @media all and (min-width: 768px) and (max-width: 1024px) { 
    flex-direction: column;
    padding-bottom: 30px;
  }

  @media all and (max-width: 768px) { 
    flex-direction: column;
    padding-bottom: 30px;
  }
`;

const EditProductDetail = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & > *:first-child {
    flex: 0 240px;
  }
  
  // padding-right: 55px;
  @media all and (min-width: 768px) and (max-width: 1024px) { 
    width: 100%;
    padding-right: 0px;
  }
  @media all and (max-width: 768px) { 
    width: 100%;
    padding-right: 0px;
  }
`;

const EditProductLabel = styled.div`
  margin-bottom: 30px;
  font-size: ${props => `${props.fontSize}px`};
  line-height: 22px;
  color: ${colors.blue};

  @media all and (min-width: 768px) and (max-width: 1024px) { 
    width: 100%;
    margin-bottom: 10px;
  }
  @media all and (max-width: 768px) { 
    width: 100%;
    margin-bottom: 10px;
  }
`;
const ProductStatistics = styled.div`
  width: 100%;
  margin-top: 10px;
  // padding-left: 55px;

  @media all and (min-width: 768px) and (max-width: 1024px) { 
    width: 100%;
    padding-left: 0px;
    margin-top: 10px;
  }
  @media all and (max-width: 768px){ 
    width: 100%;
    padding-left: 0px;
    margin-top: 10px;
  }
`;

const ProductStatBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  height: 43px;
  text-align: center;
  background-color: ${hexToRGBA(colors.blue, 0.2)};
  border-radius: 3px;
  color: ${colors.blue};
  border: 1px solid #2C98F0;
`;

const Label = styled.label`
  color: ${colors.textColor};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
`;

const LabelWrapper = styled.div`
  padding-left: 10px;
`;

const ProductStatisticsButtons = styled.div`
  display: flex;

  & > * {
    width: 240px;
    padding-right: 25px;
  }
`;

const WarningWrapper = styled.div`
    width: 100%;
    display: flex;
    background: rgba(255,152,0, .2);
    color: #FF9800;;
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding-left: 1px 5px;
    margin-top: 10px;
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    background: rgba(242,69,61, .2);
    color: #F2453D;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding-left: 1px 5px;
    display: ${props => props.show === 'ERROR' ? 'flex' : 'none'}
`;


const EditProduct = props => {
  const [updateStatus, setUpdateStatus] = useState('');
  const [deleteStatus] = useState('');

  const [uri, setUri] = useState();
  const [redirect, setRedirect] = useState('');
  const str = window.location.href;
  const id =   parseInt(str.substring(str.lastIndexOf("/") + 1).trim());
  const filteredProduct = (props.products && props.products.data.filter(x => x.id === id)) || [];
  const activeProduct = filteredProduct.length > 0 ? filteredProduct[0]: null;
  const {getAll} = props;

  const [select, setSelect] = useState({
    value: (activeProduct && activeProduct.categoryId) || 0,
    label: (activeProduct && activeProduct.categoryName) || '',
  });

  useEffect(() => {
    if(!activeProduct) {
      getAll(crudTypes.product);
      getAll(crudTypes.category);
    } else {
      setSelect({
        value: (activeProduct && activeProduct.categoryId) || 0,
        label: (activeProduct && activeProduct.categoryName) || '',
      });
    }
  }, [getAll, activeProduct]);

  const handleSelectChange = val => {
    setSelect(val);
  };

  const changeUpdateStatus = (status) => {
    setUpdateStatus(status);
  }

  const handleStatusChange = () => {
    if (activeProduct) {
      props.toggleProductStatus(activeProduct.id);
    }
  }

  // const changeDeleteStatus = (status) => {
  //   setDeleteStatus(status);
  // }

  useEffect(() => {
    if (updateStatus === 'OK') {
      setRedirect(true);
    }
  }, [updateStatus])

  // const deleteProd = () => {
  //   props.remove(activeProduct.id, crudTypes.product, changeDeleteStatus);
  // };

  const exportCSV = () => {
    if (activeProduct) {
      CSVFileExporter(`product-${activeProduct.id}.csv`, [activeProduct]);
    }
  };


  const getOptions = () =>
    (props.categories &&
      props.categories.data.length > 0 &&
      props.categories.data.map(category => ({
        value: category.id,
        label: category.name,
      }))) ||
    [];

  return (
    activeProduct && <Formik
      enableReinitialize
      initialValues={{
        name: (activeProduct && activeProduct.name) || '',
        price: (activeProduct && activeProduct.price) || '',
        description: (activeProduct && activeProduct.description) || '',
        category: { ...select },
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Product Name is required'),
        price: Yup.number().required('Price is required'),
        category: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.number(),
          })
          .required('Category is required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const editProduct = {
          ...activeProduct,
        };

        editProduct.name = values.name;
        editProduct.price = values.price;
        editProduct.description = values.description;
        editProduct.categoryId = Number(select.value);
        editProduct.categoryName = select.label;

        if(uri) {
          editProduct.imagePath = uri
        }
        props.edit(activeProduct.id, crudTypes.product, editProduct, changeUpdateStatus);
        setSubmitting(true)
      }}
    >
      {innerProps => {
        const { values, touched, errors, handleChange, handleSubmit } = innerProps;
        return (
          <Page>
            {(redirect) && (<Redirect to={ProductsPage.path} />)}
            <EditProductHeaderWrapper>
              <EditProductTitle>
                <NavLink to={ProductsPage.path} style={{ maringRight: '10px' }}>
                  <Icon.BackProducts fill={colors.blue} />
                </NavLink>
                <span>Edit Product</span>
                <EditProductDate>
                  Date Created: {`${activeProduct && format(new Date(activeProduct.creationDate), 'MM-DD-YYYY') }`}
                </EditProductDate>
                {/* <ProductsRevenue>
                  {' '}
                  {`$${(activeProduct && activeProduct.revenue) || 0}`}
                </ProductsRevenue> */}
              </EditProductTitle>
              
              <ProductHeaderActions>
                <Button.WhiteButton
                  color={colors.textColor}
                  text={'Export CSV'}
                  style={{ marginRight: 22 }}
                  onClick={exportCSV}
                />
                <Button.Button color={colors.blue} text={'Save'} onClick={() => handleSubmit()} />
                {/* <Button.Button
                  color={colors.red}
                  text={'Delete'}
                  style={{ marginLeft: 22 }}
                  onClick={() => deleteProd()}
                /> */}
              </ProductHeaderActions>
              
            </EditProductHeaderWrapper>
            <EditProductyBody>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <EditProductLabel fontSize={16}>Product Details</EditProductLabel>
                {activeProduct && <StatusWrapper>
                <Toggle
                  show={activeProduct.disabled}
                  toggle={handleStatusChange}
                  showLabel={'Active'}
                  color={!activeProduct.disabled ? colors.blue : colors.darkGrey}
                />
                </StatusWrapper>}
              </div>
              <EditProductDetail>
                <AddItemPopup.InputRow style={{ paddingRight: '20px' }}>
                  <UploadInput 
                    labelText={'Upload Image'} 
                    imagePath={(activeProduct && activeProduct.imagePath) || null} 
                    uriGenerator = { uri => {
                      setUri(uri)
                    }}
                    />
                </AddItemPopup.InputRow>
                <div>
                  <AddItemPopup.InputRow style={{ flexBasis: '240px', paddingRight: '20px' }}>
                    <Input
                      labelText={'Product Name'}
                      value={values.name}
                      onChange={handleChange}
                      id={'name'}
                    />
                    {errors.name && touched.name ? (
                      <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} /> {errors.name}</WarningWrapper>
                    ) : null}
                  </AddItemPopup.InputRow>
                  <AddItemPopup.InputRow style={{ flexBasis: '240px', paddingRight: '20px' }}>
                    <LabelWrapper>
                    <Label>Category Name</Label>
                  </LabelWrapper>
                    <Select
                      options={getOptions()}
                      onChange={val => handleSelectChange(val)}
                      isSearchable={'false'}
                      style={{ width: '-webkit-fill-available' }}
                      name={'category'}
                      value={select}
                    />
                    {errors.category && touched.category ? <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} /> {errors.category}</WarningWrapper> : null}
                  </AddItemPopup.InputRow>
                </div>
                <div style={{ flex: 1 }}>
                  <AddItemPopup.InputRow style={{ width: '240px'}}>
                    <Input
                      labelText={'Price'}
                      value={values.price}
                      onChange={handleChange}
                      id={'price'}
                    />
                    {errors.price && touched.price ? <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} /> {errors.price}</WarningWrapper> : null}
                  </AddItemPopup.InputRow>
                  <AddItemPopup.InputRow style={{ width: '100%', maxWidth: '550px' }}>
                    <Input
                      labelText={'Description'}
                      value={values.description}
                      onChange={handleChange}
                      id={'description'}
                    />
                    {errors.description && touched.description ? (
                      <div>{errors.description}</div>
                    ) : null}
                  </AddItemPopup.InputRow>
                </div>
              </EditProductDetail>
              <ProductStatistics>
                <EditProductLabel fontSize={16}>Product Statistics</EditProductLabel>
                <ErrorWrapper show={updateStatus}>
                  <Icons.XIcon fill={colors.red} style={{marginRight: 10}} width={16} /> 
                  <span>Error editing the product</span>
                </ErrorWrapper> 
                <ErrorWrapper show={deleteStatus}>
                  <Icons.XIcon fill={colors.red} style={{marginRight: 10}}  /> 
                  <span>Error deleting the product</span>
                </ErrorWrapper>
                <ProductStatisticsButtons>
                  <div>
                    <LabelWrapper>
                      <Label>Clients</Label>
                    </LabelWrapper>
                    <ProductStatBox>{`${activeProduct && activeProduct.clients}`}</ProductStatBox>
                  </div>
                  <div>
                    <LabelWrapper>
                      <Label>Orders</Label>
                    </LabelWrapper>
                    <ProductStatBox>{`${activeProduct && activeProduct.totalQuantity}`}</ProductStatBox>
                  </div>
                  <div>
                    <LabelWrapper>
                      <Label>Revenue</Label>
                    </LabelWrapper>
                    <ProductStatBox>{`$${(activeProduct && activeProduct.revenue) ||0}`}</ProductStatBox>
                  </div>
                </ProductStatisticsButtons>
                
              </ProductStatistics>
            </EditProductyBody>
          </Page>
        );
      }}
    </Formik>
  );
};

EditProduct.path = '/product/editproduct';

const mapStateToProps = state => ({
  products: state.products,
  categories: state.categories,
});

export default connect(
  mapStateToProps,
  { edit,  remove, toggleProductStatus, getAll },
)(EditProduct);
export { EditProduct };
