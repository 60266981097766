import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from '../../../../app/shared';
import FilterBar from '../../../../app/shared/views/filterBar';
import DatePicker from '../../../../app/shared/views/dateRangeFilter';
import ViewClient from './client.page';
import { NavLink } from 'react-router-dom';
import { getTotalsClients } from '../../../../actions/adminActions';
import { format } from "date-fns";
import { paginate } from '../../../../config/helpers/paginator';
import { sortData, getSortState } from '../../../../config/helpers/sorter';
import { filterData } from '../../../../config/helpers/searcher';
import { genericFilter } from '../../../../app/shared/helpers/filterFunctions';
import AdminClientMobileFilter from '../../../../app/shared/views/mobileFilter/adminClientMobileFilter';
import { CSVFileExporter } from '../../../../app/shared/helpers/CSVFileExporter';
import SelectOption from '../../../../app/shared/views/selectOption';

const {
  views: {
    Table,
    Button,
    Paginator,
    Page,
    Input,
    Icons,
    FilterableTableStructure,
    Card
  },
  helpers: { resetScroll, colors, currencyFormatter },
} = shared;

const {
  ActionBarWrapper,
  SearchWrapper,
  TableWrapper,
  PaginatorWrapper,
} = FilterableTableStructure;


const initialState = {
  selectedPeriod: { value: 'total', label: 'Total', },
  selectedSubStatus: { value: '0', label: 'All' },
  customDate: {
    startDate: null,
    endDate: null
  },
  sort: {
    key: 'registrationDate',
    order: -1,
  },
  currentPage: 1,
  search: '',
  pageSize: 10,
};

const SelectFilterWrapper = styled.div`
  width: 150px;
`;

const ButtonWrapper = styled.div`
    @media all and (max-width: 768px) { 
      display: none;
    } 
  `;

const FilterBarWrapper = styled.div`
    margin-bottom: 20px;
  `;

const TableWrapperTablet = styled(Card)`
  display: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin-top: 25px;
  }
  @media all and (max-width: 768px) { 
    display: block;
    margin-top: 25px;
  }
`;

const filerBy = {
  PeriodOptions:
    [
      { value: 'today', label: 'Today' },
      { value: 'yesterday', label: 'Yesterday' },
      { value: 'last-week', label: 'Last Week' },
      { value: 'last-month', label: 'Last Month' },
      { value: 'total', label: 'Total' },
    ],
  StatusOptions:
    [
      { value: '0', label: 'All' },
      { value: '1', label: 'Paid' }, 
      { value: '2', label: 'Free Trial' },
      { value: '3', label: 'Not Paid' },  
    ]
}

const header = [
  {
    label: 'RESTAURANT',
    key: 'pageName',
    sortable: true,
    renderer: ({ pageName }) => pageName,
  },
  {
    label: 'REGISTERED',
    key: 'registrationDate',
    renderer: ({ registrationDate }) => format(registrationDate, 'YYYY-MM-DD'),
    sortable: true,
  },
  {
    label: 'LOCATION',
    key: 'location',
    renderer: ({ location }) => location,
    sortable: true,
  },
  {
    label: 'SUB.PAID',
    key: 'totalSubPaid',
    sortable: true,
    renderer: ({ totalSubPaid }) => {
      return (
          <Button.Button
            style={{ fontWeight: 600 }}
            text={currencyFormatter(totalSubPaid)}
            size={Button.ButtonSize.large}
            color={colors.blue}
          />
      );
    },
  },  
  {
    label: 'STATUS',
    key: 'status',
    sortable: true,
    renderer: ({ status }) => {
      return (
        statusBadge(status)
      );
    },    
  },  
  {
    label: 'ORDERS',
    key: 'orderCount',
    sortable: true,
    renderer: ({ orderCount }) => {
      return (
          <Button.Button
            style={{ fontWeight: 600 }}
            text={orderCount}
            size={Button.ButtonSize.medium}
            color={colors.blue}
          />
      );
    },
  },
  {
    label: 'CLIENTS',
    key: 'clientCount',
    sortable: true,
    renderer: ({ clientCount }) => {
      return (
          <Button.Button
            style={{ fontWeight: 600 }}
            text={clientCount}
            size={Button.ButtonSize.medium}
            color={colors.blue}
          />
      );
    },
  },
  {
    label: 'REVENUE',
    key: 'totalRevenue',
    sortable: true,
    renderer: ({ totalRevenue }) => {
      return (
          <Button.Button
            style={{ fontWeight: 600 }}
            text={currencyFormatter(totalRevenue)}
            size={Button.ButtonSize.large}
            color={colors.blue}
          />
      );
    },
  },
  {
    label: 'ACTION',
    width: '70px',
    key: 'action',
    sortable: false,
    renderer: ({ id }) => {
      return (
        <NavLink to={`${ViewClient.path}/${id}`} >
          <Button.Button
            style={{ fontWeight: 600 }}
            text={"View"}
            size={Button.ButtonSize.medium}
            color={colors.blue}
          />
        </NavLink>
      );
    },
  }
]

const headerTablet = [
  {
    label: 'ID',
    key: 'ID',
    sortable: true,
    renderer: ({ positionNo }) => positionNo,
  },
  {
    label: 'RESTAURANT',
    key: 'pageName',
    sortable: true,
    renderer: ({ pageName }) => pageName,
  },
  {
    label: 'REGISTERED',
    key: 'registrationDate',
    renderer: ({ registrationDate }) => format(registrationDate, 'YYYY-MM-DD'),
    sortable: true,
  },
  {
    label: 'REVENUE',
    key: 'totalRevenue',
    sortable: true,
    renderer: ({ totalRevenue }) => {
      return (
          <Button.Button
            style={{ fontWeight: 600 }}
            text={currencyFormatter(totalRevenue)}
            size={Button.ButtonSize.large}
            color={colors.blue}
          />
      );
    },
  },
  {
    label: 'ACTION',
    width: '70px',
    key: 'action',
    sortable: false,
    renderer: ({ id }) => {
      return (
        <NavLink to={`${ViewClient.path}/${id}`} >
          <Button.Button
            style={{ fontWeight: 600 }}
            text={"View"}
            size={Button.ButtonSize.medium}
            color={colors.blue}
          />
        </NavLink>
      );
    },
  }
]

const statusLabels = [
  "PAID",
  "FREE TRIAL",
  "NOT PAID",
];

const statusBadge = (status) => {
  const labelColors = [
    colors.green,
    colors.grey,
    colors.red,
  ];
  return (
      <Button.Button
        style={{ fontWeight: 600 }}
        text={statusLabels[status-1]}
        size={Button.ButtonSize.large}
        color={labelColors[status-1]}
      />
  )
}

class AdminClientsPage extends Component {
  state = initialState;

  componentWillMount() {
    this.props.getTotalsClients(this.state.selectedSubStatus.value);
  }

  componentDidMount() {
    resetScroll();
  }
  
  updateSelectedPeriod = option => {

    if (option === 'custom') {
      option = { value: 'custom', label: 'Custom' }
    }
    else {
      this.setState({
        customDate: {
          startDate: null,
          endDate: null
        },
      });
    }
    this.setState({
      selectedPeriod: option,
      currentPage: 1
    });
  };

  updateSelectedPaymentStatus = option => {
    this.setState({
      selectedSubStatus: option,
      currentPage: 1
    });
    this.props.getTotalsClients(option.value);
  };

  updateCustomDate = customDate => {
    this.setState(
      {
        customDate: {
          startDate: customDate.startDate,
          endDate: customDate.endDate
        },
        currentPage: 1
      })
  }

  clearFilters = () => {
    this.setState(initialState);
    this.props.getTotalsClients(0);
  };

  updatePaginatorPage = currentPage => {
    this.setState({ currentPage });
  };

  setSearchValue = val => {
    this.setState({ search: val.trim(), currentPage: 1 });
  };

  render() {
    const {
      state:
      {
        selectedPeriod,
        selectedSubStatus,
        customDate,
        currentPage,
        pageSize,
        sort,
        search,     
      },
      updateSelectedPeriod,
      updateSelectedPaymentStatus,
      updateCustomDate,
      clearFilters,
      updatePaginatorPage,
    } = this;

    const timeFilteredData = genericFilter(
      selectedPeriod.value, 
      this.props.clients.data, 
      customDate, 
      "registrationDate", 
      null);

    const searchData = filterData(timeFilteredData, search);
    const sortedData = sortData(searchData, sort);
    const paginatedData = paginate(sortedData, currentPage, pageSize);

    const getTopData = (data) => {
      data.forEach((item, index) => {
        item.positionNo = (currentPage * pageSize - pageSize) + ++index;
      });

      return data;
    }

    const updateSortState = key => {
      this.setState(prevState => ({
        sort: getSortState(prevState.sort, key),
      }));
    };

    const getTableData = (header, data) => {
      return {
        action: updateSortState,
        header: header,
        values: getTopData(data),
      }
    };

    const exportCSV = () => {
      let filtered = sortedData.map((item, index) => ({
        RESTAURANT: item.pageName,
        REGISTERED: item.registrationDate,
        LOCATION: item.location,
        'SUB.PAID': item.totalSubPaid,
        STATUS: statusLabels[item.status-1],
        ORDERS: item.orderCount,
        CLIENTS: item.clientCount,
        REVENUE: item.totalRevenue
      }));
      CSVFileExporter('clients.csv', filtered);
    }

    return (
      <Page>
        <FilterBarWrapper>
          <FilterBar>
            <FilterBar.Components.Title style={{ marginTop: '10px' }}>Filter by:</FilterBar.Components.Title>
            <FilterBar.Components.Title style={{ marginTop: '10px' }}>Period</FilterBar.Components.Title>
            <SelectFilterWrapper>
              <SelectOption
                options={filerBy.PeriodOptions}
                onChange={option => { updateSelectedPeriod(option) }}
                isSearchable={'false'}
                name={'period'}
                value={selectedPeriod}
              />
            </SelectFilterWrapper>
            <FilterBar.Components.Title style={{ marginTop: '10px' }}>Payment Status</FilterBar.Components.Title>
            <SelectFilterWrapper>
              <SelectOption
                options={filerBy.StatusOptions}
                onChange={option => { updateSelectedPaymentStatus(option) }}
                isSearchable={'false'}
                name={'subStatus'}
                value={selectedSubStatus}
              />
            </SelectFilterWrapper>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DatePicker
                selectedElement={selectedPeriod}
                updateSelectedElement={updateSelectedPeriod}
                updateCustomDate={updateCustomDate}
                customDate={customDate}
                clearFilters={clearFilters}
              />
              <div style={{ marginLeft: 20 }}>
                <FilterBar.Components.ClearFilters onClick={clearFilters} />
              </div>
            </div>
          </FilterBar>
          <AdminClientMobileFilter
            selectedPeriod={selectedPeriod}
            updateSelectedPeriod={updateSelectedPeriod}
            clearFilters={clearFilters}>
          </AdminClientMobileFilter>
        </FilterBarWrapper>
        <ActionBarWrapper>
          <ButtonWrapper>
            <Button.WhiteButton
              color={colors.textColor}
              style={{ marginRight: 5 }}
              text={'Export CSV'}
              onClick={exportCSV}
            />
          </ButtonWrapper>
          <SearchWrapper>
            <Input
              start={<Icons.Search />}
              placeholder={'Search'}
              height={33}
              onChange={e => this.setSearchValue(e.target.value)}
            />
          </SearchWrapper>
        </ActionBarWrapper>
        <TableWrapper>
          <Table.TableView data={getTableData(header, paginatedData.current)} />
        </TableWrapper>
        <TableWrapperTablet>
          <Table.TableView data={getTableData(headerTablet, paginatedData.current)} />
        </TableWrapperTablet>
        <PaginatorWrapper>
          <Paginator
            changePage={updatePaginatorPage}
            numberOfPages={paginatedData.pages}
            page={paginatedData.currentPage}
          />
        </PaginatorWrapper>
      </Page>
      );
  }
}

AdminClientsPage.path = '/admin/clients';

const mapStateToProps = state => ({
  clients: state.adminClients
});

const mapDispatchToProps = (dispatch, state) => ({
  'getTotalsClients':
    (selectedPaymentStatus) => {
      dispatch(getTotalsClients(null, selectedPaymentStatus));
    },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminClientsPage);
