import React from 'react';
import styled from 'styled-components';
import colors from '../helpers/colors';
import transition from '../helpers/transition';
import hexToRGBA from '../helpers/hexToRGBA';

const ToggleContainer = styled.div`
  width: 36px;
  height: 20px;
  display: flex;
  background-color: ${props => hexToRGBA(props.color, 0.36)};
  border-radius: 9999px;
  margin-left: ${props => (props.showLabel !== null ? '17px' : '0px')};
  margin-right: ${props => (props.hiddenLabel !== null ? '17px' : '0px')};
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
  margin-left: ${props => (props.show === true ? '0px' : '16px')};
  transition: ${transition};
  border-radius: 9999px;
`;

const Label = styled.span`
  font-size: 12px;
  color: ${colors.textColor};
`;

const Toggle = ({
  show,
  toggle,
  showLabel = null,
  hiddenLabel = null,
  color = colors.blue,
  ...rest
}) => (
  <ToggleWrapper onClick={toggle}>
    <Label>{showLabel}</Label>
    <ToggleContainer color={color} showLabel={showLabel} hiddenLabel={hiddenLabel} {...rest}>
      <Circle color={color} show={show} />
    </ToggleContainer>
    <Label>{hiddenLabel}</Label>
  </ToggleWrapper>
);

export default Toggle;
