import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

import { PAYMENT_OPTIONS } from '../../constants';

const {
  views: {
    Text: {
      Paragraph: { P },
    },
    Input,
    RadioGroup,
    RadioElement,
  },
  helpers: {
    colors,
  }
} = shared;

const PaymentDetailsContainer = styled.div`
  margin-top: 12px;
  margin: 0 15px;

  & > * {
    margin-bottom: 5px;
  }
`;

const PaymentRadioOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3px;

  & > * {
    margin-right: 7px;
    margin-bottom: 7px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
  }
`;

const ClientInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  & > * {
    width: calc(50% - 10px);
    margin-bottom: 10px;
  }

  & :nth-child(3) {
    width: 100%;
  }
`;

const TextInput = styled(Input)`
  & input:focus {
    border: 1px solid ${colors.blue};
  }
`;

const PaymentRadioOption = ({ isSelected, updateSelectedElement, text }) => {
  return (
    <PaymentRadioOptionWrapper>
      <RadioElement isSelected={isSelected} updateSelectedElement={updateSelectedElement} />
      <P onClick={updateSelectedElement}>{text}</P>
    </PaymentRadioOptionWrapper>
  );
};

const PaymentDetailsStep = ({ isActive, paymentMethod, setPaymentMethod, clientInfo, setClientInfo, canUpdate, canPayWithStripe }) => {
  return (
    <PaymentDetailsContainer>
      <RadioGroup
        selectedElement={paymentMethod.methodId}
        updateSelectedElement={(methodId) => {
          const paymentMethod = Object.values(PAYMENT_OPTIONS).find(method => {
            return method.methodId === methodId;
          });

          if (paymentMethod) {
            setPaymentMethod(paymentMethod);
          }
        }}>
        {
          Object.values(PAYMENT_OPTIONS).map(method => {
            if (!canPayWithStripe && method.methodId === PAYMENT_OPTIONS.WITH_CARD.methodId) {
              return null;
            }

            return (
              <RadioGroup.Element
                key={method.methodId}
                value={method.methodId}
                text={method.methodText}
                Element={PaymentRadioOption}
              />
            );
          })
        }
      </RadioGroup>
      <ClientInfoWrapper>
        <TextInput type="text" labelText="First Name"
          value={clientInfo.firstName}
          onChange={(e) => {
            const value = e.target.value;
            if (!value || validInput(value, /^[a-zA-Z]+$/)) {
              setClientInfo({ firstName: value });
            }
          }}
          readOnly={!canUpdate}
        />
        <TextInput type="text" labelText="Last Name"
          value={clientInfo.lastName}
          onChange={(e) => {
            const value = e.target.value;
            if (!value || validInput(value, /^[a-zA-Z]+$/)) {
              setClientInfo({ lastName: value });
            }
          }}
          readOnly={!canUpdate}
        />
        <TextInput type="text" labelText="Phone Number"
          value={clientInfo.phone}
          onChange={(e) => {
            const value = e.target.value;
            if (!value || validInput(value, /(^[\d\\+])[\d]*$/)) {
              setClientInfo({ phone: value });
            }
          }}
          readOnly={!canUpdate}
        />
      </ClientInfoWrapper>
    </PaymentDetailsContainer>
  );
};

const validInput = (value, regex) => {
  value = String(value || '');
  return regex.test(value);
};

export default PaymentDetailsStep;
