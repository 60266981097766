import React, { useState } from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const {
  views: {
    Card,
    Text,
    Icons: { Add, Remove, Info, XIcon },
    Button: { BaseButton }
  },
  helpers: { colors },
} = shared;

const {
  Paragraph:{ P },
  H5,
} = Text;

const ProductCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  perspective: 1000px;
`;

const ProductBaseCard = styled(Card)`
  display: flex;
  flex-direction: column;
`;

const PhotoContainer = styled.div`
  height: 70%;
  background: url(${props => props.photo}) center center;
  background-size: cover;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

const InfoWrapper = styled.div`
  padding: 2px 5px;
  border: 1px solid ${props => props.selected ? colors.darkGrey : colors.lightGrey }
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-sizing: border-box;
  background-color: ${colors.lightGrey};

  & .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: ${props => props.selected ? colors.white : colors.blue}
  }

  & .label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    color: ${props => props.selected ? colors.white : colors.textColor}
  }

  & .value {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: ${props => props.selected ? colors.white : colors.blue}
  }

   & .price {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.green};
   }
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  & .count {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 14px;
    color: ${colors.darkestGrey}
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProductButton = styled(BaseButton)`
  border: 1px solid #ebeaf2;
  padding: 6px 12px;
`;

const FlipCardWrapper = styled.div`
  width: 140px;
  min-width: 140px;
  height: 100px;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  & > * {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  ${props => props.flipped ? `
    transform: rotateY(180deg);
  ` : null}
`;

const DescriptionBox = styled.div`
  background: linear-gradient(180deg, #42A5F5 0%, #3EA1F2 24.28%, #3296E7 49.15%, #1D83D6 74.27%, #016ABF 99.45%, #0069BE 99.93%);
  border-radius: 3px;
  transform: rotateY(180deg);

  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const DescriptionText = styled(P)`
  word-break: break-all;
  padding-top: 38px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: white;
  max-width: calc(100% - 16px);
  // max-height: calc(100% - 16px);
`;

const DescriptionInfoIcon = styled(Info)`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 18px;
  height: 18px;
  background: transparent;
`;

const DescriptionCloseIcon = styled(XIcon)`
  position: absolute;
  top: 13px;
  left: 13px;
  background: transparent;
  width: 18px;
  height: 18px;

  ${props => props.flipped ? `
    visibility: visible;
    transition-delay: 0.2s;
  ` : `
    visibility: hidden;
    transition-delay: 0.2s;
  `}
`;

const ProductAddBtn = (props) => (
  <ProductButton {...props} style={{ backgroundColor: '#F1BF3C' }}>
    <Add fill={colors.white} width="16px" height="16px" />
  </ProductButton>
);

const ProductRemoveBtn = (props) => (
  <ProductButton {...props}>
    <Remove fill={colors.darkGrey} width="16px" height="16px" />
  </ProductButton>
);

const ProductCard = ({ title, price, photo, qty, description, addProduct, removeProduct, ...rest }) => {
  const [flipped, setFlipped] = useState(false);
  const onFlipCardClick = () => {
    if (!description) return;
    setFlipped(!flipped)
  };

  return (
    <ProductCardWrapper {...rest}>
      <FlipCardWrapper onClick={onFlipCardClick} flipped={flipped}>
        <ProductBaseCard>
          {description ? <DescriptionInfoIcon fill={colors.white} /> : null }
          <PhotoContainer photo={photo} />
          <InfoWrapper>
            <Row>
              <H5 className="title">{title}</H5>
            </Row>
            <Row>
              <P className="label">Price</P>
              <P className="value price">${price}</P>
            </Row>
          </InfoWrapper>
        </ProductBaseCard>
        {description ? <DescriptionBox>
          <DescriptionCloseIcon fill={colors.white} flipped={flipped} />
          <DescriptionText>{truncateDescription(description, 80)}</DescriptionText>
        </DescriptionBox> : null}
      </FlipCardWrapper>
      <ActionsWrapper>
        <ProductRemoveBtn onClick={removeProduct} />
        <P className="count">{qty}</P>
        <ProductAddBtn onClick={addProduct} />
      </ActionsWrapper>
    </ProductCardWrapper>
  );
}

const truncateDescription = (text, maxLen) => {
  if (text.length > maxLen)  {
    const truncatedText = text.substr(0, maxLen);
    text = truncatedText.substr(0, truncatedText.lastIndexOf(' '));
    text = `${text}...`;
  }
  return text;
};

export default ProductCard;
