import React from 'react';
import styled from 'styled-components';
import colors from 'app/shared/helpers/colors';

const Spinner = styled.div`
  display: inline-block;
  position: relative;
  margin: auto;

  width: ${props => props.size}px;
  height: ${props => props.size}px;
  left: calc(50% - ${props => props.size / 2 }px);
  top: calc(50% - ${props => props.size / 2 }px);

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border: ${props => (props.size / 10) - 1}px solid ${colors.blue};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${colors.blue} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const defaultStyle = {
  display: 'inline-block',
  width: '100%',
  height: '100%',
};

export default ({ size = 40, style }) => (
  <div style={{ ...defaultStyle, ...style }}>
    <Spinner size={size}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Spinner>
  </div>
);
