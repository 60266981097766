import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

import logo from 'assets/icons/logo.svg';

const {
  views: {
    Image: {
      Image: {
        Images:{ TinyImage },
        ImageWrapper,
      }
    }
  },
  helpers: {
    colors,
  }
} = shared;

const MainBox = styled.div`
  margin-top: 20px;
  max-width: 600px;
  min-height: calc(100vh - 20px);
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  color: ${colors.textColor};
  background: ${colors.white};
  border: 1px solid #E9EAF1;
  box-sizing: border-box;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  overflow-x: hidden;
  overflow-y: hidden;

  @media(max-width: 400px){
    font-size: 80%;
  }
`;

const ContentWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  flex: 1;
`;

export default ({ className = '', children }) => (
    <MainBox className={className}>
      <ImageWrapper align="left" padding="15px 12px">
        <TinyImage alt="logo" src={logo} />
      </ImageWrapper>

      <ContentWrapper>
        {children}
      </ContentWrapper>
    </MainBox>
);
