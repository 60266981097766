import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import text from 'app/shared/views/text';
import RadioGroup from 'app/shared/views/radioGroup';
import RadioElement from 'app/shared/views/radioElement';
import colors from 'app/shared/helpers/colors';
import transition from 'app/shared/helpers/transition';

import {
  UpgradeButton,
  Title,
  Subtitle,
  FeatureList,
} from './elements';

import {
  getAllPlans
} from 'actions/subscriptionActions';

const {
  H5,
} = text;

const ChoosePlan = ({ nextContent, reference, getAllPlans }) => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');

  useEffect(() => {
    getAllPlans((err, plans) => {
      if (err) {
        console.log(err);
        return;
      }
      plans.sort((p1, p2) => p1.amount - p2.amount);
      setPlans(plans);
      setSelectedPlan(plans[0].planId);
    });
  }, [getAllPlans]);

  const onUpgradeClick = () => {
    const chosenPlan = plans.find(p => p.planId === selectedPlan);
    if (chosenPlan) {
      nextContent(chosenPlan);
    }
  };

  return (
    <ContentWrapper ref={reference}>
      <ContentTitle>Upgrade to Orderoo Pro</ContentTitle>
      <SubtitleWrapper>
        <Subtitle>
          Orderoo PRO gives you all the tools you need to take care of your customers in Messenger.
        </Subtitle>
      </SubtitleWrapper>
      <FeatureListWrapper>
        <FeatureList>
          <li>Unlimited Storage</li>
          <li>Unlimited Orders</li>
          <li>Unlimited Clients</li>
          <li>Unlimited Products and Categories</li>
          <li>Unlimited Automated Sequences</li>
          <li>Unlimited Facebook Messenger Follow Up Messages</li>
        </FeatureList>
      </FeatureListWrapper>
      <SectionTitle style={{ fontSize: '22px' }}>Choose Plan</SectionTitle>
      <PlanSelectedWrapper>
        <RadioGroup
          selectedElement={selectedPlan}
          updateSelectedElement={plan => setSelectedPlan(plan)}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', minHeight: '46px' }}>
            {plans.map(plan =>
              <RadioGroup.Element key={plan.planId} Element={PlanButton} text={plan.planName} value={plan.planId} />
            )}
          </div>
        </RadioGroup>
      </PlanSelectedWrapper>
      <UpgradeButton
        onClick={onUpgradeClick}
        text="Upgrade"
        width="100%"
      />
    </ContentWrapper>
  )
};

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;
`;

const ContentTitle = styled(Title)`
  font-size: 24px;
  line-height: 22px;
  padding-bottom: 25px;
  justify-content: center
`;

const SectionTitle = styled(Title)`
  font-size: 22px;
  line-height: 22px;
  justify-content: center
`;

const SubtitleWrapper = styled.div`
  text-align: center;
  padding-bottom: 10px;
`;

const FeatureListWrapper = styled.div`
  margin-bottom: 25px;

  & > ul {
    text-align: center;
    list-style: none;
    font-weight: normal;
    color: #748AA1;
  }
`;

const PlanSelectedWrapper = styled.div`
  margin: 30px 0 50px;
`;

const PlanButtonWrapper = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  box-sizing: border-box;
  border-radius: 3px;
  width: 148px;
  height: 46px;
  border-radius: 3px;
  display: inline-flex;
  transition: ${transition};
  align-items: center;

  :hover {
    cursor: pointer;

    .radio-element {
      // border: 1px solid ${colors.blue};
      // cursor: pointer;
    }
  }
`;

const RadioElementWrapper = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
`;

const Price = styled(H5)`
  color: ${colors.blue};
`;

const PlanButton = ({
  updateSelectedElement = () => {},
  isSelected = false,
  text = '',
  ...rest
}) => (
  <PlanButtonWrapper onClick={updateSelectedElement} isSelected={isSelected} {...rest}>
    <RadioElementWrapper>
      <RadioElement isSelected={isSelected} />
    </RadioElementWrapper>
    <Price>{text}</Price>
  </PlanButtonWrapper>
);

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { getAllPlans }
)(ChoosePlan);
