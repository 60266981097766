import React from 'react';
import styled from 'styled-components';
import Text from './text';
import colors from '../helpers/colors';

const Header = styled.div`
  display: flex;
  padding: 13px 35px;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.fog};
`;

const Footer = styled.div`
  padding: 30px 35px;
  border-top: 1px solid ${colors.fog};
  display: flex;
  flex-direction: row-reverse;

  div {
    margin-left: 20px;
  }
`;

const Content = styled.div`
  padding: 30px 35px 70px;
`;

const Title = styled(Text.H4)`
  color: ${colors.blue};
`;

const AddItemPopup = ({ title, illustration, children, actions, ...rest }) => (
  <div {...rest}>
    <Header>
      <Title>{title}</Title>
      <img src={illustration} alt={"Illustration"} />
    </Header>
    <Content>{children}</Content>
    <Footer>
      {actions.map((Item, i) => (
        <Item key={i} />
      ))}
    </Footer>
  </div>
);

AddItemPopup.InputRow = styled.div`
  margin-bottom: 15px;
`;

export default AddItemPopup;
