import axios from 'axios';
import Url from '../config/api';
import { getActivePageSubscription } from './subscriptionActions';

const {
  baseUrl
} = Url;

const baseURI = `${baseUrl}/Pages`;

export const getFacebookPages = (setStatus=null) => {
  return (dispatch) => {
      axios.post(`${baseURI}`)
          .then(res => {
            if(res.status >= 200 && res.status <= 300){
              setStatus && setStatus('OK');
              localStorage.setItem('Pages', JSON.stringify(res.data));
              const pages =  res.data.map(props => ({
                page: props,
              }));
              dispatch({
                type: `GET_FB_PAGES_OK`,
                data: pages
            })}
          })
          .catch(() => {
            setStatus && setStatus('ERROR');
            dispatch({type: 'GET_FB_PAGES_ERROR'})
    })
  }
}

export const setActivePage = (id, setStatus=null) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_ACTIVE_PAGE_WAIT'
    });
    const guestCheckoutId = getState().subscriptions.guestCheckoutId;
    const uri = `${baseURI}/${id}` + (guestCheckoutId ? `?guest_checkout_id=${guestCheckoutId}` : '');
    axios.get(uri).then(res => {
        setStatus && setStatus('OK')
        localStorage.setItem('Page', JSON.stringify(res.data.id));

        dispatch({
          type: 'SET_ACTIVE_PAGE_OK',
          data: res.data
        });

        getActivePageSubscription(id)(dispatch);
      })
      .catch(()=>{
        setStatus && setStatus('ERROR');
        dispatch({type: 'SET_ACTIVE_PAGE_ERROR'})
      });
  }
};

export const setLocalActivePage = () => {
  const pageId = localStorage.getItem('Page')
  const pages = JSON.parse(localStorage.getItem('Pages'))
  let page = null;

  if(pages !== null){
    page = pages.find(p => p.id.toString() === pageId) || null;
  }

  return (dispatch) => {
    dispatch({
      type: 'SET_LOCAL_ACTIVE_PAGE_OK',
      page,
      pages,
    });

    if (page) {
      getActivePageSubscription(pageId)(dispatch);
    }
  }
};
