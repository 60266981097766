import React from 'react';
import { Redirect } from 'react-router-dom';
import { FacebookProvider, Login } from 'react-facebook';
import shared from 'app/shared';
import { connect } from 'react-redux';
import texts from '../statics/texts';
import { loginUser} from '../../../actions/userActions';
import Config from '../../../config/api';

const {
  FacebookAppId
} = Config;

const {
  views: {
    MainView,
    Text,
    CheckBox,
    Button: { MainButton },
  },
} = shared;

const {
  Paragraph: { MainParagraph },
  Label: { MainLabel },
  Link: { ExternalLink },
} = Text;

const { title, subtitle, btnText, paragraph } = texts;
const { useState } = React;

const LoginPage = props => {

  const [checked, setChecked] = useState(false);
 // const [loginUserState, setLoginUserState] = useState(''); 

  const responseFacebook = response => {
   // props.loginUser(response.accessToken, setLoginUserState);
    props.loginUser(response.tokenDetail.accessToken);

  };

  const handleCheckboxClick = () => {
    setChecked(prev => !prev);
  };

  return (
    <div>
      {props.logIn.token ? <Redirect to={'/facebook'} /> : null}
      <MainView title={title} subtitle={subtitle}>
         <FacebookProvider appId={FacebookAppId}>
            <Login
              scope="public_profile,user_friends, pages_show_list, manage_pages, pages_messaging"
              onCompleted={responseFacebook}
            >
              {({ handleClick }) => (
                 checked ? (
                  <MainButton
                    onClick={handleClick}
                    text={btnText}
                    style={{ marginTop: '5%', cursor: 'pointer' }}
                  />
                ) : (
                  <MainButton
                    text={btnText}
                    style={{ marginTop: '5%', backgroundColor: 'lightgray', color: 'gray' }}
                  />
                )
              )}
            </Login>
        </FacebookProvider>
        <MainParagraph>{paragraph}</MainParagraph>
        <div style={{ width: '100%', alignItems: 'center' }}>
          <div style={{ display: 'table', margin: '5% auto', width: '80%', textAlign: 'center' }}>
            <span>
              <CheckBox checked={checked} click={handleCheckboxClick} />
              <span style={{ marginLeft: '5%', wordSpacing: '4%' }}>
                <MainLabel>
                  I agree to Orderoo
                  <ExternalLink> Terms of Services </ExternalLink>
                  and
                  <ExternalLink> Privacy Policy. </ExternalLink>
                </MainLabel>
              </span>
            </span>
          </div>
        </div>
      </MainView>
    </div>
  );
};

LoginPage.path = '/notAuthenticated';

const mapStateToProps = state => ({
  logIn: state.logIn,
  userDetails: state.userDetails,
});

export default connect(
  mapStateToProps,
  {
    loginUser: loginUser,
  },
)(LoginPage);

export { LoginPage };
