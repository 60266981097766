import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const {
  views: {
    Input,
    Button: {
      BaseButton
    },
    Icons: {
      Pick,
    },
    LocationPicker,
    InputArea,
  },
  helpers: {
    colors,
  }
} = shared;

const AddresDetailsContainer = styled.div`
  margin-top: 12px;
  margin: 0 15px;
`;

const AddressInputWrapper = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    flex: 1;
  }
`;

const AddressInput = styled(Input)`
  background-color: ${colors.lightGrey};
  opacity: 0.5;
  border-radius: 3px;

  & > input {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;

    &:focus {
      border: 1px solid ${colors.blue};
    }

    &::placeholder {
      color: ${colors.grey};
    }
  }
`;

const AddressInputBtn = styled(BaseButton)`
  height: 34px;
  width: 36px;
  padding: 7px;
  background-color: #F1BF3C;
  border-radius: 3px;
  margin-left: 15px;
  justify-content: center;
`;

const LocationPickMapWrapper = styled.div`
  margin: 10px 0;
  height: 210px;

  & > div {
    height: 100%;
  }
`;

const AddressDetailsInputAreaWrapper = styled.div`
  margin-top: 10px;
`;

const AddressDetailsArea = styled(InputArea)`
  & textarea:focus {
    border: 1px solid ${colors.blue};
  }
`;

const AddressDetailsStep = ({ isActive, orderLocation, setOrderLocation, addressInfo, setAddressInfo }) => {
  const [address, setAddress] = useState('');
  const [resetLocation, setResetLocation] = useState(false);

  const addressInputRef = useRef(null);
  useEffect(() => {
    if (orderLocation && orderLocation.address) {
      setAddress(orderLocation.address);
    }
  }, [orderLocation]);

  return (
    <AddresDetailsContainer>
      <AddressInputWrapper>
        <AddressInput
          type="text"
          value={address}
          placeholder="Input address"
          height="35"
          disabled={!isActive}
          inputRef={addressInputRef}
          onChange={e => {
            setAddress(e.target.value);
          }}
        />
        <AddressInputBtn onClick={() => {
          if (!isActive) return;
          setResetLocation(true);
        }}>
          <Pick fill={colors.white} />
        </AddressInputBtn>
      </AddressInputWrapper>
      {isActive ? (
        <LocationPickMapWrapper>
          <LocationPicker
            theme="silver"
            location={orderLocation}
            suggestInputRef={addressInputRef}
            onLocationPicked={(location) => {
              setResetLocation(false);
              setOrderLocation(location);
            }}
            resetToCurrent={resetLocation}
          />
        </LocationPickMapWrapper>
      )
      : null}
      {isActive ? (
        <AddressDetailsInputAreaWrapper>
          <AddressDetailsArea labelText="Address Details" value={addressInfo} max={100} onChange={(e) => {
            const value = e.target.value;
            setAddressInfo(value);
          }} />
        </AddressDetailsInputAreaWrapper>
      ) : null}
    </AddresDetailsContainer>
  );
};

export default AddressDetailsStep;
