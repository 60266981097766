export const PAYMENT_OPTIONS = {
  ON_DELIVERY: {
    methodId: 0,
    methodText: "Pay on Delivery",
  },
  WITH_CARD: {
    methodId: 1,
    methodText: "Pay with Debit/Credit Card",
  },
};

export const PAYMENT_PENDING_STORAGE_KEY = 'pendingPayment';
export const AUTH_CODE_STORAGE_KEY = 'authClientCode';
export const NOT_AUTH_REDIRECT_PATH = '/notAuthenticated';
export const PAYMENT_CONFIRM_PATH = '/shoppingcart/confirm';
export const PAYMENT_CANCEL_PATH = '/shoppingcart/cancel'
