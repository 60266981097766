import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const{
  views: {
    Text:{
      Paragraph: { P },
    },
  },
  helpers: {
    colors,
  }
} = shared;

const BodyWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  height: 435px;
  overflow: auto;
  flex-direction: row;
`;

class MessageBodySection extends React.Component{

  constructor(props){
    super(props);
    this.section = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    const scrollHeight = this.section.current.scrollHeight;
    this.section.current.scrollTop = scrollHeight;
  }

  render(){
    return (
      <BodyWrapper ref={this.section}>
        {this.props.messages.map(e =>{
          return <MessageBodyButton text={e}/>
        }
        )}
      </BodyWrapper>
    );
  }
}

const DivWrapper = styled.div`
  text-align: right;
  align-items: right;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const PWrapper = styled.span`
  text-align: center;
  background-color: ${colors.blue};
  display: inline-block;
  flex-wrap: wrap;
  max-width: 50%;
  min-width: 3%;
  
  word-wrap: break-word;
  margin-left: auto;
  padding: 10px;
  border-radius: 25px;
`;

const pStyle = {
  color: 'white',
  fontWeight: '500',
  fontSize: '20px',
  wordWrap: 'break-word'
};

class MessageBodyButton extends React.Component{

  render(){
    return(
      <DivWrapper>
        <PWrapper>
          <P style={pStyle}>
            {this.props.text}
          </P>
        </PWrapper>
      </DivWrapper>
    )
  }
}

export default MessageBodySection;
