import styled from 'styled-components';
import colors from 'app/shared/helpers/colors';
import React from 'react';
import logo from 'assets/icons/logo.svg';
import image from './image';
import text from './text';

const{
  Image:{
    Images:{ TinyImage, SmallImage, },
    ImageWrapper,
  }
}= image;

const{
  H1:{ MainTitle, },
  H3:{ MainSubtitle, },
} = text;

const MainBox = styled.div`
    align-items: center;
    padding:  2%;
    border-radius: 0.3%;
    color: ${colors.textColor};
    background: ${colors.white};
    border: 1px solid #E9EAF1;
    box-sizing: border-box;

    margin-left: auto;
    margin-right: auto;

    min-width: 640px;

    @media(min-width: 960px){
        display: block;
        width: 40%;
    }

    @media(max-width: 960px){
      margin: auto 0;
      width: 100%;
      min-width: 0;
    }

    @media(max-width: 400px){
      font-size: 80%;
    }

`;

const localStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'table',
  top: '0',
  left: '0'
};

const logoSizes = {
  'tiny': TinyImage,
  'small': SmallImage,
};

 class BoxView extends React.Component{

  render(){
    const { logoPosition, logoPadding, logoSize = 'small', boxAlign = 'middle', className } = this.props;
    const LogoImg = logoSizes[logoSize];

    return (
      <div style={localStyle}>
        <div style={{display: 'table-cell', verticalAlign: boxAlign }}>
          <MainBox className={className}>
            <div style={{position: 'relative'}}>
              <ImageWrapper align={logoPosition} padding={logoPadding} >
                <LogoImg alt={'logo'} src={logo}/>
              </ImageWrapper>

              {this.props.title && <MainTitle style={{marginTop:'5%'}}>
                {this.props.title}
              </MainTitle>}

              {this.props.subtitle && <MainSubtitle style={{marginTop:'4%'}}>
                {this.props.subtitle}
              </MainSubtitle>}

              {this.props.children}
            </div>
          </MainBox>
        </div>
      </div>
    );
  }
}

export default BoxView;
