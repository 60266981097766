import React from 'react';
import colors from 'app/shared/helpers/colors';
import styled from 'styled-components';
import loadingImage from '../../../../assets/icons/loading.svg';


const RawButton = ({  text, image = null, ...rest }) => (
  <div {...rest}
  >
    { image != null?
     <img src={loadingImage} alt={text}/>
    : <span >{text}</span>
      }
  </div>
);

const ButtonWrapper = styled(RawButton)`
  
  background-color: ${colors.lightBlue};
  color: ${colors.blue};
  width: 35%;
  border-radius: 0 5% 5% 0;
  font-size: 100%
  font-weight: bold;
  text-align: center;
  padding: 5%;
  display: flex;
  min-width: 100px;
  cursor: pointer;
  
  span{
    margin-left: auto;
    margin-right: auto;
  }
  
  img{
    margin-left: auto;
    margin-right: auto;
  }
`;

const StatefulButton = (props) => {

  const style={
    color: props.status === 'Connect' ? colors.blue : colors.green,
    backgroundColor: props.status === 'Connect' ? colors.lightBlue : colors.lightGreen,
  };
  const text = props.status;
  const image = props.status === 'Connecting' ? loadingImage : null;
  return (
    <ButtonWrapper style={style} text={text} image={image} onClick={props.click}/>
  )
}
  

export default StatefulButton;
