import Icons from '../views/icons';

export default {
  navBar: [
    {
      Icon: Icons.Dashboard,
      to: '/dashboard',
      text: 'Dashboard',
    },
    {
      Icon: Icons.Orders,
      to: '/orders',
      text: 'Orders',
    },
    {
      Icon: Icons.Clients,
      to: '/clients',
      text: 'Clients',
    },
    {
      Icon: Icons.Products,
      to: '/products',
      text: 'Products',
    },
    {
      Icon: Icons.Categories,
      to: '/categories',
      text: 'Categories',
    },
    {
      Icon: Icons.Conversations,
      to: '/marketing',
      text: 'Marketing',
    },
    {
      Icon: Icons.Chatbot,
      to: '/chatbot',
      text: 'Chatbot',
    },
  ],
};
