import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const {
  views: { Card, Icons },
  helpers: { colors },
} = shared;

const AddProductCardWrapper = styled(Card)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.span`
  color: ${colors.lightGrey};
  font-size: 12px;
  line-height: 22px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-bottom: 5px;
`;

const AddProductCard = props => (
  <AddProductCardWrapper {...props}>
    <Container>
      <IconWrapper>
        <Icons.Plus fill={colors.lightGrey} />
      </IconWrapper>
      <Text>Add New Category</Text>
    </Container>
  </AddProductCardWrapper>
);

export default AddProductCard;
