import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const {
  views: { Card, Text },
  helpers: { colors },
} = shared;

const{
  Paragraph:{ P },
  H5,
} = Text;

const CategoryCardWrapper = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 140px;
  min-width: 140px;
  height: 100px;
`;

const PhotoContainer = styled.div`
  height: 70%;
  background: url(${props => props.photo}) center center;
  background-size: cover;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

const InfoWrapper = styled.div`
  padding: 2px 5px;
  border: 1px solid ${props => props.selected ? colors.darkGrey : colors.lightGrey }
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-sizing: border-box;
  background-color: ${props => props.selected ? colors.blue : colors.lightGrey}

  & .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: ${props => props.selected ? colors.white : colors.blue}
  }

  & .label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    color: ${props => props.selected ? colors.white : colors.textColor}
  }

  & .value {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: ${props => props.selected ? colors.white : colors.blue}
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectedProductsInCategory = styled(P)`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.red};
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.white};
  border-radius: 50%;

  ${props => !props.show ? `
    display: none;
  ` : null}
`;

const CategoryCard = ({ title, totalProducts, qty, photo, selected, ...rest }) => (
  <CategoryCardWrapper {...rest}>
    <SelectedProductsInCategory show={qty !== 0}>{qty}</SelectedProductsInCategory>
    <PhotoContainer photo={photo} />
    <InfoWrapper selected={selected}>
      <Row>
        <H5 className="title">{title}</H5>
      </Row>
      <Row>
        <P className="label">Total Products</P>
        <P className="value">{totalProducts}</P>
      </Row>
    </InfoWrapper>
  </CategoryCardWrapper>
);

export default CategoryCard;
