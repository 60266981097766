import React from 'react';
import styled from 'styled-components';

import noRecordsImage from 'assets/images/no_records.svg';

import colors from '../helpers/colors';
import TableValidator from '../validators/table.validator';

import SortIcon from 'assets/icons/sort_arrows.svg';

const Table = styled.table`
  width: 100%;
`;

const Td = styled.td`
  line-height: 58px;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 12px;
  color: ${colors.black};
  @media all and (max-width: 768px) {
    line-height: 50px;
    padding-left: 25px;
    padding-right: 25px;
   }
`;

const Th = styled.th`
  line-height: 67px;
  text-align: left;
  padding-left: 35px;
  padding-right: 35px;
  color: ${colors.textColor};
  font-weight: 600;
  font-size: 12px;

  @media all and (max-width: 768px) {
   line-height: 50px;
   padding-left: 25px;
   padding-right: 25px;
  }
`;

const EmptyImage = styled.img`
  display: block;
  margin: 100px auto;
`;

const Tr = styled.tr`
  border-bottom: 1px solid ${colors.lightGrey};

  :last-child {
    border: none;
  }
`;

/**
 * TODO: Find a way to fix the Th on thead hack (borderBottom)
 * @param header
 * @param values
 * @param isEmpty
 * @returns {*}
 * @constructor
 */

const TableView = ({ data: { header, values = [], action=()=>{} }, isEmpty = false }) => (
  <div>
    <Table>
      <thead>
        <tr>
          {header.map(element => (
            <Th style={{ borderBottom: `1px solid ${colors.lightGrey}`, cursor: element.sortable && 'pointer' }} key={element.label} onClick={() => action(element.key)}>
              { element.label }
              { element.sortable && <img style={{marginLeft: '14px'}} src={SortIcon} alt='sort icon'/> }
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((element, elementIndex) => (
          <Tr key={`${element.id}-${elementIndex}`}>
            {header.map((elm, index) => (
              <Td key={`${elm.key}-${element.id}-${index}`} style={elm.width ? { width: elm.width } : {}}>
                {elm.renderer === undefined ? element[elm.key] : elm.renderer(element)}
              </Td>
            ))}
          </Tr>
        ))}
      </tbody>
    </Table>
    {isEmpty && <EmptyImage src={noRecordsImage} alt={"No records"} />}
  </div>
);

TableView.propTypes = TableValidator.TableSchema;

export default { TableView };
