import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import colors from '../../shared/helpers/colors';
import Icons from '../../shared/views/icons';

const NotificationListWrapper = styled.div`
  width: -webkit-fill-available;
  overflow: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  flex-wrap: wrap;
`;

const ListElementWrapper = styled.li`
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  list-style: none;
  font-weight: 500;
  padding: 8px 16px 8px 20px;
  color: ${props => props.color};
`;

const IconWrapper = styled.div`
  padding-right: 16px;
`;

const NotificationBody = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  flex: 1;
`;

const NotificationTime = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
`;

const NotificationItem = ({ icon: Icon, title, time, iconColor, textColor, onClick, to }) => (
  <NavLink to={to}>
    <ListElementWrapper color={textColor} onClick={onClick}>
      <IconWrapper>
        <Icon width={'13px'} height={'13px'} fill={iconColor} />
      </IconWrapper>
      <NotificationBody>{title}</NotificationBody>
      <NotificationTime>{time}</NotificationTime>
    </ListElementWrapper>
  </NavLink>
);

const NotificationItemTypes = {
  'CLIENT': (props) => (
    <NotificationItem
      {...props}
      icon={Icons.ClientNotificationIcon}
      title="New Client Added"
      to={`/client/editclient/${props.itemId}`}
    />
  ),
  'ORDER': (props) => (
    <NotificationItem
      {...props}
      icon={Icons.Orders}
      title="New Order Added"
      to={`/order/editorder/${props.itemId}`}
    />
  ),
};

const NotificationList = ({ data, markAsRead, ...rest }) => {
  return (
    <NotificationListWrapper {...rest}>
      {
        data && data.map(notification => {
          const NotificationItem = NotificationItemTypes[notification.notificationType];
          if (!NotificationItem) return null;

          return <NotificationItem
            key={notification.id}
            textColor={notification.status === 'UNREAD' ? colors.blue: colors.grey}
            iconColor={notification.status === 'UNREAD' ? colors.blue: colors.grey}
            time={moment(notification.creationDate).format('hh:mm A')}
            onClick={() => markAsRead(notification.id)}
            itemId={notification.itemId}
          />;
        })
      }
    </NotificationListWrapper>
  );
}

export default NotificationList;