import React from 'react';
import shared from 'app/shared';

const {
  views: {
    Input,
    Icons,
  },
  helpers: { colors },
} = shared;

const SearchInput = ({ search, onSearchChanged }) => (
  <Input
    start={<Icons.Search style={{ marginLeft: '8px' }} width={20} height={20} fill={colors.textColor} />}
    placeholder={'Search'}
    height={33}
    onChange={e => onSearchChanged(e.target.value)}
    value={search}
  />
);

export default SearchInput;