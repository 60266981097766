import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import admin from 'app/admin';
import dashboard from 'app/dashboard';
import orders from 'app/orders';
import clients from 'app/clients';
import products from 'app/products';
import categories from 'app/categories';
import shared from 'app/shared';
import chatBot from 'app/chatbot';
import identity from 'app/identity';
import facebook from 'app/facebook';
import stripe from 'app/stripe';
import marketing from 'app/marketing';
import finished from 'app/finished';
import settings from 'app/settings';
import shoppingCart from 'app/shoppingCart';
import upgrade from 'app/upgrade';
import notifications from 'app/notification';
import guestCheckout from 'app/guestCheckout';

const {
  views: {
    AdminDashboardPage,
    AdminClientsPage,
    AdminViewClientPage,
    AdminDetailsPage,
    AdminHelpCenterPage,
    AdminMessengerPage,
  },
} = admin;

const {
  views: { DashboardPage },
} = dashboard;

const {
  views: { SettingsPage },
} = settings

const {
  views: { FacebookPage },
} = facebook;

const {
  views: { OrdersPage, EditOrder },
} = orders;

const {
  views: { ClientsPage, EditClient },
} = clients;

const {
  views: { ProductsPage, EditProduct },
} = products;

const {
  views: { CategoriesPage, EditCategory },
} = categories;

const {
  views: { AdminLayout },
} = shared;

const {
  views: { CentralAdminLayout },
} = shared;

const {
  views: { ChatBotPage },
} = chatBot;

const {
  hoc: { ProtectedRoute, GuestRoute },
} = identity;

const {
  views: { LoginPage },
} = identity;

const {
  views: { StripeConfig },
} = stripe;

const {
  views: { MarketingPage },
} = marketing;

const {
  views: { ShoppingPage, SuccessPage, CancelPage },
} = shoppingCart;

const {
  views: {
    Restrict: UpgradeRestrict,
  }
} = upgrade;

const { views: Finished } = finished;

const {
  views: {
    NotificationsPage,
  }
} = notifications;

const {
  views: { GuestCheckoutPage }
} = guestCheckout;

export default () => (
  <div>
    <Route render={(info) => { console.log(info.location.pathname) }} />
    <Switch>
      <Route exact path={ShoppingPage.path} component={ShoppingPage} />
      <Route exact path={SuccessPage.path} component={SuccessPage} />
      <Route exact path={CancelPage.path} component={CancelPage} />
      <Route exact path={GuestCheckoutPage.path + "/:checkout_id"} component={GuestCheckoutPage} />
      <Route render={() => (
        <>
        <GuestRoute guestRender={() => (
          <Switch>
            <Route exact path={LoginPage.path} component={LoginPage} />
            <Redirect to={LoginPage.path} />
          </Switch>
        )} />
        <ProtectedRoute
          path={"/"}
          protectedRender={({ location }) => {
            return (
              <CSSTransition key={location.key} classNames={"fade"} timeout={300}>
                <TransitionGroup>
                  <Switch>
                    <Route path={StripeConfig.path} component={StripeConfig} />
                    <Route path={FacebookPage.path} component={FacebookPage} />
                    <Route path={Finished.path} component={Finished} />

                    <Route path="/admin/:page" render={() => (
                      <CentralAdminLayout location={location}>
                        <Switch>
                          <Route path={AdminDashboardPage.path} component={AdminDashboardPage} />
                          <Route exact path={AdminDetailsPage.path + "/:id"} component={AdminDetailsPage} />
                          <Route exact path={AdminViewClientPage.path + "/:id"} component={AdminViewClientPage} />
                          <Route path={AdminClientsPage.path} component={AdminClientsPage} />
                          <Route path={AdminHelpCenterPage.path} component={AdminHelpCenterPage} />
                          <Route path={AdminMessengerPage.path} component={AdminMessengerPage} />
                        </Switch>
                      </CentralAdminLayout>
                    )} />

                    <Route render={() => (
                      <AdminLayout location={location}>
                        <Switch>
                          <Route path={DashboardPage.path} component={DashboardPage} />
                          <Route path={MarketingPage.path} component={MarketingPage} />
                          <Route path={OrdersPage.path} component={OrdersPage} />
                          <Route path={EditOrder.path} component={EditOrder} />
                          <Route path={SettingsPage.path} component={SettingsPage} />
                          <Route path={ClientsPage.path} component={ClientsPage} />
                          <Route path={EditClient.path} component={EditClient} />
                          <Route path={ProductsPage.path} component={ProductsPage} />
                          <Route path={EditProduct.path} component={EditProduct} />
                          <Route path={CategoriesPage.path} component={CategoriesPage} />
                          <Route path={EditCategory.path} component={EditCategory} />
                          <Route path={ChatBotPage.path} component={ChatBotPage} />
                          <Route path={UpgradeRestrict.path} component={UpgradeRestrict} />
                          <Route path={NotificationsPage.path} component={NotificationsPage} />

                          <Route path="/admin" render={() => {
                            // alert('There was no match: ' + JSON.stringify(location));
                            return <Redirect to={AdminDashboardPage.path} />
                          }} />
                          <Route path="/" render={() => {
                            // alert('There was no match: ' + JSON.stringify(location));
                            return <Redirect to={DashboardPage.path} />
                          }} />

                        </Switch>
                      </AdminLayout>
                    )} />
                  </Switch>
                </TransitionGroup>
              </CSSTransition>
            );
          }} />
        </>
      )} />

    </Switch>
  </div>
);

