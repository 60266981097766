import React, {useState} from 'react';
import styled from 'styled-components'
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import colors from '../helpers/colors'

const CalendarWrapper = styled.div`
  .DateRangePickerInput {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
  }

  .DateRangePickerInput .DateInput_1 {
    display: flex;
    align-items: center;
    background-color: transparent;
  }

  .DateInput__small {
    width: initial;
  }

  .DateRangePickerInput_arrow {
    padding: 0 10px;
  }
  .DateRangePickerInput .DateInput_1::before {
    color: ${colors.blue};
    font-size: 13px;
    line-height: 16px;
  }

  .DateRangePickerInput .DateInput_1:first-child::before {
    content: 'from:';
  }

  .DateRangePickerInput .DateInput_1::after {
    content: "\\25BE";
    color: ${colors.lightGrey};
    transform: scaleY(0.7);
  }
  
  .DateRangePickerInput .DateInput_1 input {
    border-bottom: none;
    background-color: transparent;
    font-size: 13px;
    line-height: 16px;
    width: 90px;
  }

  .DateRangePickerInput .DateInput_1:last-child::before {
    content: 'to:';
  }

  .CalendarDay__default {
    border: none;
    line-height: 38px;
  } 

  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover, 
  .CalendarDay__default:hover, 
  .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
    background: ${colors.blue};
    border: none;
  }

  .CalendarDay__selected_end,
  .CalendarDay__selected_start {
    transition: .15s ease-in-out;
  }

  .CalendarDay__selected_end {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .CalendarDay__selected_start {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .CalendarDay__selected_span, 
  .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
    background: rgba(44,152,240, .1);
    color: ${colors.darkestGrey};
  }

  .DayPickerNavigation_button__default {
    border: 1px solid transparent;
  }

  .CalendarMonth_caption {
    color: ${colors.blue}
    font-size: 14px;
    line-height: 22px;
  }

  .DayPicker_weekHeader {
    color: #BFCADB;
  }

  .DateInput_fang {
    display: none;
  }
  table td {
    font-size: 13px;
  }
`

const DatePicker = (props) => {
    const { customDate = {} } = props;
    const { startDate, endDate } = customDate;
    const [focusedInput, setFocusedInput] = useState(null);

    const handleDatesChange = ({ startDate, endDate }) => {
      props.updateCustomDate({
         startDate: startDate && startDate,
         endDate: endDate && endDate
      })
      if(startDate && endDate) {
        if (props.updateSelectedElement) {
          props.updateSelectedElement('custom');
        }
      }
    };

    return (
      <CalendarWrapper className={props.className}>
        <DateRangePicker
          startDate={startDate}
          startDateId="tata-start-date"
          endDate={endDate}
          endDateId="tata-end-date"
          onDatesChange={handleDatesChange}
          startDatePlaceholderText=""
          endDatePlaceholderText=""
          focusedInput={focusedInput}
          onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          small
          isOutsideRange={() => false}
          numberOfMonths={2}
          calendarInfoPosition="top"
          hideKeyboardShortcutsPanel={true}
        />
      </CalendarWrapper>
    );
  }
  
  export default DatePicker;
