import { subWeeks, subMonths, subDays, startOfDay, endOfDay } from 'date-fns';

const getEdgeDates = (data, dateField) => {
  const today = new Date();
  if(data.length === 0){
    return {
      startDate: today,
      endDate: today
    }
  }

  var dates = data.map(function(x) { return new Date(x[dateField]); })
  var startDate = new Date(Math.min.apply(null,dates));
  var endDate = new Date(Math.max.apply(null,dates));

  return {
    startDate,
    endDate
  };
}

export const datesFromFilter = (filter, data, customDates, dateField) => {
  const today = new Date();
  let startDate = today;
  let endDate = today;

  if(filter === 'yesterday'){
    startDate = subDays(today, 1);
    endDate = subDays(today, 1);
  }
  else if(filter === 'last-week'){
    startDate = subWeeks(today, 1);
    endDate = subDays(today, 1);
  }
  else if(filter === 'last-month'){
    startDate = subMonths(today, 1);
    endDate = subDays(today, 1);
  }
  else if(filter === 'custom'){
    startDate = customDates.startDate;
    endDate = customDates.endDate;  
  }
  else if(filter === 'total'){
    var dates = getEdgeDates(data, dateField);
    startDate = dates.startDate;
    endDate = dates.endDate;
  }

  return {
    startDate: startOfDay(startDate),
    endDate: endOfDay(endDate)
  }
}

export const genericFilter = (filter, data, customDates, dateField) => {
  var dates = datesFromFilter(filter, data, customDates, dateField);
  var ed = new Date(dates.endDate).getTime();
  var sd = new Date(dates.startDate).getTime();
  return data.filter(d => {var time = new Date(d[dateField]).getTime();
                         return (sd <= time && time <= ed);
                        });
};