import axios from 'axios';
import decodeJwt from 'jwt-decode'
import Url from '../config/api';

const {
  baseUrl
} = Url;

const api = axios.create({
  baseURL: baseUrl,
});

const setTokenToApi = (token) => {
  api.interceptors.request.use((config) => {
    if (!token.startsWith('Bearer')) {
      token = `Bearer ${token}`;
    }
    config.headers.Authorization = token;
    return config;
  });
};

export const initShopping = (authCode, done) => {
  return (dispatch) => {
    Promise.resolve()
      .then(async () => {
        const res = await api.get('/Auth/get-auth-token', {
          params: {
            authCode: authCode || 'no code',
          }
        });
        const jwtToken = res.data.auth_token;
        setTokenToApi(jwtToken);
        return { jwtToken };
      })
      .then(async ({ jwtToken }) => {
        const jwtInfo = decodeJwt(jwtToken);
        const pageId = jwtInfo.PageId;
        let pageInfo = { pageId };

        try {
          const res = await api.get(`/Pages/${jwtInfo.PageId}`);
          pageInfo = res.data;
        } catch (err) {
          console.error('Failed to get page info: ', err);
        }

        return { jwtToken, pageInfo };
      })
      .then(async ({ jwtToken, pageInfo }) => {
        const res = await api.get('/Categories');
        return { jwtToken, pageInfo, categories: res.data };
      })
      .then(data => {
        done && done(null, data);
      })
      .catch(err => {
        console.log('got here too')
        done && done(err);
      });
  };
};

export const startNewOrder = (done) => {
  return (dispatch) => {
    dispatch({ type: 'START_NEW_ORDER' });
    done && done(null);
  };
};

export const getPageInfo = (pageId, done) => {
  return (dispatch) => {
    api.get(`/Pages/GetByPageId/${pageId}`)
      .then(res => {
        done && done(null, res.data);
      })
      .catch(err => {
        done && done(err);
      });
  };
};

export const getAuthToken = (authCode, done) => {
  return (dispatch) => {
    api.get('/Auth/get-auth-token', {
      params: {
        authCode: authCode || 'no code',
      }
    })
    .then(res => {
      setTokenToApi(res.data.auth_token);
      dispatch({ type: 'SHOPPING_AUTH_OK', data: res.data });
      done && done(null, res.data.auth_token);
    })
    .catch(err => {
      dispatch({ type: 'SHOPPING_AUTH_ERROR' });
      done && done(err);
    });
  };
};

export const getClientProducts = (done) => {
  return (dispatch) => {
    api.get('/Categories')
      .then(res => {
        dispatch({ type: 'GET_ALL_CATEGORIES_OK', data: res.data });
        done && done(null, res.data);
      })
      .catch(err => {
        dispatch({ type: 'GET_ALL_CATEGORIES_ERROR' });
        done && done(err);
      });
  };
};

export const saveOrder = (order, paymentMethod, client, done) => {
  // Note: a better aproach will be to use map state to entity
  // then passing the data as function args
  return (dispatch) => {
    const orderEntity = mapOrderToEntity({ order, paymentMethod, client });

    (!orderEntity.id
      ? api.post('/Orders', orderEntity)
      : api.put(`/Orders/${orderEntity.id}`, orderEntity)
    ).then(res => {
      dispatch({ type: 'ORDER_SAVED', data: { id: res.data.id } });
      done && done(null, {
        ...order,
        id: res.data.id,
      });
    })
    .catch(err => {
      dispatch({ type: 'ORDER_ERROR' });
      done && done(err);
    });
  }
};

export const getOrderBySessionId = (sessionId, done) => {
  return (dispatch) => {
    api.get(`/Orders/get-order-by-sessionid/${sessionId}`)
      .then(res => {
        dispatch({ type: 'GET_ORDER_OK', data: mapOrderFromEntity(res.data) });
        done && done(null, res.data);
      })
      .catch(err => {
        dispatch({ type: 'GET_ORDER_ERROR' });
        done && done(err);
      });
  };
}

export const updateOrderPaymentMethod = (sessionId, paymentMethod, done) => {
  return (dispatch) => {
    api.get(`/Orders/get-order-by-sessionid/${sessionId}`)
      .then(async res => {
        const order = res.data;
        order.paymentMethod = paymentMethod.methodId;
        await api.put(`/Orders/${order.id}`, order);
        dispatch({ type: 'UPDATE_ORDER_PAYMENT_METHOD_OK' });
        done && done(null, order);
      })
      .catch(err => {
        dispatch({ type: 'UPDATE_ORDER_PAYMENT_METHOD_ERROR' });
        done && done(err);
      });
  }
};

export const startPayment = (orderId, done) => {
  return (dispatch) => {
    api.get(`/Payment/start-payment/${orderId}`)
      .then(res => {
        dispatch({ type: 'PAYMENT_STARTED',
          data: {
            paymentDetails: res.data.paymentDetails,
            paymentMethod: res.data.paymentMethod,
          }
        });
        done && done(null, res.data);
      })
      .catch(err => {
        dispatch({ type: 'PAYMENT_START_ERROR' });
        done && done(err);
      });
  };
};

export const completePayment = (sessionId, done) => {
  return (dispatch) => {
    api.get(`/Payment/complete-payment/${sessionId}`)
      .then(res => {
        dispatch({ type: 'PAYMENT_COMPLETED', data: res.data });
        done && done(null, res.data);
      })
      .catch(err => {
        dispatch({ type: 'PAYMENT_COMPLETE_ERROR' });
        done && done(err);
      });
  };
};

export const addProductToOrder = (product) => {
  return (dispatch) => {
    dispatch({
      type: 'ORDER_ADD_PRODUCT',
      data: product,
    });
  };
};

export const removeProductFromOrder = (product, one = true) => {
  return (dispatch) => {
    dispatch({
      type: 'ORDER_REMOVE_PRODUCT',
      data: product,
      one: one,
    });
  };
};

export const setOrderLocation = (orderLocation) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ORDER_LOCATION',
      data: orderLocation,
    });
  };
}

export const setPaymentMethod = (paymentMethod) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_PAYMENT_METHOD',
      data: paymentMethod,
    });
  };
}

export const setClientInfo = (clientInfo = {}) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CLIENT_INFO',
      data: clientInfo,
    });
  };
}

export const setOrderDescription = (orderDescription) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ORDER_DESCRIPTION',
      data: orderDescription,
    });
  };
};

export const setAddressInfo = (addressInfo) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ADDRESS_INFO',
      data: addressInfo,
    });
  };
}

const mapOrderToEntity = ({ order, paymentMethod, client }) => {
  return {
    id: order.id,
    orderLocation: order.orderLocation.address,
    orderLocation2: order.addressInfo,
    customerDescription: order.description,
    clientName: client.firstName,
    clientLastName: client.lastName,
    clientPhone: client.phone,
    totalValue: order.total,
    paymentMethod: paymentMethod.methodId,
    orderProducts: order.orderProducts.map(product => ({
      productId: product.id,
      qty: product.qty,
      price: product.price,
      name: product.name,
    }))
  };
};

const mapOrderFromEntity = (orderEntity) => {
  return {
    order: {
      id: orderEntity.id,
      total: orderEntity.totalValue,
      taxFee: 0,
      shippingFee: 0,
      orderLocation: {
        address: orderEntity.orderLocation,
      },
      description: orderEntity.customerDescription,
      addressInfo: orderEntity.orderLocation2,
      orderProducts: orderEntity.orderProducts.map(product => ({
        id: product.productId,
        qty: product.qty,
        price: product.price,
        name: product.name,
      })),
    },
    client: {
      firstName: orderEntity.clientName,
      lastName: orderEntity.clientLastName,
      phone: orderEntity.clientPhone,
    },
    paymentMethod: {
      methodId: orderEntity.paymentMethod,
    }
  }
};
