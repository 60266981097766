import React from 'react';
import { Route } from 'react-router-dom';
import auth from '../../../auth';
const { isAuthenticated } = auth;

const ProtectedRoute = ({ path, protectedRender }) => {
  return <Route path={path} render={(props) => {
    if (isAuthenticated()) {
      return protectedRender(props);
    }
    return null;
  }} />
};

export default ProtectedRoute;
