import axios from 'axios';
import Url from '../config/api';

const {
  baseUrl
} = Url;

const baseURI = `${baseUrl}/Auth`;
const Axios = axios.create();

export const loginUser = (data, setStatus=null) => {
  return (dispatch) => {
    Axios.post(`${baseURI}`,{
      "accessToken":data
    })
    .then(res =>
      {
          if(res.status >= 200 && res.status <= 300){
            setStatus && setStatus('OK');
            localStorage.setItem("authToken", res.data.auth_token);
            dispatch({
              type: 'LOGIN_USER_OK',
              token: res.data.auth_token
            })
          }else{
            setStatus && setStatus('ERROR')
          }
    })
    .catch(()=>{
      setStatus && setStatus('ERROR')
      dispatch({type: 'LOGIN_USER_ERROR'})
  })
  }
}

export const getUserDetails = (setStatus=null) => {
  return (dispatch) => {
    axios.get(`${baseUrl}/UserDetails/user`)
      .then(res => {
        if(res.status >= 200 && res.status <= 300){
          setStatus && setStatus('OK');
          localStorage.setItem('User', JSON.stringify(res.data));
          dispatch({
            type:'GET_USER_DETAILS_OK',
            user: res.data
          })
        }
        else{
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dispatch({type: 'GET_USER_DETAILS_ERROR'})
      })
  }
}

export const setLocalUser = () => {
  return (dispatch) => {
    const user = localStorage.getItem('User')
    dispatch({
      type: 'SET_LOCAL_USER',
      user: JSON.parse(user)
    })
  }
}
