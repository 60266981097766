import React, {useState, useEffect, createRef, useCallback} from 'react';
import styled from 'styled-components';
import colors from '../helpers/colors';
import ifDifferentFromNullRenderThis from '../helpers/ifDifferentFromNullRenderThis';
import UploadInputImage from './uploadInputImage';
import axios from 'axios';
import apiUrl from '../../../config/api';
import FormData from 'form-data';
import Icons from './icons';

const baseUri = `${apiUrl.baseUrl}/Image`;

const Label = styled.label`
  color: ${colors.textColor};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
`;

const LabelInfo = styled.label`
  color: ${colors.lightGrey};
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  margin-left: 10px;
`;

const LabelWrapper = styled.div`
  padding-left: 10px;
`;

const InputField = styled.input`
  color: ${colors.darkestGrey};
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  font-size: 16px;
  line-height: 17px;
  width: 100%;
  height: 100%;
  user-select: auto;
  input::-webkit-outer-spin-button;
  input::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

const InputAreaField = styled.textarea`
  align-self: center;
  color: ${colors.darkestGrey};
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  font-size: 16px;
  line-height: 17px;
  width: 100%;
  height: 100%;
  user-select: auto;
  textarea::-webkit-outer-spin-button;
  textarea::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`;

const InputWrapper = styled.div`
  border: 1px solid #e1e6ec;
  border-radius: 3px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.height ? `${props.height}px` : null}
  background: ${colors.white};
  & > input {
    padding: 0 25px 0 10px;
  }

  & > textarea {
    padding: 11px 10px;
    resize: none;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    & > input {
      padding: 0 0 0 5px;
    }
  }
`;

const StartWrapper = styled.div``;
const EndWrapper = styled.div``;

const Input = ({ start = null, end = null, labelText = null, height = 43, className = '', inputRef, ...rest }) => (
  <div>
    {ifDifferentFromNullRenderThis(
      labelText,
      <LabelWrapper>
        <Label>{labelText}</Label>
      </LabelWrapper>,
    )}
    <InputWrapper height={height} className={className}>
      {ifDifferentFromNullRenderThis(start, <StartWrapper>{start}</StartWrapper>)}
      <InputField ref={inputRef} {...rest} />
      {ifDifferentFromNullRenderThis(end, <EndWrapper>{end}</EndWrapper>)}
    </InputWrapper>
  </div>
);

const getImageUri = (uuid) => {
  return `https://magus-photo-container.s3.amazonaws.com/${uuid}_SIZE_2.jpeg`;
}

const UploadInput = ({setUri = null, labelText = null, setUriForUser = null, height = 125, imagePath = null , uriGenerator, ...rest }) => {

  const [imageUri, setImageUri] = useState(imagePath);

  const uploadImage = (event) => {
    //console.log(event.target.files[0])
     let data = new FormData();
    data.append('Image', event.target.files[0], event.target.files[0].name);

    axios.post(baseUri, data,  {
      headers: {
      'accept': 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,

      },
      onUploadProgress: ev => {
        console.log('Still loading!!');

        setImageUri("loading")
      }


   }).then(res => {

      const completeUri = getImageUri(res.data.uuid);
      uriGenerator(completeUri)
      setImageUri(completeUri);

      if(setUriForUser != null)
        setUriForUser(completeUri);

    }).catch(err => setImageUri(imagePath)
    );
  }

  let imageDisplay;

  if(imageUri === null){
    imageDisplay = (<UploadInputImage  for= "file"  />);
  }
  else if(imageUri === "loading"){
    imageDisplay = (<Icons.Loading width="138" height="96" fill={colors.blue} />);
  }
  else{
    imageDisplay = (<img  width="138" height="96" src={imageUri} alt={"Uploaded Here"}/>);
  }

  return (
    <div>
      {ifDifferentFromNullRenderThis(
        labelText,
        <LabelWrapper>
          <Label>{labelText}</Label>
        </LabelWrapper>,
      )}
      <InputWrapper height={height} style={{ padding: 0, justifyContent: 'center' }}>
      <label htmlFor="file">
        {imageDisplay}
      </label>

        <input id="file" type={"file"} style={{display: "none"}} onChange={uploadImage} />
      </InputWrapper>
    </div>
  );
}

const InputArea = ({ labelText = null, className = '', inputRef, onChange, max, rows = 1, ...rest }) => {
  inputRef = inputRef || createRef();

  useEffect(() => {
    resizeArea(rows, inputRef.current);
  }, [inputRef, rows]);

  const handleInput = useCallback((e) => {
    if (onChange) {
      onChange(e);
    }

    resizeArea(rows, inputRef.current);
  }, [onChange, inputRef, rows]);

  return (
    <div>
      {ifDifferentFromNullRenderThis(
        labelText,
        <LabelWrapper>
          <Label>{labelText}</Label>
          {max ? <LabelInfo>Max Characters. {max}</LabelInfo> : null}
        </LabelWrapper>,
      )}
      <InputWrapper className={className}>
        <InputAreaField ref={inputRef} maxLength={max} rows={rows} onChange={handleInput} {...rest} />
      </InputWrapper>
    </div>
  );
};

const resizeArea = (rows, el) => {
  if (el) {
    el.style.height = '0';
    el.style.overflowY = 'hidden';
    rows = typeof rows !== 'number' ? parseInt(rows, 10) : rows;

    if (isNaN(rows))
      return;

    const {
      borderBottomWidth,
      borderTopWidth,
      fontSize,
      lineHeight,
      paddingBottom,
      paddingTop,
    } = window.getComputedStyle(el);

    const lh = lineHeight === 'normal' ? parseFloat(fontSize) * 1.2 : parseFloat(lineHeight);
    const rowHeight = rows === 0 ? 0 : lh * rows +
      parseFloat(borderBottomWidth) +
      parseFloat(borderTopWidth) +
      parseFloat(paddingBottom) +
      parseFloat(paddingTop);

    const scrollHeight = el.scrollHeight + parseFloat(borderBottomWidth) + parseFloat(borderTopWidth);
    const height = Math.max(rowHeight, scrollHeight);
    el.style.height = `${height}px`
  }
};

export default {
  Input,
  InputArea,
  UploadInput,
};
