export const ORDER_STATUS = {
  0: 'Pending',
  1: 'Payed',
  4: 'Pending',
  5: 'Cancelled'
};

export const ORDER_PAYMENT_METHODS = {
  0: 'Cash',
  1: 'Stripe'
};
