import React from 'react';
import { connect } from 'react-redux';
import { setGuestCheckoutId } from '../../../actions/subscriptionActions';
import identity from '../../identity';

const {
  views: { LoginPage },
} = identity;

const { useEffect } = React;

const GuestCheckoutPage = props => {

  const {match, setGuestCheckoutId} = props;

  useEffect(() => {
    match.params.checkout_id && setGuestCheckoutId(match.params.checkout_id);
  }, [match.params.checkout_id, setGuestCheckoutId]);

  return <LoginPage></LoginPage>;
};

GuestCheckoutPage.path = '/guest_checkout';

const mapStateToProps = state => ({
    guestCheckoutId: state.guestCheckoutId,
});

export default connect(
  mapStateToProps,
  {
    setGuestCheckoutId
  },
)(GuestCheckoutPage);

export { GuestCheckoutPage };
