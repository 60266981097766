import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ButtonWithIcon } from './buttons';
import Icons from './icons';
import colors from '../helpers/colors';
import TopBarValidator from '../validators/topBar.validator';
import { getAll } from '../../../actions/crudActions';
import { setActivePage } from '../../../actions/facebookAction';
import { getNotifications } from '../../../actions/notificationAction';

const CentralAdminTopBarWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const ImageAndSelectWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 30px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    > * {
      margin-left: 10px;
    }
  }
`;

const SelectWraper = styled.div`
  width: 220px;

`;
const SelectWrapperTablet = styled.div`
@media all and (min-width: 768px) and (max-width: 1024px) {
  width: 80%;
}
`

const CentralAdminTopBar = props => {
  return (
    <CentralAdminTopBarWrapper>
      <ImageAndSelectWrapper>
        <SelectWraper>
          <SelectWrapperTablet>
          </SelectWrapperTablet>
        </SelectWraper>
      </ImageAndSelectWrapper>
      <ButtonsWrapper>
        <NavLink to={'/settings'} style={{ textDecoration: 'none' }}>
          <ButtonWithIcon
            Icon={Icons.Settings}
            color={colors.blue}
            text={props.userDetails.user ? props.userDetails.user.name : null}
          />
        </NavLink>
      </ButtonsWrapper>
    </CentralAdminTopBarWrapper>
  );
};

CentralAdminTopBar.propTypes = TopBarValidator.TopBarSchema;

const mapStateToProps = state => ({
  facebookPages: state.facebookPages,
  userDetails: state.userDetails,
  categories: state.categories,
  products: state.products,
  notficiations: state.notifications,
  orders: state.orders,
  clients: state.clients,
});

export default connect(
  mapStateToProps,
  { setActivePage, getAll, getNotifications },
)(CentralAdminTopBar);
