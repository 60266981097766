import ShoppingPage from './views/shopping.page';
import SuccessPage from './views/success.page';
import CancelPage from './views/cancel.page';

import {
  PAYMENT_CONFIRM_PATH,
  PAYMENT_CANCEL_PATH,
} from './constants';

ShoppingPage.path = '/shoppingcart';
SuccessPage.path = PAYMENT_CONFIRM_PATH;
CancelPage.path = PAYMENT_CANCEL_PATH;

export default {
  views: {
    ShoppingPage,
    SuccessPage,
    CancelPage,
  }
};
