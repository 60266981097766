import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk'
import { logger } from 'redux-logger'
import crudTypes from './config/crudTypes'
import interceptor from './interceptor'
import logIn from './reducers/logInReducer'
import facebookPages from './reducers/facebookReducer'
import userDetails from './reducers/userDetailsReducer'
import crudReducer from './reducers/crudReducer'
import notificationReducer from './reducers/notificationReducer'
import stripeSettingsReducer from './reducers/stripeSettingsReducer'
import { composeWithDevTools } from 'redux-devtools-extension'
import productReducer from './reducers/productReducer';
import checkoutReducer from './reducers/checkoutReducer'
import shoppingReducer from './reducers/shoppingReducer';
import subscriptionReducer from './reducers/subscriptionReducer';
import adminClientsReducer from './reducers/adminClientsReducer';
import adminTopClientsReducer from './reducers/adminTopClientsReducer';
import orderReducer from './reducers/orderReducer';
import adminSubInvoiceReducer from './reducers/adminSubInvoiceReducer'
import adminOrdersReducer from './reducers/adminOrdersReducer';
import adminPagesReducer from './reducers/adminPagesReducer';
import adminSubscriptionsReducer from './reducers/adminSubscriptionsReducer';

// intercept requests
interceptor()

const reducers = combineReducers(
    {
      logIn: logIn,
      facebookPages: facebookPages,
      userDetails: userDetails,
      orders: orderReducer,
      clients: crudReducer(crudTypes.client),
      categories: crudReducer(crudTypes.category),
      products: productReducer,
      notifications: notificationReducer,
      stripeSettings: stripeSettingsReducer,
      checkout: checkoutReducer,
      shopping: shoppingReducer,
      subscriptions: subscriptionReducer,
      adminOrders: adminOrdersReducer,
      adminPages: adminPagesReducer,
      adminClients: adminClientsReducer,
      adminSubscriptions: adminSubscriptionsReducer,
      adminTopClients: adminTopClientsReducer,
      adminSubInvoices: adminSubInvoiceReducer
    }
)

const store = createStore(reducers, composeWithDevTools(applyMiddleware(logger, thunk)))

export default store
