import axios from 'axios';
import Url from '../config/api';

const baseUrl = `${Url.baseUrl}/Subscribe`

export const showUpgradeModal = (show = true) => {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_UPGRADE_MODAL',
      data: show,
    });
  };
};

export const getAllPlans = (done) => {
  return (dispatch) => {
    axios.get(`${baseUrl}/GetAllPlans`)
      .then(res => {
        done(null, res.data);
      })
      .catch(err => {
        done(err);
      });
  };
};

export const addSubscription = (planId, planName, pageId, done) => {
  return (dispatch) => {
    axios.post(`${baseUrl}/AddSubscription`, {
      planId,
      planName,
      pageId,
    })
    .then(res => {
      done && done(null, res.data);
    })
    .catch(err => {
      done && done(err);
    });
  };
};

export const getSubscription = (pageId, done) => {
  return (dispatch) => {
    axios.get(`${baseUrl}/GetSubscriptionByPageId/${pageId}`)
      .then(res => {
        done && done(null, res.data);
      })
      .catch(err => {
        done && done(err);
      });
  };
};

export const getActivePageSubscription = (pageId, done) => {
  return (dispatch) => {
    axios.get(`${baseUrl}/GetSubscriptionByPageId/${pageId}`)
      .then(res => {
        done && done(null, res.data);
        dispatch({
          type: 'GET_ACTIVE_PAGE_SUBSCRIPTION_OK',
          data: res.data,
        });
      })
      .catch(err => {
        done && done(err);
        dispatch({ type: 'GET_ACTIVE_PAGE_SUBSCRIPTION_ERROR' });
      });
  };
}

export const cancelSubscription = (pageId, done) => {
  return (dispatch) => {
    axios.put(`${baseUrl}/CancelSubscription/${pageId}`)
      .then(res => {
        done && done(null, res.data);
        dispatch({
          type: 'GET_ACTIVE_PAGE_SUBSCRIPTION_OK',
          data: res.data,
        });
      })
      .catch(err => {
        done && done(err);
      });
  };
};

export const reactivateLastSubscription = (pageId, done) => {
  return (dispatch) => {
    axios.post(`${baseUrl}/ReactivateLastSubscription/${pageId}`)
      .then(res => {
        done && done(null, res.data);
        dispatch({
          type: 'GET_ACTIVE_PAGE_SUBSCRIPTION_OK',
          data: res.data
        });
      })
      .catch(err => {
        done && done(err);
      });
  };
};

export const getSubscriptionInvoices = (pageId, done) => {
  return (dispatch) => {
    axios.get(`${baseUrl}/GetSubscriptionInvoices/${pageId}`)
      .then(res => {
        done(null, res.data);
      })
      .catch(err => {
        done(err);
      });
  };
};

export const setGuestCheckoutId = (guestCheckoutId) => {
  return (dispatch) => {
      dispatch({
        type: 'SET_GUEST_CHECKOUT_ID_OK',
        data: guestCheckoutId
      });
  };
};