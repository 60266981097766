import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import InfoPage from './components/infoPage';
import { MainButton } from './components/buttons';
import { usePendingPayment } from '../hooks';

import {
  completePayment,
} from 'actions/shoppingActions';

import OrderConfirmedGraphic from '../graphics/orderConfirmed.svg';

const closeWindow = () => {
  if ('MessengerExtensions' in window) {
    window.MessengerExtensions.requestCloseBrowser(() => {}, (err) => {
      console.error(err);
      // fallback to close by redirect
      window.location.href = 'https://facebook.com';
    });
  } else {
     // fallback to close by redirect
     window.location.href = 'https://facebook.com';
  }
};

const OrderSuccessPage = ({ completePayment }) => {
  const [pendingPayment, setPendingPayment] = usePendingPayment();
  const [close, setClose] = useState(false);

  useEffect(() => {
    if (pendingPayment) {
      completePayment(pendingPayment.sessionId, (err) => {
        if (err) {
          console.log(err);
          return;
        }
        setPendingPayment(null);
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    if (!pendingPayment && close) {
      closeWindow();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [pendingPayment, close]);

  return (
    <InfoPage
      success={true}
      title={'Order Confirmed'}
      info={'Your order has been placed and payment has been confirmed. Give the restorant some time to prepare your delicious food and meanwhile do what you love.'}
      graphicSrc={OrderConfirmedGraphic}
      actions={(
        <MainButton text="Done" onClick={() => setClose(true)} />
      )}
    />
  );
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  {
    completePayment,
  }
)(OrderSuccessPage);
