export default {
    order: {
        tag: "ORDERS",
        uri: "Orders"
    },
    product:{
        tag: "PRODUCTS",
        uri: "Product"
    },
    category:{
        tag: "CATEGORIES",
        uri: "Categories"
    },
    client:{
        tag: "CLIENTS",
        uri: "Clients"
    },
    image:{
        tag: "IMAGE",
        uri: "Image"
    }
}