import React from 'react';
import styled from 'styled-components';
import Icons from '../../shared/views/icons';
import colors from '../../shared/helpers/colors';
import mediaQueries from '../../shared/helpers/mediaQueries';
import transition from '../../shared/helpers/transition';
import hexToRGBA from '../../shared/helpers/hexToRGBA';

const NotificationButtonWrapper = styled.div`
  position: relative;
`;

const ButtonWithIconWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  background-color: ${props => hexToRGBA(props.color, 0.1) || ''};
  user-select: none;
  transition: ${transition};
  font-size: 16px;

  ${mediaQueries.mobile(`
    font-size: 14px;
  `)}

  :hover {
    cursor: pointer;
    background-color: ${props => hexToRGBA(props.color, 0.3) || ''};
  }

  min-width: 36px;
  height: 36px;

  ${mediaQueries.mobile(`
    min-width: 120px;
    height: 25px;
  `)}
`;

const IconWrapper = styled.div`
  margin: 0 11px 0 11px;

  ${mediaQueries.mobile(`
    margin: 0 3px 0 6px;
  `)}
`;

const NewNotificationCounter = styled.div`
  position: absolute;
  top: -7px;
  right: -8px;
  border-radius: 50%;
  background-color: ${colors.red};
  color: white;
  width: 16px;
  height: 16px;
  font-family: Inter;
  font-weight: bold;
  font-size: 8px;
  line-height: 17px;
  display: ${props => (props.counter > 0 ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

const NotificationBaseButton = ({ Icon, text, color, ...rest }) => (
  <ButtonWithIconWrapper color={color} {...rest}>
    <IconWrapper>
      <Icon fill={color} />
    </IconWrapper>
    <span style={{ color }}>{text}</span>
  </ButtonWithIconWrapper>
);
const NotificationButton = ({ refer, counter, ...props }) => (
  <NotificationButtonWrapper ref={refer}>
    <NewNotificationCounter counter={counter}>{counter}</NewNotificationCounter>
    <NotificationBaseButton Icon={Icons.Notification} color={colors.blue} {...props} />
  </NotificationButtonWrapper>
);

export default NotificationButton;
