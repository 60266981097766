import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const {
  views: {
    Text: {
      Paragraph: { P },
      H4,
      H5,
    },
    Button: {
      BaseButton,
    },
    Icons: {
      XIcon,
      Add,
      Remove
    }
  },
  helpers: {
    colors,
  }
} = shared;

const OrderPanelContainer = styled.div`
  width: calc(100% - 30px);
  background-color: #F6F6F9;
  margin: 15px 15px 0 15px;

  & > * {
    padding: 5px 10px;
  }

  & > :last-child {
    margin: 0;
    margin-top: 10px;
  }
`;

const OrderPanelTitle = styled(H5)`
  color: ${colors.darkestGrey};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

const OrderPanelCategory = styled.div`
`;

const OrderCategoryName = styled(H5)`
  color: ${colors.darkestGrey};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
`;

const OrderPanelProduct = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;

const OrderProductName = styled(H5)`
  color: ${colors.textColor};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const OrderProductImg = styled.img`
  width: 30px;
  height: 20px;
  border-radius: 3px;
  margin-right: 10px;
`;

const OrderProductPrice = styled(H5)`
  color: ${colors.green};
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  width: 65px;
  ${props => !props.canUpdate ? `
    margin-right: 15px;
  ` : null}
`;

const OrderProductQuantity = styled(P)`
  color: ${colors.darkestGrey};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
`;

const OrderPanelInfo = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const OrderPanelActions = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: 7px;
  }
`;

const OrderPanalTotals = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E9EAF1;
  padding: 10px 58px 10px 49px;
`;

const OrderTotalPanelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const FeeLabel = styled(P)`
  color: ${colors.textColor};
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
`;

const FeeValue = styled(P)`
  color: ${colors.textColor};
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
`;

const TotalLabel = styled(H4)`
  color: ${colors.darkestGrey};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
`;

const TotalPrice = styled(P)`
  color: ${colors.green};
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  width: 16px;
  text-align: left;
`;

const ProductButton = styled(BaseButton)`
  width: 20px;
  height: 20px;
  padding: 2px;
`;

const getProductsWithDetails = (categories, order) => {
  const infos = categories.reduce((infos, category) => {
    return infos.concat(category.magProducts);
  }, []);

  return order.orderProducts.map(product => {
    const info = infos.find(i => i.id === product.id);

    return {
      ...product,
      categoryId: info.categoryId,
      imagePath: info.imagePath,
    };
  });
};

const OrderPanel = ({ order, categories, addProduct, removeProduct, canUpdate }) => {
  const orderProducts = getProductsWithDetails(categories, order)
  const categoriesWithProducts = Object.values(orderProducts.reduce((map, product) => {
    const category = categories.find(c => c.id === product.categoryId);
    if (category) {
      map[category.id] = map[category.id] || {
        name: category.name,
        products: [],
      };

      const productDetails = category.magProducts.find(p => p.id === product.id);
      map[category.id].products.push({
        ...product,
        imagePath: productDetails.imagePath,
      });
    }
    return map;
  }, {}));

  return (
    <OrderPanelContainer>
      <OrderPanelTitle>My Order</OrderPanelTitle>
      {categoriesWithProducts.map((category, i) => (
        <OrderPanelCategory key={`${category.name}-${i}`}>
          <OrderCategoryName>{category.name}</OrderCategoryName>
          {category.products.map((product, i) => (
            <OrderPanelProduct key={`${product.name}-${i}`}>
              <OrderPanelInfo>
                <OrderProductImg src={product.imagePath} />
                <OrderProductName>{product.name}</OrderProductName>
              </OrderPanelInfo>
              <OrderPanelActions>
                {canUpdate ? <ProductButton onClick={() => removeProduct(product)}>
                  <Remove fill={colors.darkGrey} width="16px" height="16px" />
                </ProductButton> : null}
                <OrderProductQuantity>{product.qty}</OrderProductQuantity>
                {canUpdate ? <ProductButton onClick={() => addProduct(product)}>
                  <Add fill={colors.yellow}  width="16px" height="16px" />
                </ProductButton> : null}
              </OrderPanelActions>
              <OrderProductPrice canUpdate={canUpdate}>${product.price}</OrderProductPrice>
              {canUpdate ? <XIcon fill={colors.red} onClick={() => removeProduct(product, false)} width="16px" height="16px" /> : null}
            </OrderPanelProduct>
          ))}
        </OrderPanelCategory>
      ))}
      <OrderPanalTotals>
        <OrderTotalPanelRow>
          <FeeLabel>Shipping Fee</FeeLabel>
          <FeeValue>${order.shippingFee}</FeeValue>
        </OrderTotalPanelRow>
        <OrderTotalPanelRow>
          <FeeLabel>Tax Fee</FeeLabel>
          <FeeValue>${order.taxFee}</FeeValue>
        </OrderTotalPanelRow>
        <OrderTotalPanelRow>
          <TotalLabel>Total:</TotalLabel>
          <TotalPrice>${order.total}</TotalPrice>
        </OrderTotalPanelRow>
      </OrderPanalTotals>
    </OrderPanelContainer>
  );
};

export default OrderPanel;

