import React, { useState } from 'react';
import styled from 'styled-components';
import shared from 'app/shared/';

import StripeSettings from './stripe.settings';
import SubscribeSettings from './subscribe.settings';

const {
  views: { Button,  TabBar },
  helpers: { colors },
} = shared;

const SettingsBody = styled.div`
  margin-top: 2.455%;
  background: ${colors.white}
  padding: 0.7% 30px;
  display: flex;
  max-height: 700px;
  border: 1px solid #E9EAF1;
  box-sizing: border-box;
  border-radius: 3px;
  flex-direction: column;
`;

const tabs = [
  {
    text: 'Stripe',
    value: 'stripe',
    component: StripeSettings,
  },
  {
    text: 'Subscriptions',
    value: 'subscriptions',
    component: SubscribeSettings,
  }
];

const SettingsPage = () => {
  const [currentTab, updateCurrentTab] = useState(tabs[0].value);
  const [executeOnSubmit, setExecuteOnSubmit] = useState((() => { return () => alert('Initiated') }));

  const ActiveTab = tabs.find(t => t.value === currentTab).component;
  const updateState = value => {
    updateCurrentTab(value);
  };

  return (
    <div>
      <div style={{ display: 'inline-flex', width: '100%' }}>
        <div style={{ width: '90%' }}>
          <TabBar
            tabs={tabs}
            selectedElement={currentTab}
            updateSelectedElement={value => updateState(value)}
          />
        </div>
        <div style={{ width: '10%', borderBottom: `1px solid ${colors.lightGrey}` }}>
          <Button.Button
            color={colors.blue}
            text="Save"
            size={Button.ButtonSize.extraMedium}
            onClick={executeOnSubmit}
          />
        </div>
      </div>

      <SettingsBody>
        <ActiveTab setExecuteOnSubmit={setExecuteOnSubmit} />
      </SettingsBody>
    </div>
  );
};

SettingsPage.path = '/settings';

export default SettingsPage;
