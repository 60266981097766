import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared/';
import moment from 'moment';

import {
  addSubscription,
  cancelSubscription,
  reactivateLastSubscription,
  getSubscriptionInvoices,
  getActivePageSubscription
} from 'actions/subscriptionActions';

const {
  views: {
    Toggle,
    Button,
    Table,
    Alert,
    Text,
  },
  helpers: {
    colors,
  }
} = shared;

const {
  Link: { ExternalLink },
} = Text;

const SubscribeSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SettingsTitleWrapper = styled.div`
  margin-top: 1.45%;
  margin-bottom: 40px;
  font-weight: 500;
  width: 50%
`;

const SettingsTitle = styled.div`
  font-size: 16px;
  color: ${colors.blue};
`;

const SettingsLabelWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  flex: 1;
`;

const SettingAlertWrapper = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  margin-bottom: 40px;
`;

const SettingAlert = styled(Alert)`
  width: 100%;
`;

const SettingsLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: ${colors.textColor};
  min-width: 185px;
`;

const SettingLabelValue = styled.div`
  color: ${colors.blue};
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
`;

const TableWrapper = styled.div`
  width: -webkit-fill-available;

  & thead th {
    padding-left: unset;
    padding-right: unset;
  }

  & tbody td {
    padding-left: unset;
    padding-right: unset;
  }
`;

const StatusValue = styled.p`
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.color || colors.green};
`;

const tableData = {
  header: [
    {
      label: 'ID',
      key: 'invoiceId',
      sortable: false,
    },
    {
      label: 'Date',
      key: 'paymentDate',
      sortable: true,
      renderer: ({ paymentDate }) =>
        moment(paymentDate).format('YYYY-MM-DD')
    },
    {
      label: 'Value',
      key: 'amount',
      sortable: true,
      renderer: ({ amount }) => `$${amount}`
    },
    {
      label: 'Status',
      key: 'status',
      renderer: ({ status }) => (
        <StatusValue>{status}</StatusValue>
      ),
      sortable: false,
    },
    {
      label: 'ACTION',
      width: '70px',
      key: 'action',
      sortable: false,
      renderer: ({ onView }) => {
        return (
          <Button.Button
            style={{ fontWeight: 600 }}
            text="View"
            size={Button.ButtonSize.medium}
            color={colors.blue}
            onClick={onView}
          />
        );
      },
    },
  ],
};

const SubscribeSettings = ({
  user,
  activePage,
  subscription,
  getSubscriptionInvoices,
  reactivateLastSubscription,
  cancelSubscription,
  setExecuteOnSubmit,
  getActivePageSubscription,
}) => {
  const [toggle, setToggle] = useState(false);
  const [sort, setSort] = useState({ key: 'paymentDate', order: 1 });
  const [invoices, setInvoices] = useState([]);
  const [openInvoicePerview, setOpenInvoicePreview] = useState('');
  const [alert, setAlert] = useState({ show: false, message: '' });
  getSubscriptionInvoices = useCallback(getSubscriptionInvoices, []);

  useEffect(() => {
    if (subscription != null && !subscription.isCanceled) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [subscription]);

  useEffect(() => {
    if (sort) {
      const data = sortInvoicesBy([...invoices], sort.key, sort.order);
      setInvoices(data);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [sort]);

  useEffect(() => {
    if (activePage && activePage.id) {
      getSubscriptionInvoices(activePage.id, (err, data) => {
        if (err) {
          console.log(err);
          return;
        }
        data.forEach(item => {
          item.onView = () => {
            setOpenInvoicePreview(item.pdfUrl);
          };
        });

        data = sortInvoicesBy([...data], sort.key, sort.order);
        setInvoices(data);
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [getSubscriptionInvoices, activePage]);

  useEffect(() => {
    if (openInvoicePerview) {
      window.open(openInvoicePerview, '_blank');
      setOpenInvoicePreview('');
    }
  }, [openInvoicePerview]);

  const handleToggleChange = () => {
    if (!activePage || !activePage.id)
      return;

    const newToggle = !toggle;
    setToggle(newToggle);

    if (newToggle === (subscription !== null && !subscription.isCanceled)) {
      setExecuteOnSubmit(null);
    } else {
      const done = (message, err) => {
        getActivePageSubscription(activePage.id);
        if (err) {
          setToggle(!newToggle);
          setExecuteOnSubmit(null);
        }
        setAlert({
          show: true,
          message,
        });
      };

      if (newToggle) { // re-activate
        setExecuteOnSubmit(() => () => {
          reactivateLastSubscription(activePage.id, done.bind(
            null, 'You have successfully re-activated your subscription'
          ));
        });
      } else { // cancel
        setExecuteOnSubmit(() => () => {
          cancelSubscription(activePage.id, done.bind(
            null,
            'You have cancelled your subscription, however it will remain active till the end of your current plan.'
          ));
        });
      }
    }
  };

  const dataTableSort = (key) => {
    setSort((prevSort) => ({
      key,
      order: -1 * prevSort.order,
    }));
  };

  if(!subscription)
    return null;
  return (
    <SubscribeSettingsWrapper>
      <SettingsTitleWrapper>
        <SettingsTitle>Subscription Details</SettingsTitle>
      </SettingsTitleWrapper>
      <SettingAlertWrapper show={alert.show}>
        <SettingAlert show={alert.show} message={alert.message} />
      </SettingAlertWrapper>

      {subscription.inactive_message ?
      <h1>{subscription.inactive_message}</h1>
      :
      <>
      <ExternalLink onClick={() => {
        window.Paddle.Checkout.open({
          override: subscription.update_url,
          successCallback: () => {
            setTimeout(() => {
              window.location.href = '/dashboard';
            }, 1000);
          },
        });
      }}>Update Payment Information</ExternalLink>
      <ExternalLink onClick={() => {
        window.Paddle.Checkout.open({
          override: subscription.cancel_url,
          successCallback: () => {
            setTimeout(() => {
              window.location.href = '/dashboard';
            }, 1000);
          },
        });
      }}>Cancel Subscription</ExternalLink>
      </>}
     <SettingsLabelWrapper>
        <SettingsLabel>Member Since:</SettingsLabel>
        <SettingLabelValue>
          {moment(subscription.memberSince).format('MMMM DD YYYY')}
        </SettingLabelValue>
      </SettingsLabelWrapper>
      <SettingsLabelWrapper>
        <SettingsLabel>Current Plan:</SettingsLabel>
        <SettingLabelValue>{subscription.planName}</SettingLabelValue>
      </SettingsLabelWrapper>
      {subscription && !subscription.isTrialSubscription ? (
        <SettingsLabelWrapper>
          <SettingsLabel>Subscription</SettingsLabel>
          <ActiveSubscriptionToggle isActive={toggle} onClick={handleToggleChange} />
        </SettingsLabelWrapper>
      ) : null}
      {subscription && !subscription.isTrialSubscription ? (
        <SettingsLabelWrapper>
          <SettingsLabel>Subscription History</SettingsLabel>
          <TableWrapper>
            <Table.TableView data={{
              ...tableData,
              values: invoices,
              action: dataTableSort,
            }} />
          </TableWrapper>
        </SettingsLabelWrapper>
      ) : null}
    </SubscribeSettingsWrapper>
  );
};

const ActiveSubscriptionToggle = ({ isActive, onClick }) => (
  <Toggle show={!isActive} onClick={onClick} color={isActive ? colors.blue : colors.darkGrey} />
);

const sortInvoicesBy = (invoices, key, order) => {
  switch (key) {
    case 'paymentDate': {
      invoices.sort((a, b) => {
        return order * moment(b.paymentDate).diff(a.paymentDate);
      });
      return invoices;
    }

    case 'amount': {
      invoices.sort((a, b) => {
        return order * (b.amount - a.amount);
      });
      return invoices;
    }

    default:
      return invoices;
  }
}

const mapStateToProps = state => ({
  user: state.userDetails.user,
  activePage: state.facebookPages.activePage,
  subscription: state.subscriptions.activePageSubscription,
});

export default connect(
  mapStateToProps,
  {
    addSubscription,
    cancelSubscription,
    getSubscriptionInvoices,
    reactivateLastSubscription,
    getActivePageSubscription
  }
)(SubscribeSettings);
