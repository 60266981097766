import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Helmet } from 'react-helmet';

import '../assets/global.css';

import {
  initShopping,
  addProductToOrder,
  removeProductFromOrder,
  saveOrder,
  setOrderLocation,
  setPaymentMethod,
  setClientInfo,
  startPayment,
  getOrderBySessionId,
  startNewOrder,
  setAddressInfo,
  setOrderDescription,
} from 'actions/shoppingActions';

import shared from '../../shared';

import WizardStep from './components/wizardStep';
import MainBox from './components/mainbox';
import { MainButton, OutlinedButton } from './components/buttons';
import ProductSelectStep from './steps/productSelect';
import AddressDetailsStep from './steps/addressDetails';
import PaymentDetailsStep from './steps/paymentDetails';

import {
  useQueryParams,
  usePendingPayment,
} from '../hooks';

import {
  PAYMENT_CONFIRM_PATH,
  NOT_AUTH_REDIRECT_PATH,
} from '../constants';

const{
  views:{
    Wizard,
    Spinner,
    Icons: {
      Tags,
      Location,
      Card,
    },
  }
} = shared;

const OrderWizard = styled(Wizard)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ActionsWrapper = styled.div`
  flex-grow: 1;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  & > :first-child {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: unset;
  }
`;

const ShoppingPage = ({
  location,
  order,
  paymentMethod,
  clientInfo,
  initShopping,
  addProductToOrder,
  removeProductFromOrder,
  saveOrder,
  setOrderLocation,
  setPaymentMethod,
  setClientInfo,
  startPayment,
  getOrderBySessionId,
  startNewOrder,
  setAddressInfo,
  setOrderDescription,
}) => {
  const [queryParams] = useQueryParams(location.search);
  const [authCode] = useState(queryParams.get('Code') || queryParams.get('authCode'));
  const [pendingPayment, setPendingPayment] = usePendingPayment();
  const [categories, setCategories] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState('');
  const [isProcessing, setIsProccessing] = useState(false);
  const [isRetry, setIsRetry] = useState(false);
  const [proceedBtnText, setProceedBtnText] = useState('Proceed');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (authCode) {
      initShopping(authCode, (err, data) => {
        if (err) {
          console.log(err);
          return setRedirectTo(NOT_AUTH_REDIRECT_PATH);
        }

        setPageInfo(data.pageInfo);
        setCategories(data.categories);

        if (!pendingPayment || pendingPayment.pageId !== data.pageInfo.pageId) {
          setPendingPayment(null);
          setIsLoading(false);
        } else {
          setIsRetry(true);
          setProceedBtnText('Retry');
          getOrderBySessionId(pendingPayment.sessionId, (err) => {
            if (err) {
              console.error('Cannot get order by session id');
              return setRedirectTo(NOT_AUTH_REDIRECT_PATH);
            }
            setIsLoading(false);
          });
        }
      });
    } else {
      setRedirectTo(NOT_AUTH_REDIRECT_PATH);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [authCode, initShopping, getOrderBySessionId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError(null);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [error]);

  if (redirectTo) {
    return <Redirect to={redirectTo} push={isRetry} />;
  }

  const onStepChange = ({ nextStep, totalSteps }) => {
    // update proceed btn text
    if (isRetry) {
      setProceedBtnText('Retry');
    } else if (nextStep >= totalSteps) {
      setProceedBtnText('Place Order');
    } else {
      setProceedBtnText('Proceed');
    }
  };

  const onFinish = () => {
    saveOrder(order, paymentMethod, clientInfo, (err, order) => {
      if (err) {
        console.log(err);
        return setIsProccessing(false);
      }

      startPayment(order.id, (err, payment) => {
        if (err) {
          console.log(err);
          return setIsProccessing(false);
        }

        const { paymentMethod, paymentDetails } = payment;

        if (pageInfo.pageId) {
          setPendingPayment({
            sessionId: paymentDetails.sessionId,
            pageId: pageInfo.pageId,
            authCode: authCode,
          });
        }

        switch (paymentMethod.toLowerCase()) {
          case 'cash':
            setRedirectTo(`${PAYMENT_CONFIRM_PATH}?session_id=${paymentDetails.sessionId}`);
          break;

          case 'stripe':
            loadStripe(paymentDetails.publicKey).then((stripe) => {
              stripe.redirectToCheckout({ sessionId: paymentDetails.sessionId })
                .catch(err => {
                  console.log(err);
                  setPendingPayment(null);
                  setIsProccessing(false);
                });
            });
          break;

          default:
            // make eslint happy
        }
      });

    });
  };

  const onProceed = (activeStep, totalSteps, nextStep) => {
    setError(null);

    const err = validateStep(activeStep, totalSteps);
    if (err instanceof Error) {
      return setError(err);
    }

    if (isProcessing) {
      return;
    } else {
      setIsProccessing(true);
    }

    setIsProccessing(false);
    nextStep();
  };

  const onGoBack = (activeStep, totalSteps, prevStep) => {
    if (isProcessing) {
      return;
    }

    prevStep();
  };

  const onStartNewOrder = (setActive) => {
    if (!isRetry) return;
    startNewOrder(() => {
      setPendingPayment(null);
      setActive(1); // reset to first step
      setIsRetry(false);
    });
  };

  const validateStep = (activeStep) => {
    if (activeStep >= 3 && (!clientInfo.firstName || !clientInfo.lastName || !clientInfo.phone))
      return new Error('Please enter your payment info');

    if (activeStep >= 2 && (!order.orderLocation || !order.orderLocation.address))
      return new Error('Please select your address location');

    if (activeStep >= 1 && order.total <= 0)
      return new Error('Please select at least one product');

    return null;
  };

  return (
    <MainBox logoPosition="left" logoPadding="15px 12px" logoSize="tiny" boxAlign="bottom">
      {pageInfo ? <Helmet>
        <title>{pageInfo.pageName}</title>
      </Helmet> : null}
      {
        isLoading
        ? <Spinner size={30} style={{ height: '100px' }} />
        : (
          <OrderWizard initialStep={isRetry ? 3 : 1} totalSteps={3} onStepChange={onStepChange} onFinish={onFinish}>
            {
              ({ activeStep, totalSteps, nextStep, prevStep, setActive }) => {
                return (
                  <>
                    <WizardStep id={1} name="Choose Products" icon={Tags} activeStep={activeStep} error={error}
                      step={
                        <ProductSelectStep order={order} categories={categories} canUpdate={!isRetry} addProduct={addProductToOrder} removeProduct={removeProductFromOrder} orderDescription={order.description} setOrderDescription={setOrderDescription} />
                      }
                    />
                    <WizardStep id={2} name="Address Details" icon={Location} activeStep={activeStep} error={error}
                      step={
                        <AddressDetailsStep orderLocation={order.orderLocation} setOrderLocation={setOrderLocation} addressInfo={order.addressInfo} setAddressInfo={setAddressInfo} />
                      }
                    />
                    <WizardStep id={3} name="Payment Process" icon={Card} activeStep={activeStep} error={error}
                      step={
                        <PaymentDetailsStep  paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} clientInfo={clientInfo} setClientInfo={setClientInfo} canPayWithStripe={pageInfo.hasStripeConfiguration} canUpdate={!isRetry} />
                      }
                    />
                    <ActionsWrapper>
                      <MainButton
                        text={proceedBtnText}
                        onClick={onProceed.bind(null, activeStep, totalSteps, nextStep)}
                      />
                      {isRetry ? <OutlinedButton
                        text="Start New Order"
                        onClick={onStartNewOrder.bind(null, setActive)}
                      /> : null}
                      {activeStep > 1 && !isRetry ? <OutlinedButton
                        text="Go Back"
                        onClick={onGoBack.bind(null, activeStep, totalSteps, prevStep)}
                      /> : null}
                    </ActionsWrapper>
                  </>
                );
              }
            }
          </OrderWizard>
        )
      }
    </MainBox>
  );
};

const mapStateToProps = state => ({
  order: state.shopping.order,
  paymentMethod: state.shopping.paymentMethod,
  clientInfo: state.shopping.client,
});

export default connect(
  mapStateToProps,
  {
    initShopping,
    addProductToOrder,
    removeProductFromOrder,
    saveOrder,
    setOrderLocation,
    setPaymentMethod,
    setClientInfo,
    startPayment,
    getOrderBySessionId,
    startNewOrder,
    setAddressInfo,
    setOrderDescription,
  },
)(ShoppingPage);
