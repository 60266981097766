import axios from 'axios';
import Url from '../config/api';

const { baseUrl} = Url;

const baseURI = `${baseUrl}/admin/`;

export const getAllOrders = (setStatus=null) => {
  return (dipatch) => {
    axios.get(`${baseURI}GetAllOrders`)
          .then(res =>{
              if(res.status >= 200 && res.status <= 300){
                  setStatus && setStatus('OK');
                  dipatch({
                    type: 'GET_ORDERS_OK',
                    data: res.data
                  })
              }
          })
          .catch(() => {
              setStatus && setStatus('ERROR');
              dipatch({type: 'GET_ORDERS_ERROR'})
      })
  }
}

export const getAllPages = (setStatus=null) => {
  return (dipatch) => {
    axios.get(`${baseURI}GetAllPages`)
          .then(res =>{
              if(res.status >= 200 && res.status <= 300){
                  setStatus && setStatus('OK');
                  dipatch({
                    type: 'GET_PAGES_OK',
                    data: res.data
                  })
              }
          })
          .catch(() => {
              setStatus && setStatus('ERROR');
              dipatch({type: 'GET_PAGES_ERROR'})
      })
  }
}

export const getAllSubscriptions = (setStatus=null, pageId = 0) => {
    return (dipatch) => {
      axios.get(`${baseURI}GetAllSubscriptions?pageId=${pageId}`)
            .then(res =>{
                if(res.status >= 200 && res.status <= 300){
                    setStatus && setStatus('OK');
                    dipatch({
                      type: 'GET_SUBSCRIPTIONS_OK',
                      data: res.data
                    })
                }
            })
            .catch(() => {
                setStatus && setStatus('ERROR');
                dipatch({type: 'GET_SUBSCRIPTIONS_ERROR'})
        })
    }
}

export const getTopNewClients = (setStatus = null) => {
  return (dipatch) => {
    axios.get(`${baseURI}GetTopNewClients/`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dipatch({
            type: 'GET_TOP_NEW_CLIENTS_OK',
            data: res.data
          })
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dipatch({ type: 'GET_TOP_NEW_CLIENTS_ERROR' })
      })
  }
}

export const getTopPayingClients = (setStatus = null) => {
  return (dipatch) => {
    axios.get(`${baseURI}GetTopPayingClients/`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dipatch({
            type: 'GET_TOP_PAYING_CLIENTS_OK',
            data: res.data
          })
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dipatch({ type: 'GET_TOP_PAYING_CLIENTS_ERROR' })
      })
  }
}

export const getTopInactiveClients = (setStatus = null) => {
  return (dipatch) => {
    axios.get(`${baseURI}GetTopInactiveClients/`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dipatch({
            type: 'GET_TOP_INACTIVE_CLIENTS_OK',
            data: res.data
          })
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dipatch({ type: 'GET_TOP_INACTIVE_CLIENTS_ERROR' })
      })
  }
}

export const getTotalsClients = (setStatus = null, filter = 0, clientId = 0) => {
  return (dipatch) => {
    axios.get(`${baseURI}GetTotalsClients?filter=${filter}&clientId=${clientId}`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dipatch({
            type: 'GET_TOTALS_CLIENTS_OK',
            data: res.data
          })
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dipatch({ type: 'GET_TOTALS_CLIENTS_OK_ERROR' })
      })
  }
}

export const getSubscriptionInvoices = (setStatus = null, pageId = 0) => {
  return (dipatch) => {
    axios.get(`${baseURI}/GetSubscriptionInvoices/${pageId}`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dipatch({
            type: 'GET_SUBSCRIPTION_INVOICES_OK',
            data: res.data
          })
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dipatch({ type: 'GET_SUBSCRIPTION_INVOICES_ERROR' })
      })
  }
}