import Restrict from './views/restrict';
import Modal from './views/modal';

Restrict.path = '/upgrade';

export default {
  views: {
    Restrict,
    Modal,
  }
};
