import { useState, useCallback } from 'react';

import {
  PAYMENT_PENDING_STORAGE_KEY,
} from './constants';

export const useQueryParams = (searchString) => {
  const queryParams = new URLSearchParams(searchString);
  return useState(queryParams);
};

export const usePendingPayment = () => {
  const [state, setState] = useState(() => {
    const pendingSession = localStorage.getItem(PAYMENT_PENDING_STORAGE_KEY);
    if (!pendingSession) {
      return null;
    } else {
      try {
        return JSON.parse(pendingSession);
      } catch {
        localStorage.removeItem(PAYMENT_PENDING_STORAGE_KEY);
      }
    }
  });

  const setPendingSession = useCallback((session) => {
    if (session === null || session === undefined) {
      localStorage.removeItem(PAYMENT_PENDING_STORAGE_KEY);
      setState(null);
    } else {
      setState(session);
      const storedSession = JSON.stringify(session);
      localStorage.setItem(PAYMENT_PENDING_STORAGE_KEY, storedSession);
    }
  }, []);

  return [state, setPendingSession];
};
