import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const {
  views: { Card, Text, Icons },
  helpers: { colors },
} = shared;

const{
  Paragraph:{P, },
  H5,
} = Text;

const ProductCardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;

  ${props => props.disabled ? `
    & ${PhotoContainer} {
      filter: grayscale(90%);
    }

    & ${Value} {
      color: ${colors.grey} !important;
    }

    & ${Title} {
      color: ${colors.grey};
    }

  `: null}
`;

const PhotoContainer = styled.div`
  height: 130px;
  background: url(${props => props.photo}) center center;
  background-size: cover;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

const InfoWrapper = styled.div`
  padding: 5px 10px 10px 10px;
  border-top: 1px solid ${colors.lightGrey}
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(H5)`
  color: ${colors.blue};
`;

const Label = styled(P)`
  font-size: 11px;
  line-height: 22px;
  color: ${colors.textColor};
`;

const Value = styled(P)`
  font-size: 12px;
  line-height: 22px;
`;

const Price = styled(Value)`
  color: ${colors.green};
`;

const Sales = styled(Value)`
  color: ${colors.blue};
`;

const ProductCard = ({ title, price, sales, photo, disabled, ...rest }) => (
  <ProductCardWrapper disabled={disabled} {...rest}>
    <PhotoContainer photo={photo} />
    <InfoWrapper>
      <Row style={{ marginBottom: 5 }}>
        <Title>{title}</Title>
        <Icons.Forward style={{ width: 10, height: 10 }} fill={colors.lightGrey} />
      </Row>
      <Row>
        <Label>Price</Label>
        <Price>{price}</Price>
      </Row>
      <Row>
        <Label>Sales</Label>
        <Sales>{sales}</Sales>
      </Row>
    </InfoWrapper>
  </ProductCardWrapper>
);

export default ProductCard;
