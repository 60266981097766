import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const{
  views:{
    Button: {
      Button,
      GradientButton,
    }
  },
  helpers: {
    colors,
  }
} = shared;

const MainButtonStyled = styled(GradientButton)`
  color: ${colors.white};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 17px;
  min-height: 45px;
  width: 100%;
`;

const MainButtonSize = {
  height: '45px',
  fontSize: '16px',
  fontWeight: 600
};

export const MainButton = (props) => (
  <MainButtonStyled size={MainButtonSize} {...props} />
);

export const OutlinedButton = styled(Button)`
  color: ${colors.darkestGrey};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 17px;
  min-height: 45px;
  width: 100% !important;
  border: 2px solid ${colors.lightGrey};
  border-radius: 3px;
`;

