import axios from 'axios';
import Url from '../config/api';

const {
  baseUrl
} = Url;


const disptachSuccess = (type, res) => {
    return {
        type: `${type}_OK`,
        data: res.data
    }
}

const disptachError = (type) => {
    return {
        type: `${type}_ERROR`
    }
}

export const getAll =  (type, setStatus=null) => {
    return (disptach) => {
        axios.get(`${baseUrl}/${type.uri}`)
            .then(res => {
                if(res.status === 200){
                    setStatus && setStatus('OK')
                    disptach({...disptachSuccess(`GET_ALL_${type.tag}`, res)});
                }
                else{
                    setStatus && setStatus('ERROR')
                }
             }).catch(() =>{
                setStatus && setStatus('ERROR')
                disptach({...disptachError(`GET_ALL_${type.tag}`)})
             })
    }
}

export const getById = (type, id, setStatus=null) =>{
    return (disptach) => {
        axios.get(`${baseUrl}/${type.uri}/${id}`)
            .then(res => {
                if(res.status === 200){
                    setStatus && setStatus('OK')
                    disptach({...disptachSuccess(`GET_${type.tag}_BY_ID`, res)})
                }
                else{
                    setStatus && setStatus('ERROR')
                }
            })
            .catch(()=> 
                {
                    setStatus && setStatus('ERROR')
                    disptach({...disptachError(`GET_${type.tag}_BY_ID`)})
                })
    }
}

export const add = (type, data, setStatus=null) => {
    return (disptach) => {
        axios.post(`${baseUrl}/${type.uri}`, data)
            .then(res => {
                if(res.status >= 200 && res.status <= 300){
                    setStatus && setStatus('OK');
                    disptach({...disptachSuccess(`POST_${type.tag}`, res)})
                }
                else{
                    setStatus && setStatus('ERROR')
                }
            })
            .catch(() => {
                setStatus && setStatus('ERROR');
                disptach({...disptachError(`POST_${type.tag}`)})
            })
    }
}

export const edit =  (id, type, data, setStatus=null) => {
    return (disptach) => {
        axios.put(`${baseUrl}/${type.uri}/${id}`, data)
            .then(res => {
                if(res.status >= 200 && res.status <= 300){
                    setStatus && setStatus('OK');
                    disptach({...disptachSuccess(`PUT_${type.tag}`, res)})
                }
                else{
                    setStatus && setStatus('ERROR')
                }
            })
            .catch(() => {
                setStatus && setStatus('ERROR');
                disptach({...disptachError(`PUT_${type.tag}`)})
            })
    }
}


export const remove = (id, type, setStatus=null) => {
    return (disptach) => {
        axios.delete(`${baseUrl}/${type.uri}/${id}`)
            .then(res => {
                if(res.status >= 200 && res.status <= 300){
                    setStatus && setStatus('OK');
                    disptach({...disptachSuccess(`DELETE_${type.tag}`, {data: id})})
                }
                else{
                    setStatus && setStatus('ERROR')
                }
            })
            .catch(() => {
                setStatus && setStatus('ERROR')
                disptach({...disptachError(`DELETE_${type.tag}`)})
            })
    }
}


