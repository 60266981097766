import axios from 'axios';
import Url from '../config/api';

const { baseUrl } = Url;

const baseURI = `${baseUrl}/Orders`;

export const refundOrder = (id, setStatus=null) => {
  return (dispatch) => {
    axios.put(`${baseURI}/cancelOrder/${id}`)
      .then(res=> {
          if(res.status >= 200 && res.status <= 300){
              setStatus && setStatus('OK');
              dispatch({
                  type: 'ORDER_REFUND_OK',
                  data: res.data,
              })
          }
      })
      .catch(() => {
          setStatus && setStatus('ERROR');
          dispatch({type: 'ORDER_REFUND_ERROR'})
      });
  }
};
