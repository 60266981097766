import React, { useEffect, useState, useCallback } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import { setupAutoSuggest, getLatLng, geocodeByLatLng, getCurrentLocation } from './helper';
import * as mapThemes from './styles.json';
import MarkerIcon from './images/marker.png';

import Config from 'config';
import Icons from '../icons.js';
import Colors from '../../helpers/colors';

const mapStyles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  map: {
    position: 'flex',
    width: '100%',
    height: '100%',
  },
};

const LocationPicker = (props) => {
  const {
    google,
    theme,
    location,
    onLocationPicked,
    autocompleteRef,
    resetToCurrent,
    ...rest
  } = props;

  const [centerPos, setCenterPos] = useState(null);
  const [triggerOnLocationPicked] = useState(() => (newLocation) => {
    if (typeof onLocationPicked === 'function') {
      onLocationPicked(newLocation);
    }
  });

  const onLocationChanged = useCallback(async (event) => {
    const pos = await getLatLng(event);
    const results = await geocodeByLatLng(google, pos);
    const address = results[0].formatted_address;
    const newLocation = {
      pos,
      address,
    };
    setCenterPos(newLocation.pos);
    triggerOnLocationPicked(newLocation);
  }, [google, triggerOnLocationPicked]);

  useEffect(() => {
    if (resetToCurrent) {
      getCurrentLocation(Config.map.googleMapsKey).then(pos => {
        onLocationChanged({ latLng: pos });
      });
    }
  }, [resetToCurrent, onLocationChanged]);

  useEffect(() => {
    getCurrentLocation(Config.map.googleMapsKey).then(pos => {
      setCenterPos({
        lat: pos.lat(),
        lng: pos.lng(),
      });
    })
  }, []);

  const onPlaceSelected = async (place) => {
    let address = place.formatted_address;
    if (!address.startsWith(place.name)) {
      address = `${place.name}, ${address}`;
    }
    const pos = await getLatLng(place.geometry);
    const newLocation = {
      pos,
      address,
    };

    setCenterPos(newLocation.pos);
    if (typeof onLocationPicked === 'function') {
      onLocationPicked(newLocation);
    }
  };

  return (
    <Map {...rest}
      google={google}
      center={centerPos}
      style={mapStyles.map}
      containerStyle={mapStyles.container}
      styles={mapThemes[theme] || []}
      onClick={(a, b, e) => onLocationChanged(e)}
      onReady={() => {
        if (props.suggestInputRef) {
          setupAutoSuggest(google, props.suggestInputRef, onPlaceSelected);
        }
      }}
    >
      {
        location && location.pos ? (
          <Marker
            title={location.address}
            position={location.pos}
            draggable={true}
            onDragend={(a, b, e) => onLocationChanged(e)}
            icon={{
              url: MarkerIcon,
            }}
          />
        ) : null
      }
    </Map>
  );
}

const MapsLoadingContainer = () => (
  <span style={{ display: 'flex', justifyContent: 'center' }}>
    <Icons.Loading fill={Colors.blue} width="20px" />
  </span>
);

export default GoogleApiWrapper({
  apiKey: Config.map.googleMapsKey,
  LoadingContainer: MapsLoadingContainer,
})(LocationPicker);
