import axios from 'axios';
import Url from '../config/api';

const baseUrl = `${Url.baseUrl}/Product`;


export const toggleProductStatus = (productId, done) => {
  return (dispatch) => {
    axios.put(`${baseUrl}/UpdateProductStatus/${productId}`).then(res => {
        done && done(null, res.data);
        dispatch({
          type: 'UPDATE_PRODUCT_STATUS_OK',
          data: res.data,
        });
      })
      .catch(err => {
        done && done(err);
        dispatch({ type: 'UPDATE_PRODUCT_STATUS_ERR' });
      });
  };
};
