import React from 'react';
import styled from 'styled-components';

import colors from '../helpers/colors';
import hexToRGBA from '../helpers/hexToRGBA';
import text from './text';
import icons from './icons';

const {
  Paragraph: { P }
} = text;

const AlertColors = {
  error: colors.red,
  warning: colors.orange,
  success: colors.green,
};

const AlertIcons = {
  error: icons.ErrorIcon,
  warning: icons.WarningIcon,
  success: icons.SuccessIcon,
};

const AlertWrapper = styled.div`
  border-radius: 3px;
  min-height: 24px;
  padding: 5px;

  background-color: ${props => hexToRGBA(AlertColors[props.type], 0.2)};
  color: ${props => AlertColors[props.type]};

  display: ${props => props.show ? 'flex' : 'none'};
  align-items: center;
`;


const AlertMessage = styled(P)`
  color: inherit;
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;

  display: flex;
  align-items: center;
  letter-spacing: 1px;
  padding: 5px;
`;

const AlertIconWrapper = styled.div`
  padding: 5px;
`;

export default ({ type = 'success', show, message, className }) => {
  const AlertIcon = AlertIcons[type];

  return (
    <AlertWrapper show={show} type={type} className={className}>
      <AlertIconWrapper>
        <AlertIcon fill={AlertColors[type]} width={14} height={14} />
      </AlertIconWrapper>
      <AlertMessage>{message}</AlertMessage>
    </AlertWrapper>
  );
};
