import React from 'react';
import styled from 'styled-components';
import colors from '../helpers/colors';

/* eslint max-len: ["error", { "ignoreStrings": true }] */

const IconWrapper = styled.div`
  width: ${props => props.width || '20px'};
  height: ${props => props.height || '20px'};
  display: inline-flex;

  svg {
    margin: auto;
    width: ${props => props.width || '20px'};
    height: ${props => props.height || '20px'};
  }

  path {
    fill: ${props => props.fill || 'initial'};
  }
`;

const Settings = props => (
  <IconWrapper {...props}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3013 7.94356L13.498 8.60482C13.6347 8.68033 13.6981 8.83648 13.6513 8.98076C13.3404 9.93887 12.8097 10.8045 12.1156 11.5234C12.0636 11.5772 11.9941 11.6125 11.9184 11.6235C11.8427 11.6346 11.7654 11.6206 11.6991 11.584L10.5033 10.9228C10.0001 11.3355 9.4217 11.6557 8.79708 11.8672V13.1893C8.79708 13.2626 8.771 13.3337 8.72314 13.391C8.67529 13.4482 8.60849 13.4882 8.53376 13.5043C7.55216 13.7153 6.49791 13.7262 5.46678 13.5045C5.31261 13.4713 5.20273 13.3406 5.20273 13.1893V11.8672C4.57811 11.6557 3.99968 11.3355 3.49656 10.9228L2.30075 11.584C2.23445 11.6206 2.1571 11.6346 2.08141 11.6235C2.00573 11.6125 1.9362 11.5772 1.88426 11.5234C1.19015 10.8045 0.659391 9.93887 0.348534 8.98076C0.301723 8.83651 0.365158 8.68036 0.501828 8.60482L1.69856 7.94356C1.57836 7.3198 1.57836 6.68025 1.69856 6.05649L0.501856 5.39523C0.365186 5.31972 0.301751 5.16356 0.348562 5.01929C0.659419 4.06117 1.19015 3.19558 1.88429 2.47666C1.93623 2.42283 2.00576 2.38751 2.08144 2.3765C2.15713 2.36549 2.23448 2.37944 2.30078 2.41607L3.49659 3.07722C3.99971 2.66455 4.57814 2.34439 5.20276 2.13287V0.810751C5.20276 0.737425 5.22883 0.666283 5.27669 0.609031C5.32455 0.551779 5.39134 0.511828 5.46608 0.495752C6.44768 0.284731 7.50192 0.273817 8.53306 0.495564C8.68722 0.528709 8.7971 0.659435 8.7971 0.810698V2.13284C9.42173 2.34435 10.0002 2.66451 10.5033 3.0772L11.6991 2.41604C11.7654 2.37941 11.8427 2.36546 11.9184 2.37647C11.9941 2.38748 12.0636 2.4228 12.1156 2.47663C12.8097 3.19556 13.3404 4.06115 13.6513 5.01926C13.6981 5.16351 13.6347 5.31966 13.498 5.3952L12.3013 6.05646C12.4215 6.68023 12.4215 7.31979 12.3013 7.94356ZM9.2464 7.00001C9.2464 5.81421 8.23863 4.84948 6.99992 4.84948C5.76121 4.84948 4.75344 5.81421 4.75344 7.00001C4.75344 8.18581 5.76121 9.15054 6.99992 9.15054C8.23863 9.15054 9.2464 8.18581 9.2464 7.00001Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const Copyright = props => (
  <IconWrapper {...props}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99992 0.333313C3.31801 0.333313 0.333252 3.31807 0.333252 6.99998C0.333252 10.6819 3.31801 13.6666 6.99992 13.6666C10.6818 13.6666 13.6666 10.6819 13.6666 6.99998C13.6666 3.31807 10.6818 0.333313 6.99992 0.333313ZM10.1487 9.65463C10.1059 9.70482 9.07943 10.884 7.1957 10.884C4.91903 10.884 3.31172 9.18342 3.31172 6.97087C3.31172 4.7853 4.97849 3.116 7.17629 3.116C8.97621 3.116 9.91728 4.11909 9.95645 4.16181C10.0047 4.21441 10.0342 4.28154 10.0402 4.35267C10.0462 4.42381 10.0285 4.49493 9.98973 4.5549L9.38812 5.48648C9.27927 5.65495 9.04473 5.68409 8.89796 5.54818C8.89169 5.54243 8.18481 4.90624 7.23451 4.90624C5.99484 4.90624 5.24753 5.8088 5.24753 6.95146C5.24753 8.01603 5.93339 9.09372 7.24422 9.09372C8.28446 9.09372 8.99906 8.33194 9.00615 8.32423C9.14411 8.17463 9.38409 8.18893 9.50368 8.35253L10.1635 9.25501C10.2064 9.31358 10.2282 9.38489 10.2255 9.4574C10.2228 9.52991 10.1957 9.5994 10.1487 9.65463Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const Undo = props => (
  <IconWrapper {...props}>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.290321 5.72395e-06H0.532256C0.692587 5.72395e-06 0.822578 0.129998 0.822578 0.290328V2.96674C1.86764 1.18663 3.80417 -0.00688942 6.01918 2.99273e-05C9.31886 0.0103606 12.0051 2.7099 12 6.00958C11.9948 9.3189 9.31049 12 6 12C4.4534 12 3.0435 11.4148 1.97956 10.4538C1.85586 10.3421 1.85003 10.1499 1.9679 10.0321L2.13892 9.86105C2.24787 9.75211 2.42317 9.747 2.53768 9.85007C3.4544 10.6751 4.66778 11.1774 6 11.1774C8.84864 11.1774 11.1774 8.87103 11.1774 6C11.1774 3.15136 8.87102 0.822586 6 0.822586C3.99527 0.822586 2.25636 1.96155 1.39623 3.62904H4.16129C4.32162 3.62904 4.45161 3.75903 4.45161 3.91936V4.16129C4.45161 4.32162 4.32162 4.45162 4.16129 4.45162H0.290321C0.129991 4.45162 -1.90735e-06 4.32162 -1.90735e-06 4.16129V0.290328C-1.90735e-06 0.129998 0.129991 5.72395e-06 0.290321 5.72395e-06Z"
        fill="#748AA1"
      />
    </svg>
  </IconWrapper>
);

const Revenue = props => (
  <IconWrapper {...props}>
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.35475 8.23491L4.45392 7.16511C4.00243 7.04324 3.6882 6.64715 3.6882 6.20704C3.6882 5.65522 4.16497 5.20834 4.7537 5.20834H7.14838C7.58903 5.20834 8.02246 5.3336 8.38365 5.56381C8.60397 5.70261 8.90015 5.66876 9.08797 5.4961L10.3449 4.34506C10.6013 4.11147 10.5652 3.72215 10.2799 3.51564C9.39498 2.86564 8.28974 2.5034 7.15561 2.50001V0.87501C7.15561 0.577094 6.89555 0.333344 6.57771 0.333344H5.4219C5.10406 0.333344 4.844 0.577094 4.844 0.87501V2.50001H4.7537C2.45293 2.50001 0.603645 4.35183 0.816747 6.54897C0.968446 8.10965 2.23983 9.37918 3.84351 9.81928L7.54569 10.8349C7.99718 10.9602 8.31141 11.3529 8.31141 11.793C8.31141 12.3448 7.83464 12.7917 7.2459 12.7917H4.85122C4.41057 12.7917 3.97715 12.6664 3.61596 12.4362C3.39563 12.2974 3.09946 12.3313 2.91164 12.5039L1.6547 13.655C1.39826 13.8886 1.43438 14.2779 1.71972 14.4844C2.60463 15.1344 3.70987 15.4966 4.844 15.5V17.125C4.844 17.4229 5.10406 17.6667 5.4219 17.6667H6.57771C6.89555 17.6667 7.15561 17.4229 7.15561 17.125V15.4932C8.83875 15.4628 10.4171 14.525 10.9734 13.032C11.7499 10.9466 10.446 8.80704 8.35475 8.23491Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const Clients = props => (
  <IconWrapper {...props}>
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.53337 7C7.20983 7 8.56671 5.64313 8.56671 3.96667C8.56671 2.29021 7.20983 0.933334 5.53337 0.933334C3.85692 0.933334 2.50004 2.29021 2.50004 3.96667C2.50004 5.64313 3.85692 7 5.53337 7ZM7.61337 7.86667H7.38858C6.82525 8.1375 6.19962 8.3 5.53337 8.3C4.86712 8.3 4.24421 8.1375 3.67817 7.86667H3.45337C1.73087 7.86667 0.333374 9.26417 0.333374 10.9867V11.7667C0.333374 12.4844 0.915666 13.0667 1.63337 13.0667H9.43337C10.1511 13.0667 10.7334 12.4844 10.7334 11.7667V10.9867C10.7334 9.26417 9.33587 7.86667 7.61337 7.86667ZM13.3334 7C14.7688 7 15.9334 5.83542 15.9334 4.4C15.9334 2.96458 14.7688 1.8 13.3334 1.8C11.898 1.8 10.7334 2.96458 10.7334 4.4C10.7334 5.83542 11.898 7 13.3334 7ZM14.6334 7.86667H14.5305C14.154 7.99667 13.7559 8.08333 13.3334 8.08333C12.9109 8.08333 12.5127 7.99667 12.1363 7.86667H12.0334C11.4809 7.86667 10.9717 8.02646 10.5248 8.28375C11.1857 8.99604 11.6 9.94125 11.6 10.9867V12.0267C11.6 12.0863 11.5865 12.1431 11.5838 12.2H16.3667C17.0844 12.2 17.6667 11.6177 17.6667 10.9C17.6667 9.22354 16.3098 7.86667 14.6334 7.86667Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const Conversations = props => (
  <IconWrapper {...props}>
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 0.0666656C4.21302 0.0666656 0.333328 2.94833 0.333328 6.50476C0.333328 8.04 1.05781 9.44524 2.26302 10.5502C1.83984 12.1102 0.424735 13.5 0.407807 13.5155C0.333328 13.5867 0.313016 13.6919 0.357026 13.7848C0.401037 13.8776 0.495828 13.9333 0.604162 13.9333C2.84869 13.9333 4.53125 12.949 5.36406 12.3424C6.47109 12.7231 7.7 12.9429 9 12.9429C13.787 12.9429 17.6667 10.0612 17.6667 6.50476C17.6667 2.94833 13.787 0.0666656 9 0.0666656ZM4.66666 7.49524C4.06744 7.49524 3.58333 7.05262 3.58333 6.50476C3.58333 5.9569 4.06744 5.51428 4.66666 5.51428C5.26588 5.51428 5.75 5.9569 5.75 6.50476C5.75 7.05262 5.26588 7.49524 4.66666 7.49524ZM9 7.49524C8.40078 7.49524 7.91666 7.05262 7.91666 6.50476C7.91666 5.9569 8.40078 5.51428 9 5.51428C9.59921 5.51428 10.0833 5.9569 10.0833 6.50476C10.0833 7.05262 9.59921 7.49524 9 7.49524ZM13.3333 7.49524C12.7341 7.49524 12.25 7.05262 12.25 6.50476C12.25 5.9569 12.7341 5.51428 13.3333 5.51428C13.9325 5.51428 14.4167 5.9569 14.4167 6.50476C14.4167 7.05262 13.9325 7.49524 13.3333 7.49524Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const Orders = props => (
  <IconWrapper {...props}>
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.77526 1.17808L0.380559 3.96217C0.255984 4.21133 0.388684 4.50924 0.654083 4.58507L6.01354 6.11524C6.20582 6.1694 6.41164 6.08816 6.51455 5.91754L9.00064 1.80098L2.22482 0.937044C2.03795 0.915378 1.85922 1.01017 1.77526 1.17808ZM17.6207 3.96217L16.226 1.17808C16.1421 1.01017 15.9606 0.912669 15.7738 0.937044L9.00064 1.80098L11.484 5.92024C11.5869 6.09086 11.7928 6.17211 11.985 6.11795L17.3445 4.58778C17.6126 4.50924 17.7426 4.21133 17.6207 3.96217ZM11.8632 7.00084C11.4055 7.00084 10.9749 6.7571 10.742 6.36711L9.00064 3.4801L7.262 6.36711C7.02639 6.7598 6.59579 7.00355 6.13811 7.00355C6.01625 7.00355 5.89438 6.9873 5.77793 6.95209L2.06774 5.89045V10.7112C2.06774 11.1093 2.33856 11.4559 2.72312 11.5507L8.57817 13.0159C8.8544 13.0836 9.14417 13.0836 9.4177 13.0159L15.2782 11.5507C15.6627 11.4532 15.9335 11.1066 15.9335 10.7112V5.89045L12.2234 6.94938C12.1069 6.98459 11.985 7.00084 11.8632 7.00084Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const Dashboard = props => (
  <IconWrapper {...props}>
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.4041 10.125H10.6802L16.4901 15.6808C16.7122 15.8931 17.0776 15.9104 17.3059 15.7047C18.7287 14.4229 19.7074 12.6949 19.9945 10.7525C20.0438 10.4199 19.7552 10.125 19.4041 10.125ZM18.8221 7.84683C18.5191 3.64701 15.0096 0.290962 10.6177 0.00127127C10.2824 -0.0208774 10 0.249477 10 0.570809V8.43746H18.2269C18.5629 8.43746 18.8452 8.16745 18.8221 7.84683ZM8.23535 10.125V1.78266C8.23535 1.44691 7.92689 1.17093 7.57947 1.21804C3.19823 1.81008 -0.150654 5.47024 0.00522739 9.85673C0.165521 14.3617 4.22176 18.056 8.93498 17.9994C10.7879 17.9772 12.5 17.4063 13.9078 16.4514C14.1982 16.2545 14.2173 15.8457 13.9655 15.6048L8.23535 10.125Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const Products = props => (
  <IconWrapper {...props}>
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5607 7.06065L8.93935 0.439344C8.65804 0.158039 8.27651 2.08024e-06 7.87869 0H1.5C0.671563 0 0 0.671562 0 1.5V7.87869C2.08024e-06 8.27651 0.158039 8.65804 0.439344 8.93934L7.06066 15.5607C7.64641 16.1464 8.59616 16.1465 9.18197 15.5607L15.5607 9.18197C16.1464 8.59619 16.1464 7.64644 15.5607 7.06065ZM3.5 5C2.67156 5 2 4.32844 2 3.5C2 2.67156 2.67156 2 3.5 2C4.32844 2 5 2.67156 5 3.5C5 4.32844 4.32844 5 3.5 5ZM19.5607 9.18197L13.182 15.5607C12.5962 16.1464 11.6464 16.1464 11.0607 15.5607L11.0494 15.5494L16.4888 10.1101C17.02 9.57884 17.3125 8.87256 17.3125 8.12131C17.3125 7.37006 17.0199 6.66378 16.4888 6.13256L10.3562 0H11.8787C12.2765 2.08024e-06 12.658 0.158039 12.9393 0.439344L19.5607 7.06065C20.1464 7.64644 20.1464 8.59619 19.5607 9.18197Z"
        fill="#E9EAF1"
      />
    </svg>
  </IconWrapper>
);

const Categories = props => (
  <IconWrapper {...props}>
    <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.8852 7.7219L17.3705 11.9251C17.1748 12.2522 16.8946 12.5235 16.5578 12.7122C16.221 12.9008 15.8394 13 15.451 13H1.56337C0.920209 13 0.519479 12.3198 0.843542 11.7781L3.35826 7.57494C3.55397 7.24784 3.83418 6.97646 4.17098 6.78785C4.50777 6.59925 4.88938 6.5 5.27778 6.5H19.1654C19.8086 6.5 20.2093 7.18023 19.8852 7.7219ZM5.27778 5.41667H16.6667V3.79167C16.6667 2.89419 15.9205 2.16667 15 2.16667H9.44445L7.22222 0H1.66667C0.746181 0 0 0.727526 0 1.625V11.038L2.39851 7.02907C2.99354 6.0345 4.09684 5.41667 5.27778 5.41667Z"
        fill="#E9EAF1"
      />
    </svg>
  </IconWrapper>
);

const Chatbot = props => (
  <IconWrapper {...props}>
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 8V12C0 12.5531 0.446875 13 1 13H2V7H1C0.446875 7 0 7.44688 0 8ZM14.5 3H11V1C11 0.446875 10.5531 0 10 0C9.44687 0 9 0.446875 9 1V3H5.5C4.11875 3 3 4.11875 3 5.5V14C3 15.1031 3.89687 16 5 16H15C16.1031 16 17 15.1031 17 14V5.5C17 4.11875 15.8813 3 14.5 3ZM8 13H6V12H8V13ZM7 9.25C6.30937 9.25 5.75 8.69063 5.75 8C5.75 7.30937 6.30937 6.75 7 6.75C7.69063 6.75 8.25 7.30937 8.25 8C8.25 8.69063 7.69063 9.25 7 9.25ZM11 13H9V12H11V13ZM14 13H12V12H14V13ZM13 9.25C12.3094 9.25 11.75 8.69063 11.75 8C11.75 7.30937 12.3094 6.75 13 6.75C13.6906 6.75 14.25 7.30937 14.25 8C14.25 8.69063 13.6906 9.25 13 9.25ZM19 7H18V13H19C19.5531 13 20 12.5531 20 12V8C20 7.44688 19.5531 7 19 7Z"
        fill="#E9EAF1"
      />
    </svg>
  </IconWrapper>
);

const Logout = props => (
  <IconWrapper {...props}>
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.03091 16H3.74982C1.67961 16 0 14.2083 0 12V4C0 1.79167 1.67961 0 3.74982 0H7.03091C7.28871 0 7.49963 0.225 7.49963 0.5V2.16667C7.49963 2.44167 7.28871 2.66667 7.03091 2.66667H3.74982C3.05844 2.66667 2.49988 3.2625 2.49988 4V12C2.49988 12.7375 3.05844 13.3333 3.74982 13.3333H7.03091C7.28871 13.3333 7.49963 13.5583 7.49963 13.8333V15.5C7.49963 15.775 7.28871 16 7.03091 16ZM11.6362 3.37083L14.6673 6.33333H7.18715C6.66764 6.33333 6.24969 6.77917 6.24969 7.33333V8.66667C6.24969 9.22083 6.66764 9.66667 7.18715 9.66667H14.6673L11.6362 12.6292C11.2416 13.0125 11.2299 13.6708 11.6049 14.075L12.4603 14.9875C12.8236 15.375 13.4173 15.3792 13.7845 14.9917L19.7217 8.70833C20.0928 8.31667 20.0928 7.67917 19.7217 7.2875L13.7884 1.0125C13.4212 0.625 12.8314 0.625 12.4642 1.01667L11.6088 1.92917C11.2299 2.32917 11.2455 2.9875 11.6362 3.37083Z"
        fill="#E9EAF1"
      />
    </svg>
  </IconWrapper>
);

const Back = props => (
  <IconWrapper {...props}>
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.24975 0.297012C7.65014 -0.0990038 8.29931 -0.0990038 8.6997 0.297012C9.10009 0.693027 9.10009 1.33509 8.6997 1.73111L1.75024 8.60459C1.34985 9.00061 0.700687 9.00061 0.300294 8.60459C-0.100098 8.20858 -0.100098 7.56651 0.300294 7.17049L7.24975 0.297012Z"
        fill="#2C98F0"
      />
      <path
        d="M0.3003 8.82951C-0.100093 8.43349 -0.100093 7.79142 0.3003 7.39541C0.700692 6.99939 1.34986 6.99939 1.75025 7.39541L8.69971 14.2689C9.1001 14.6649 9.1001 15.307 8.69971 15.703C8.29931 16.099 7.65015 16.099 7.24975 15.703L0.3003 8.82951Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const Hamburger = props => (
  <IconWrapper {...props}>
    <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.1071 12.3578H0.892857C0.401786 12.3578 0 12.7541 0 13.2385V14.1193C0 14.6037 0.401786 15 0.892857 15H24.1071C24.5982 15 25 14.6037 25 14.1193V13.2385C25 12.7541 24.5982 12.3578 24.1071 12.3578ZM24.1071 6.19266H0.892857C0.401786 6.19266 0 6.58899 0 7.0734V7.95413C0 8.43853 0.401786 8.83486 0.892857 8.83486H24.1071C24.5982 8.83486 25 8.43853 25 7.95413V7.0734C25 6.58899 24.5982 6.19266 24.1071 6.19266Z"
        fill="#2C98F0"
      />
      <path
        d="M0.892857 0H24.1071C24.5982 0 25 0.39633 25 0.880734V1.76147C25 2.24587 24.5982 2.6422 24.1071 2.6422H0.892857C0.401786 2.6422 0 2.24587 0 1.76147V0.880734C0 0.39633 0.401786 0 0.892857 0Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const BackArrow = props => (
  <IconWrapper {...props}>
    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.183565 8.55744L7.8282 0.850313C8.07296 0.60556 8.46873 0.60556 8.71348 0.850313L9.74457 1.8814C9.98932 2.12616 9.98932 2.52193 9.74457 2.76668L3.57366 9.00008L9.74457 15.2335C9.98932 15.4782 9.98932 15.874 9.74457 16.1188L8.71348 17.1499C8.46873 17.3946 8.07296 17.3946 7.8282 17.1499L0.183565 9.44272C-0.0611884 9.19797 -0.0611884 8.8022 0.183565 8.55744Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const ForwardArrow = props => (
  <IconWrapper {...props}>
    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.74457 9.44272L2.09993 17.1499C1.85518 17.3946 1.45941 17.3946 1.21465 17.1499L0.183565 16.1188C-0.0611884 15.874 -0.0611884 15.4782 0.183565 15.2335L6.35448 9.00008L0.183565 2.76668C-0.0611884 2.52193 -0.0611884 2.12616 0.183565 1.8814L1.21465 0.850313C1.45941 0.60556 1.85518 0.60556 2.09993 0.850313L9.74457 8.55744C9.98932 8.8022 9.98932 9.19797 9.74457 9.44272Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const Close = props => (
  <IconWrapper {...props}>
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.2145 15.509L26.7163 16.0109C26.9923 16.2869 26.9923 16.7385 26.7163 17.0145L22.6181 21.1127L26.7163 25.2108C26.9923 25.4868 26.9923 25.9385 26.7163 26.2145L26.2145 26.7163C25.9385 26.9923 25.4868 26.9923 25.2108 26.7163L21.1126 22.6181L17.0145 26.7163C16.7385 26.9923 16.2868 26.9923 16.0108 26.7163L15.509 26.2145C15.233 25.9385 15.233 25.4868 15.509 25.2108L19.6072 21.1127L15.509 17.0145C15.233 16.7385 15.233 16.2869 15.509 16.0109L16.0108 15.509C16.2868 15.233 16.7385 15.233 17.0145 15.509L21.1126 19.6072L25.2108 15.509C25.4868 15.233 25.9385 15.233 26.2145 15.509ZM31.4835 10.7418C37.2126 16.4709 37.2126 25.7545 31.4835 31.4836C25.7545 37.2127 16.4708 37.2127 10.7417 31.4836C5.01266 25.7545 5.01266 16.4709 10.7417 10.7418C16.4708 5.01269 25.7545 5.01269 31.4835 10.7418ZM30.1454 12.08C25.1314 7.06596 17.0437 7.11614 12.0799 12.08C7.06593 17.0939 7.11612 25.1816 12.0799 30.1454C17.0939 35.1594 25.1816 35.1092 30.1454 30.1454C35.1594 25.1314 35.1092 17.0438 30.1454 12.08Z"
        fill="white"
      />
    </svg>
  </IconWrapper>
);

const Search = props => (
  <IconWrapper {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8898 15.0493L11.8588 11.0182C11.7869 10.9463 11.6932 10.9088 11.5932 10.9088H11.2713C12.3431 9.74952 12.9994 8.20272 12.9994 6.49968C12.9994 2.90923 10.0901 0 6.49968 0C2.90923 0 0 2.90923 0 6.49968C0 10.0901 2.90923 12.9994 6.49968 12.9994C8.20272 12.9994 9.74952 12.3431 10.9088 11.2744V11.5932C10.9088 11.6932 10.9495 11.7869 11.0182 11.8588L15.0493 15.8898C15.1961 16.0367 15.4336 16.0367 15.5805 15.8898L15.8898 15.5805C16.0367 15.4336 16.0367 15.1961 15.8898 15.0493ZM6.49968 11.9994C3.45921 11.9994 0.999951 9.54016 0.999951 6.49968C0.999951 3.45921 3.45921 0.999951 6.49968 0.999951C9.54016 0.999951 11.9994 3.45921 11.9994 6.49968C11.9994 9.54016 9.54016 11.9994 6.49968 11.9994Z"
        fill="#748AA1"
      />
    </svg>
  </IconWrapper>
);

const Forward = props => (
  <IconWrapper {...props}>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.9"
        d="M5 0C7.7621 0 10 2.2379 10 5C10 7.7621 7.7621 10 5 10C2.2379 10 0 7.7621 0 5C0 2.2379 2.2379 0 5 0ZM7.29637 4.65726L4.56452 1.9254C4.375 1.73589 4.06855 1.73589 3.88105 1.9254L3.53831 2.26815C3.34879 2.45766 3.34879 2.76411 3.53831 2.95161L5.58669 5L3.53831 7.04839C3.34879 7.2379 3.34879 7.54435 3.53831 7.73185L3.88105 8.0746C4.07056 8.26411 4.37702 8.26411 4.56452 8.0746L7.29637 5.34274C7.48589 5.15323 7.48589 4.84677 7.29637 4.65726Z"
        fill="#E9EAF1"
      />
    </svg>
  </IconWrapper>
);

const Plus = props => (
  <IconWrapper {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5714 7.85714H12.1429V1.42857C12.1429 0.639732 11.5031 0 10.7143 0H9.28571C8.49687 0 7.85714 0.639732 7.85714 1.42857V7.85714H1.42857C0.639732 7.85714 0 8.49687 0 9.28571V10.7143C0 11.5031 0.639732 12.1429 1.42857 12.1429H7.85714V18.5714C7.85714 19.3603 8.49687 20 9.28571 20H10.7143C11.5031 20 12.1429 19.3603 12.1429 18.5714V12.1429H18.5714C19.3603 12.1429 20 11.5031 20 10.7143V9.28571C20 8.49687 19.3603 7.85714 18.5714 7.85714Z"
        fill="#E9EAF1"
      />
    </svg>
  </IconWrapper>
);

const Notification = props => (
  <IconWrapper {...props}>
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00004 16C8.05123 16 8.9039 15.1047 8.9039 14H5.09618C5.09618 15.1047 5.94885 16 7.00004 16ZM13.4104 11.3216C12.8354 10.6728 11.7596 9.69687 11.7596 6.5C11.7596 4.07188 10.1381 2.12812 7.95182 1.65125V1C7.95182 0.447812 7.52563 0 7.00004 0C6.47445 0 6.04826 0.447812 6.04826 1V1.65125C3.86196 2.12812 2.24053 4.07188 2.24053 6.5C2.24053 9.69687 1.16464 10.6728 0.589644 11.3216C0.411073 11.5231 0.331907 11.7641 0.333395 12C0.336668 12.5125 0.719703 13 1.28875 13H12.7113C13.2804 13 13.6637 12.5125 13.6667 12C13.6682 11.7641 13.589 11.5228 13.4104 11.3216Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const ProductNotificationIcon = props => (
  <IconWrapper {...props}>
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      margin="0px"
    >
      <path
        d="M1.44252 0.521578L0.36967 2.66319C0.273843 2.85485 0.37592 3.08401 0.580074 3.14234L4.70273 4.31939C4.85064 4.36106 5.00896 4.29856 5.08812 4.16731L7.0005 1.00073L1.78833 0.336166C1.64459 0.3195 1.5071 0.392415 1.44252 0.521578ZM13.6313 2.66319L12.5585 0.521578C12.4939 0.392415 12.3543 0.317417 12.2106 0.336166L7.0005 1.00073L8.9108 4.1694C8.98996 4.30064 9.14828 4.36314 9.29619 4.32148L13.4188 3.14442C13.6251 3.08401 13.7251 2.85485 13.6313 2.66319ZM9.20245 5.00063C8.85039 5.00063 8.51916 4.81313 8.34 4.51314L7.0005 2.29236L5.66309 4.51314C5.48185 4.81521 5.15062 5.00271 4.79856 5.00271C4.70481 5.00271 4.61107 4.99021 4.52149 4.96313L1.66751 4.14648V7.85472C1.66751 8.16096 1.87583 8.42762 2.17164 8.50053L6.67552 9.62758C6.88801 9.67967 7.11091 9.67967 7.32131 9.62758L11.8294 8.50053C12.1252 8.42553 12.3335 8.15887 12.3335 7.85472V4.14648L9.47951 4.96104C9.38993 4.98813 9.29619 5.00063 9.20245 5.00063Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const ClientNotificationIcon = props => (
  <IconWrapper {...props}>
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.33337 4.99998C5.62296 4.99998 6.66671 3.95623 6.66671 2.66665C6.66671 1.37706 5.62296 0.333313 4.33337 0.333313C3.04379 0.333313 2.00004 1.37706 2.00004 2.66665C2.00004 3.95623 3.04379 4.99998 4.33337 4.99998ZM5.93337 5.66665H5.76046C5.32712 5.87498 4.84587 5.99998 4.33337 5.99998C3.82087 5.99998 3.34171 5.87498 2.90629 5.66665H2.73337C1.40837 5.66665 0.333374 6.74165 0.333374 8.06665V8.66665C0.333374 9.21873 0.781291 9.66665 1.33337 9.66665H7.33337C7.88546 9.66665 8.33337 9.21873 8.33337 8.66665V8.06665C8.33337 6.74165 7.25837 5.66665 5.93337 5.66665ZM10.3334 4.99998C11.4375 4.99998 12.3334 4.10415 12.3334 2.99998C12.3334 1.89581 11.4375 0.99998 10.3334 0.99998C9.22921 0.99998 8.33337 1.89581 8.33337 2.99998C8.33337 4.10415 9.22921 4.99998 10.3334 4.99998ZM11.3334 5.66665H11.2542C10.9646 5.76665 10.6584 5.83331 10.3334 5.83331C10.0084 5.83331 9.70212 5.76665 9.41254 5.66665H9.33337C8.90837 5.66665 8.51671 5.78956 8.17296 5.98748C8.68129 6.5354 9.00004 7.26248 9.00004 8.06665V8.86665C9.00004 8.91248 8.98962 8.95623 8.98754 8.99998H12.6667C13.2188 8.99998 13.6667 8.55206 13.6667 7.99998C13.6667 6.7104 12.623 5.66665 11.3334 5.66665Z"
        fill="#E9EAF1"
      />
    </svg>
  </IconWrapper>
);

const SeeAllNotificationIcon = props => (
  <IconWrapper {...props}>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.9"
        d="M5 0C7.7621 0 10 2.2379 10 5C10 7.7621 7.7621 10 5 10C2.2379 10 0 7.7621 0 5C0 2.2379 2.2379 0 5 0ZM7.29637 4.65726L4.56452 1.9254C4.375 1.73589 4.06855 1.73589 3.88105 1.9254L3.53831 2.26815C3.34879 2.45766 3.34879 2.76411 3.53831 2.95161L5.58669 5L3.53831 7.04839C3.34879 7.2379 3.34879 7.54435 3.53831 7.73185L3.88105 8.0746C4.07056 8.26411 4.37702 8.26411 4.56452 8.0746L7.29637 5.34274C7.48589 5.15323 7.48589 4.84677 7.29637 4.65726Z"
        fill="#E9EAF1"
      />
    </svg>
  </IconWrapper>
);

const CalendarDownArrow = props => (
  <IconWrapper {...props}>
    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.35248 0.25093C6.77304 0.250923 7.00572 0.738542 6.74116 1.06547L3.85632 4.63023C3.6562 4.87751 3.27908 4.87751 3.07897 4.63023L0.194235 1.06555C-0.0703254 0.73863 0.162345 0.251023 0.582903 0.251016L6.35248 0.25093Z"
        fill="#B8C5D3"
      />
    </svg>
  </IconWrapper>
);

const Cancel = props => (
  <IconWrapper {...props}>
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 0C3.7995 0 0 3.7995 0 8.5C0 13.2005 3.7995 17 8.5 17C13.2005 17 17 13.2005 17 8.5C17 3.7995 13.2005 0 8.5 0ZM12.75 11.5515L11.5515 12.75L8.5 9.6985L5.4485 12.75L4.25 11.5515L7.3015 8.5L4.25 5.4485L5.4485 4.25L8.5 7.3015L11.5515 4.25L12.75 5.4485L9.6985 8.5L12.75 11.5515Z"
        fill="#F2453D"
      />
    </svg>
  </IconWrapper>
);

const BackProducts = props => (
  <IconWrapper {...props}>
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.212138 6.45579L6.07895 0.2253C6.3618 -0.0750999 6.82064 -0.0750999 7.10349 0.2253L7.78782 0.952094C8.07037 1.25217 8.07067 1.7382 7.78903 2.03892L3.13935 6.99984L7.78873 11.9611C8.07067 12.2618 8.07007 12.7478 7.78752 13.0479L7.10319 13.7747C6.82034 14.0751 6.3615 14.0751 6.07865 13.7747L0.212138 7.54389C-0.0707126 7.24349 -0.0707126 6.75619 0.212138 6.45579Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
);

const Loading = props =>{
  const {width, height, fill} = props;
  return (

      <svg width={width} height={height} viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="4" r="4" fill={fill}/>
        <circle opacity="0.6" cx="17" cy="4" r="4" fill={fill}/>
        <circle opacity="0.3" cx="4" cy="4" r="4" fill={fill}/>
      </svg>

  );
}

const WarningIcon = props => (
  <IconWrapper {...props}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 0C3.13428 0 0 3.13541 0 7C0 10.8668 3.13428 14 7 14C10.8657 14 14 10.8668 14 7C14 3.13541 10.8657 0 7 0ZM7 3.10484C7.65473 3.10484 8.18548 3.6356 8.18548 4.29032C8.18548 4.94505 7.65473 5.47581 7 5.47581C6.34527 5.47581 5.81452 4.94505 5.81452 4.29032C5.81452 3.6356 6.34527 3.10484 7 3.10484ZM8.58064 10.2742C8.58064 10.4612 8.42899 10.6129 8.24193 10.6129H5.75806C5.57101 10.6129 5.41935 10.4612 5.41935 10.2742V9.59677C5.41935 9.40972 5.57101 9.25806 5.75806 9.25806H6.09677V7.45161H5.75806C5.57101 7.45161 5.41935 7.29996 5.41935 7.1129V6.43548C5.41935 6.24843 5.57101 6.09677 5.75806 6.09677H7.56452C7.75157 6.09677 7.90323 6.24843 7.90323 6.43548V9.25806H8.24193C8.42899 9.25806 8.58064 9.40972 8.58064 9.59677V10.2742Z"
        fill="#FF9800"
      />
    </svg>
  </IconWrapper>
);

const SuccessIcon = props => (
  <IconWrapper {...props}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 7C14 10.866 10.866 14 7 14C3.134 14 0 10.866 0 7C0 3.134 3.134 0 7 0C10.866 0 14 3.134 14 7ZM6.19031 10.7064L11.3839 5.51289C11.5602 5.33654 11.5602 5.05058 11.3839 4.87423L10.7452 4.23556C10.5688 4.05918 10.2829 4.05918 10.1065 4.23556L5.87097 8.47107L3.8935 6.4936C3.71714 6.31725 3.43119 6.31725 3.2548 6.4936L2.61614 7.13227C2.43978 7.30862 2.43978 7.59458 2.61614 7.77093L5.55162 10.7064C5.728 10.8828 6.01393 10.8828 6.19031 10.7064Z"
        fill="#4CAF50"
      />
    </svg>
  </IconWrapper>
);

const ErrorIcon = props => (
  <IconWrapper {...props}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 0C3.129 0 0 3.129 0 7C0 10.871 3.129 14 7 14C10.871 14 14 10.871 14 7C14 3.129 10.871 0 7 0ZM10.5 9.513L9.513 10.5L7 7.987L4.487 10.5L3.5 9.513L6.013 7L3.5 4.487L4.487 3.5L7 6.013L9.513 3.5L10.5 4.487L7.987 7L10.5 9.513Z"
        fill="#F2453D"
      />
    </svg>
  </IconWrapper>
)

const FilterIcon = props => (
  <IconWrapper {...props}>
    <svg width="18" height="18" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
      <path stroke={colors.blue} d="M14.2493 3H3.75095C3.08474 3 2.7486 3.80829 3.22061 4.28032L7.50007 8.56067V12.9375C7.50006 13.0444 7.52291 13.1501 7.56709 13.2474C7.61126 13.3448 7.67574 13.4315 7.75619 13.5019L9.2562 14.814C9.73521 15.2332 10.5001 14.9003 10.5001 14.2496V8.56067L14.7796 4.28032C15.2507 3.80925 14.9169 3 14.2493 3ZM9.75007 8.25001V14.25L8.25007 12.9375V8.25001L3.75006 3.75H14.2501L9.75007 8.25001Z" fill="#2C98F0"/>
    </svg>
  </IconWrapper>
)

const XIcon = props => (
  <IconWrapper {...props}>
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 0C3.7995 0 0 3.7995 0 8.5C0 13.2005 3.7995 17 8.5 17C13.2005 17 17 13.2005 17 8.5C17 3.7995 13.2005 0 8.5 0ZM12.75 11.5515L11.5515 12.75L8.5 9.6985L5.4485 12.75L4.25 11.5515L7.3015 8.5L4.25 5.4485L5.4485 4.25L8.5 7.3015L11.5515 4.25L12.75 5.4485L9.6985 8.5L12.75 11.5515Z" fill="#F2453D"/>
    </svg>
  </IconWrapper>
)

const Tags = props => (
  <IconWrapper {...props}>
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3375 6.28744L7.17289 1.26627C6.95347 1.05295 6.65588 0.933107 6.34557 0.933105H1.3702C0.724014 0.933105 0.200195 1.44237 0.200195 2.07061V6.90778C0.200197 7.20946 0.323466 7.49878 0.542884 7.71211L5.70751 12.7333C6.16439 13.1775 6.9052 13.1775 7.36213 12.7333L12.3375 7.8961C12.7944 7.45188 12.7944 6.73165 12.3375 6.28744ZM2.9302 4.72477C2.28401 4.72477 1.7602 4.2155 1.7602 3.58727C1.7602 2.95904 2.28401 2.44977 2.9302 2.44977C3.57638 2.44977 4.1002 2.95904 4.1002 3.58727C4.1002 4.2155 3.57638 4.72477 2.9302 4.72477ZM15.4575 7.8961L10.4821 12.7333C10.0252 13.1775 9.28442 13.1775 8.82751 12.7333L8.81874 12.7247L13.0614 8.5999C13.4758 8.19706 13.7039 7.66146 13.7039 7.09177C13.7039 6.52207 13.4758 5.98647 13.0614 5.58363L8.278 0.933105H9.46558C9.77588 0.933107 10.0735 1.05295 10.2929 1.26627L15.4575 6.28744C15.9144 6.73165 15.9144 7.45188 15.4575 7.8961Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
)

const Location = props => (
  <IconWrapper {...props}>
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.0002 0.0666504C6.11547 0.0666504 4.5877 1.59442 4.5877 3.47915C4.5877 5.00286 6.81801 7.77998 7.67249 8.78802C7.84555 8.99223 8.15511 8.99223 8.3279 8.78802C9.18238 7.77998 11.4127 5.00286 11.4127 3.47915C11.4127 1.59442 9.88492 0.0666504 8.0002 0.0666504ZM8.0002 4.61665C7.37186 4.61665 6.8627 4.10748 6.8627 3.47915C6.8627 2.85082 7.37186 2.34165 8.0002 2.34165C8.62853 2.34165 9.1377 2.85082 9.1377 3.47915C9.1377 4.10748 8.62853 4.61665 8.0002 4.61665ZM0.745112 5.9153C0.584285 5.97962 0.446418 6.09066 0.349292 6.23408C0.252165 6.3775 0.200234 6.54673 0.200195 6.71994L0.200195 13.4994C0.200195 13.806 0.509758 14.0157 0.794404 13.9019L4.53353 12.2V5.8874C4.29411 5.45461 4.0983 5.03319 3.95801 4.63019L0.745112 5.9153ZM8.0002 9.80771C7.61913 9.80771 7.25865 9.64034 7.01138 9.34838C6.47892 8.72005 5.91261 8.0045 5.4002 7.27055V12.1997L10.6002 13.933V7.27082C10.0878 8.0045 9.52174 8.72032 8.98901 9.34865C8.74174 9.64034 8.38126 9.80771 8.0002 9.80771ZM15.206 4.4314L11.4669 6.13332V13.9333L15.2553 12.418C15.4161 12.3537 15.554 12.2427 15.6511 12.0993C15.7483 11.9558 15.8002 11.7866 15.8002 11.6134V4.83386C15.8002 4.52728 15.4906 4.31765 15.206 4.4314Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
)

const Card = props => (
  <IconWrapper {...props}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7751 0.93335H1.22437C0.583999 0.93335 0.0664062 1.51564 0.0664062 2.23335V11.7667C0.0664062 12.4844 0.583999 13.0667 1.22437 13.0667H12.7751C13.4155 13.0667 13.9331 12.4844 13.9331 11.7667V2.23335C13.9331 1.51564 13.4155 0.93335 12.7751 0.93335ZM1.36881 2.23335H12.6307C12.7101 2.23335 12.7751 2.30647 12.7751 2.39585V3.53335H1.22437V2.39585C1.22437 2.30647 1.28937 2.23335 1.36881 2.23335ZM12.6307 11.7667H1.36881C1.28937 11.7667 1.22437 11.6936 1.22437 11.6042V7.00002H12.7751V11.6042C12.7751 11.6936 12.7101 11.7667 12.6307 11.7667ZM4.68863 9.05835V10.1417C4.68863 10.3204 4.55863 10.4667 4.39974 10.4667H2.66641C2.50752 10.4667 2.37752 10.3204 2.37752 10.1417V9.05835C2.37752 8.8796 2.50752 8.73335 2.66641 8.73335H4.39974C4.55863 8.73335 4.68863 8.8796 4.68863 9.05835ZM9.31085 9.05835V10.1417C9.31085 10.3204 9.18085 10.4667 9.02196 10.4667H5.74789C5.589 10.4667 5.459 10.3204 5.459 10.1417V9.05835C5.459 8.8796 5.589 8.73335 5.74789 8.73335H9.02196C9.18085 8.73335 9.31085 8.8796 9.31085 9.05835Z"
        fill="#2C98F0"
      />
    </svg>
  </IconWrapper>
)

const Pick = props => (
  <IconWrapper {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 6.5625C8.10156 6.5625 6.5625 8.10156 6.5625 10C6.5625 11.8984 8.10156 13.4375 10 13.4375C11.8984 13.4375 13.4375 11.8984 13.4375 10C13.4375 8.10156 11.8984 6.5625 10 6.5625ZM10 11.5625C9.13828 11.5625 8.4375 10.8617 8.4375 10C8.4375 9.13828 9.13828 8.4375 10 8.4375C10.8617 8.4375 11.5625 9.13828 11.5625 10C11.5625 10.8617 10.8617 11.5625 10 11.5625ZM19.375 9.0625H17.4352C17.0113 5.67148 14.3285 2.98867 10.9375 2.56484V0.625C10.9375 0.279687 10.6578 0 10.3125 0H9.6875C9.34219 0 9.0625 0.279687 9.0625 0.625V2.56484C5.67148 2.98867 2.98867 5.67148 2.56484 9.0625H0.625C0.279687 9.0625 0 9.34219 0 9.6875V10.3125C0 10.6578 0.279687 10.9375 0.625 10.9375H2.56484C2.98867 14.3285 5.67148 17.0113 9.0625 17.4352V19.375C9.0625 19.7203 9.34219 20 9.6875 20H10.3125C10.6578 20 10.9375 19.7203 10.9375 19.375V17.4352C14.3285 17.0113 17.0113 14.3281 17.4352 10.9375H19.375C19.7203 10.9375 20 10.6578 20 10.3125V9.6875C20 9.34219 19.7203 9.0625 19.375 9.0625ZM10 15.625C6.89844 15.625 4.375 13.1016 4.375 10C4.375 6.89844 6.89844 4.375 10 4.375C13.1016 4.375 15.625 6.89844 15.625 10C15.625 13.1016 13.1016 15.625 10 15.625Z"
        fill="white"
      />
    </svg>
  </IconWrapper>
)

const Add = props => (
  <IconWrapper {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1613 9.35484V10.6452C15.1613 10.9113 14.9435 11.129 14.6774 11.129H11.129V14.6774C11.129 14.9435 10.9113 15.1613 10.6452 15.1613H9.35484C9.08871 15.1613 8.87097 14.9435 8.87097 14.6774V11.129H5.32258C5.05645 11.129 4.83871 10.9113 4.83871 10.6452V9.35484C4.83871 9.08871 5.05645 8.87097 5.32258 8.87097H8.87097V5.32258C8.87097 5.05645 9.08871 4.83871 9.35484 4.83871H10.6452C10.9113 4.83871 11.129 5.05645 11.129 5.32258V8.87097H14.6774C14.9435 8.87097 15.1613 9.08871 15.1613 9.35484ZM20 10C20 15.5242 15.5242 20 10 20C4.47581 20 0 15.5242 0 10C0 4.47581 4.47581 0 10 0C15.5242 0 20 4.47581 20 10ZM18.0645 10C18.0645 5.54435 14.4556 1.93548 10 1.93548C5.54435 1.93548 1.93548 5.54435 1.93548 10C1.93548 14.4556 5.54435 18.0645 10 18.0645C14.4556 18.0645 18.0645 14.4556 18.0645 10Z"
        fill="#F1BF3C"
      />
    </svg>
  </IconWrapper>
)

const Remove = props => (
  <IconWrapper {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.32258 11.129C5.05645 11.129 4.83871 10.9113 4.83871 10.6452V9.35484C4.83871 9.08871 5.05645 8.87097 5.32258 8.87097H14.6774C14.9435 8.87097 15.1613 9.08871 15.1613 9.35484V10.6452C15.1613 10.9113 14.9435 11.129 14.6774 11.129H5.32258ZM20 10C20 15.5242 15.5242 20 10 20C4.47581 20 0 15.5242 0 10C0 4.47581 4.47581 0 10 0C15.5242 0 20 4.47581 20 10ZM18.0645 10C18.0645 5.54435 14.4556 1.93548 10 1.93548C5.54435 1.93548 1.93548 5.54435 1.93548 10C1.93548 14.4556 5.54435 18.0645 10 18.0645C14.4556 18.0645 18.0645 14.4556 18.0645 10Z"
        fill="#9092A1"
      />
    </svg>
  </IconWrapper>
)

const Info = props => (
  <IconWrapper {...props}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 0C4.02979 0 0 4.03124 0 9C0 13.9717 4.02979 18 9 18C13.9702 18 18 13.9717 18 9C18 4.03124 13.9702 0 9 0ZM9 3.99194C9.84179 3.99194 10.5242 4.67434 10.5242 5.51613C10.5242 6.35792 9.84179 7.04032 9 7.04032C8.15821 7.04032 7.47581 6.35792 7.47581 5.51613C7.47581 4.67434 8.15821 3.99194 9 3.99194ZM11.0323 13.2097C11.0323 13.4502 10.8373 13.6452 10.5968 13.6452H7.40323C7.16273 13.6452 6.96774 13.4502 6.96774 13.2097V12.3387C6.96774 12.0982 7.16273 11.9032 7.40323 11.9032H7.83871V9.58064H7.40323C7.16273 9.58064 6.96774 9.38566 6.96774 9.14516V8.27419C6.96774 8.0337 7.16273 7.83871 7.40323 7.83871H9.72581C9.9663 7.83871 10.1613 8.0337 10.1613 8.27419V11.9032H10.5968C10.8373 11.9032 11.0323 12.0982 11.0323 12.3387V13.2097Z"
        fill="white"/>
    </svg>
  </IconWrapper>
)

export default {
  Settings,
  Copyright,
  Undo,
  Revenue,
  Clients,
  Conversations,
  Orders,
  Back,
  Hamburger,
  BackArrow,
  ForwardArrow,
  Close,
  Dashboard,
  Products,
  Categories,
  Loading,
  Chatbot,
  Logout,
  Search,
  Forward,
  Plus,
  Notification,
  ProductNotificationIcon,
  SeeAllNotificationIcon,
  ClientNotificationIcon,
  CalendarDownArrow,
  Cancel,
  BackProducts,
  WarningIcon,
  SuccessIcon,
  ErrorIcon,
  FilterIcon,
  XIcon,
  Tags,
  Location,
  Card,
  Pick,
  Add,
  Remove,
  Info,
};
