import { PAYMENT_OPTIONS } from 'app/shoppingCart/constants';

const initialState = {
  paymentMethod: PAYMENT_OPTIONS.ON_DELIVERY,
  client: {
    firstName: '',
    lastName: '',
    phone: '',
  },
  order: {
    total: 0,
    taxFee: 0,
    shippingFee: 0,
    orderLocation: {},
    addressInfo: '',
    description: '',
    orderProducts: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'START_NEW_ORDER':
      return {
        ...initialState
      };

    case 'GET_ORDER_OK':
      return {
        ...state,
        order: { ...state.order, ...action.data.order },
        client: { ...state.client, ...action.data.client },
        paymentMethod: Object.values(PAYMENT_OPTIONS)
          .find(opt => opt.methodId === action.data.paymentMethod.methodId),
      };

    case 'ORDER_ADD_PRODUCT': {
      let exists = false;
      const productToAdd = action.data;
      const orderProducts = state.order.orderProducts.map(p => {
        if (p.id === productToAdd.id) {
          exists = true;

          return {
            ...p,
            qty: p.qty + 1,
          };
        }
        return p;
      });

      if (!exists) {
        orderProducts.push({
          ...productToAdd,
          qty: 1,
        });
      }

      const total = getTotal(orderProducts, state.order.shippingFee, state.order.taxFee);
      return { ...state, order: { ...state.order, orderProducts, total } };
    }

    case 'ORDER_REMOVE_PRODUCT': {
      const one = action.one;
      const productToRemove = action.data;
      const orderProducts = state.order.orderProducts.reduce((products, p) => {
        if (p.id === productToRemove.id) {
          if (one && p.qty > 1) {
            products.push({
              ...p,
              qty: p.qty - 1,
            });
          }
        } else {
          products.push(p);
        }
        return products;
      }, []);

      const total = getTotal(orderProducts, state.order.shippingFee, state.order.taxFee);
      return { ...state, order: { ...state.order, orderProducts, total } };
    }

    case 'ORDER_SAVED': {
      return { ...state, order: { ...state.order, ...action.data } };
    }

    case 'SET_ORDER_LOCATION': {
      return { ...state, order: { ...state.order, orderLocation: action.data } };
    }

    case 'SET_PAYMENT_METHOD': {
      return { ...state, paymentMethod: action.data };
    }

    case 'SET_CLIENT_INFO': {
      return { ...state, client: { ...state.client, ...action.data } };
    }

    case 'SET_ADDRESS_INFO': {
      return { ...state, order: { ...state.order, addressInfo: action.data } };
    }

    case 'SET_ORDER_DESCRIPTION': {
      return { ...state, order: { ...state.order, description: action.data } };
    }

    default:
      return state;
  }
};

const getTotal = (orderProducts, shippingFee, taxFee) => {
  const productsTotal = orderProducts.reduce((total, p) => {
    return total + (p.qty * p.price);
  }, 0);

  return productsTotal + shippingFee + taxFee;
}
