const initialState = {
  data: [],
  activePage: null
}

export default (state = initialState, action) => {
    switch(action.type) {
      case 'GET_FB_PAGES_OK':
        return {
          data: [...action.data],
          activePage: {...state.activePage}
        }
      case 'SET_ACTIVE_PAGE_WAIT':
        return {
          data: [...state.data],
          activePage: {
            data: null,
            status: 'Connecting'
          }
        }
      case 'SET_ACTIVE_PAGE_OK':
        return {
          data: [...state.data],
          activePage: action.data,
        }
      case 'SET_LOCAL_ACTIVE_PAGE_OK':
        return {
          activePage: action.page || {...state.activePage},
          data: action.pages || [...state.data]
        }

      default:
        return state
    }
}
