import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import moment from 'moment';
import shared from 'app/shared';
import {NavLink} from 'react-router-dom';
import crudTypes from '../../../config/crudTypes';
import {getAll} from '../../../actions/crudActions';
import products from '../../products';
import clients from '../../clients';
import orders from '../../orders';

const {
  views: {
    EditClient
  }
} = clients;

const {
  views: {
    EditProduct
  }
} = products;

const {
  views: {
    EditOrder
  }
} = orders;

const {
  views: { Card, TabBar, Table, Button },
  helpers: { colors },
} = shared;

const tabs = [
  {
    text: 'Top Orders',
    value: 'orders',
  },
  {
    text: 'Top Clients',
    value: 'clients',
  },
  {
    text: 'Top Products',
    value: 'products',
  },
];

const TopBarCard = styled(Card)`
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`;

const MobileCard = styled(Card)`
  display: none;

  @media all and (max-width: 1024px) {
    display: block;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const getActiveCrudType = (currentTab) =>{
  switch(currentTab){
    case 'orders':
      return crudTypes.order
    case 'products':
      return crudTypes.product
    case 'clients':
      return crudTypes.client
    default:
      return crudTypes.order
  }
}

const TopSection = (props) => {
  const [currentTab, updateCurrentTab] = useState('orders');
  const [actions] = useState({
    getAll: props.getAll,
  });

  useEffect(() => {
    actions.getAll(getActiveCrudType(currentTab));
  }, [currentTab, actions]);

  const getTopData = () => {
    const getCondition = () =>{
      switch(currentTab){
        case 'orders':
          return 'totalValue';
        case 'clients':
          return 'totalSum';
        case 'products':
          return 'totalQuantity';
        default:
          return 'id'
      }
    }

    const getValues = (currentTab) => {
      const values = {...props[currentTab]};

      switch(currentTab){
        case 'orders':
          values.data = values.data.filter(order => order.orderStatus === 1); // payed orders only
        break;

        default:
          // values ok
      }

      return values;
    }

    const values = getValues(currentTab);

    if( !( values.data  && values.data.length > 0))
      return [];

    values.data.sort((a, b) => {
      const attribute = getCondition();
      return Number(b[attribute]) - Number(a[attribute]);
    });

    const vals = values.data.slice(0, 5);
    return vals;
  }

  const getTableData = () => {
    switch(currentTab)
    {
      case 'orders':
        return {
            header: [
              {
                label: 'NAME',
                key: 'name',
                sortable: false,
                renderer: ({clientName, clientLastName}) => `${clientName} ${clientLastName}`,
              },
              {
                label: 'QUANTITY',
                key: 'orderProducts',
                sortable: false,
                renderer: ({ orderProducts }) => {
                  const qty = orderProducts.reduce((qty, order) => qty + order.qty, 0);
                  return (
                    <Button.Button
                      text={qty}
                      size={Button.ButtonSize.medium}
                      style={{
                        backgroundColor: colors.lightBlue,
                        fontWeight: 'bold',
                        fontSize: '12px',
                        lineHeight: '14px',
                        textAlign: 'center',
                        color: colors.blue,
                      }}
                    />
                  );
                },
              },
              {
                label: 'VALUE',
                key: 'value',
                sortable: false,
                renderer: ({ totalValue }) => `$${totalValue}`,
              },
              {
                label: 'DATE CREATED',
                key: 'orderDate',
                sortable: false,
                renderer: ({orderDate}) => moment(orderDate).format('YYYY-MM-DD HH:mm'),
              },
              {
                label: 'ACTION',
                width: '70px',
                key: 'action',
                sortable: false,
                renderer: ({id}) => {
                  return (
                    <NavLink to={`${EditOrder.path}/${id}`} >
                      <Button.Button
                        style={{ fontWeight: 600 }}
                        text={"View"}
                        size={Button.ButtonSize.medium}
                        color={colors.blue}
                      />
                    </NavLink>
                  );
                },
              },
            ],
            values: getTopData(),
      }
      case 'products':
        return {
          header: [
            {
              label: 'NAME',
              key: 'name',
              sortable: false,
            },
            {
              label: 'PRICE',
              key: 'price',
              sortable: false,
              renderer: ({ price }) => `$${price}`,
            },
            {
              label: 'QUANTITY',
              key: 'quantity',
              sortable: false,
              renderer: ({totalQuantity}) => (
                <Button.Button
                  text={totalQuantity}
                  size={Button.ButtonSize.medium}
                  style={{
                    backgroundColor: colors.lightBlue,
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '14px',
                    textAlign: 'center',
                    color: colors.blue,
                  }}
                />
              ),
            },
            {
              label: 'CATEGORY',
              key: 'categoryName',
              sortable: false,
              renderer: ({ categoryName }) => categoryName,
            },
            {
              label: 'DESCRIPTION',
              key: 'description',
              sortable: false,
              renderer: ({description}) => description,
            },
            {
              label: 'ACTION',
              width: '70px',
              key: 'action',
              sortable: false,
              renderer: ({id}) => {
                return (
                  <NavLink to={`${EditProduct.path}/${id}`}  style={{ textDecoration: 'none' }}>
                    <Button.Button
                      style={{ fontWeight: 600 }}
                      text={"View"}
                      size={Button.ButtonSize.medium}
                      color={colors.blue}
                    />
                  </NavLink>
                );
              },
            },
          ],
          values: getTopData(),
      }
      case 'clients':
        return {
          header: [
            {
              label: 'NAME',
              key: 'clientName',
              sortable: false,
              renderer: ({name, surname}) => name + " " + surname,
            },
            {
              label: 'ORDERS',
              key: 'orderCount',
              sortable: false,
              renderer: ({orderCount}) => (
                <Button.Button
                  text={orderCount}
                  size={Button.ButtonSize.medium}
                  style={{
                    backgroundColor: colors.lightBlue,
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '14px',
                    textAlign: 'center',
                    color: colors.blue,
                  }}
                />
              ),
            },
            {
              label: 'VALUE',
              key: 'totalSum',
              sortable: false,
              renderer: ({ totalSum }) => `$${totalSum}`,
            },
            {
              label: 'EMAIL',
              key: 'email',
              sortable: false,
              renderer: ({ email }) => email,
            },
            {
              label: 'PHONE',
              key: 'phone',
              sortable: false,
              renderer: ({ phone }) => phone,
            },
            {
              label: 'LOCATION',
              key: 'location',
              sortable: false,
              renderer: ({location}) => location,
            },
            {
              label: 'ACTION',
              width: '70px',
              key: 'action',
              sortable: false,
              renderer: ({id}) => {
                return (
                  <NavLink to={`${EditClient.path}/${id}`}  style={{textDecoration: 'none'}}>
                    <Button.Button
                      style={{ fontWeight: 600 }}
                      text="Edit"
                      size={Button.ButtonSize.medium}
                      color={colors.blue}
                    />
                  </NavLink>
                );
              },
            },
          ],
          values: getTopData(),
        }
      default:
        return {
            header: [
              {
                label: 'NAME',
                key: 'fullName',
                sortable: true,
              },
              {
                label: 'QUANTITY',
                key: 'quantity',
                renderer: ({ quantity }) => `$${quantity}`,
                sortable: true,
              },
              {
                label: 'DATE CREATED',
                key: 'dateCreated',
                sortable: true,
              },
              {
                label: 'ACTION',
                width: '70px',
                key: 'action',
                sortable: false,
                renderer: () => {
                  return (
                    <Button.Button
                      style={{ fontWeight: 600 }}
                      text={"View"}
                      size={Button.ButtonSize.medium}
                      color={colors.blue}
                    />
                  );
                },
              },
            ],
            values: getTopData(),
      }
    };
  }

  const getTableDataTablet = () => {
    switch(currentTab)
    {
      case 'orders':
        return {
            header: [
              {
                label: 'NAME',
                key: 'clientName',
                sortable: false,
                renderer: ({clientName}) => clientName,
              },
              {
                label: 'VALUE',
                key: 'value',
                renderer: ({ totalValue }) => `$${totalValue}`,
                sortable: false,
              },
              {
                label: 'ACTION',
                width: '70px',
                key: 'action',
                sortable: false,
                renderer: ({id}) => {
                  return (
                    <NavLink to={`${EditOrder.path}/${id}`} >
                      <Button.Button
                        style={{ fontWeight: 600 }}
                        text={"View"}
                        size={Button.ButtonSize.medium}
                        color={colors.blue}
                      />
                    </NavLink>
                  );
                },
              },
            ],
            values: getTopData(),
      }
      case 'products':
        return {
          header: [
            {
              label: 'NAME',
              key: 'name',
              sortable: true,
            },
            {
              label: 'PRICE',
              key: 'price',
              renderer: ({ price }) => `$${price}`,
              sortable: true,
            },
            {
              label: 'ACTION',
              width: '70px',
              key: 'action',
              sortable: false,
              renderer: ({id}) => {
                return (
                  <NavLink to={`${EditProduct.path}/${id}`}  style={{ textDecoration: 'none' }}>
                    <Button.Button
                      style={{ fontWeight: 600 }}
                      text={"View"}
                      size={Button.ButtonSize.medium}
                      color={colors.blue}
                    />
                  </NavLink>
                );
              },
            },
          ],
          values: getTopData(),
      }
      case 'clients':
        return {
          header: [
            {
              label: 'NAME',
              key: 'clientName',
              renderer: ({name, surname}) => name + " " + surname,
              sortable: true,
            },
            {
              label: 'PHONE',
              key: 'phone',
              renderer: ({ phone }) => phone,
              sortable: true,
            },
            {
              label: 'ACTION',
              width: '70px',
              key: 'action',
              sortable: false,
              renderer: ({id}) => {
                return (
                  <NavLink to={`${EditClient.path}/${id}`}  style={{textDecoration: 'none'}}>
                    <Button.Button
                      style={{ fontWeight: 600 }}
                      text="Edit"
                      size={Button.ButtonSize.medium}
                      color={colors.blue}
                    />
                  </NavLink>
                );
              },
            },
          ],
          values: getTopData(),
        }
      default:
        return {
            header: [
              {
                label: 'NAME',
                key: 'fullName',
                sortable: true,
              },
              {
                label: 'QUANTITY',
                key: 'quantity',
                renderer: ({ quantity }) => `$${quantity}`,
                sortable: true,
              },
              {
                label: 'DATE CREATED',
                key: 'dateCreated',
                sortable: true,
              },
              {
                label: 'ACTION',
                width: '70px',
                key: 'action',
                sortable: false,
                renderer: () => {
                  return (
                    <Button.Button
                      style={{ fontWeight: 600 }}
                      text={"View"}
                      size={Button.ButtonSize.medium}
                      color={colors.blue}
                    />
                  );
                },
              },
            ],
            values: getTopData(),
      }
    };
  }

  const updateState = (value) =>{
    updateCurrentTab(value);
  }

  return (
    <TopWrapper>
      <TabBar
        tabs={tabs}
        selectedElement={currentTab}
        updateSelectedElement={value => updateState(value)}
      />
      <TopBarCard style={{ marginTop: 10 }}>
        <Table.TableView data={getTableData()} />
      </TopBarCard>

      <MobileCard style={{ marginTop: 10 }}>
        <Table.TableView data={getTableDataTablet()} />
      </MobileCard>
    </TopWrapper>
  );
};

const mapStateToProps = state => ({
  clients: state.clients,
  products: state.products,
  orders: state.orders,
});

export default connect(
  mapStateToProps,
  {getAll }
)(TopSection)

export {TopSection};
