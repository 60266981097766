import axios from 'axios';
import logout from 'app/shared/helpers/logout'

export default () => {
  axios.interceptors.request.use((config) => {
    if (undefined === config.headers.Authorization) {
      let token = localStorage.getItem('authToken');

      if (undefined !== token) {
        token = `Bearer ${token}`;
        config.headers.Authorization = token;
      }
    }

    if (undefined === config.headers.Page) {
      let activePage = JSON.parse(localStorage.getItem('Page'));
      config.headers.Page = activePage || '';
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && ( error.response.status === 401 || error.response.status === 403)) {
        logout();
      }
      return Promise.reject(error);
    },
  );
}
