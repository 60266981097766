import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';
import shared from 'app/shared';
import StatCard from './statCard';
import { genericFilter} from '../../shared/helpers/graphDataFormater';

const {
  views: { Icons, RadioGroup },
  helpers: { colors }
} = shared;

const StatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media all and (min-width: 768px) and (max-width: 1024px) { 
    flex-direction: column;
    justify-content: space-around;
  }

  @media all and (max-width: 768px) { 
    flex-direction: column;
  }
`;

const ChartWrapper = styled.div`
  width: 600px;
  height: 350px;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  padding: 16px;
  flex-shrink: 1;
  padding-right: 10;
  flex-grow: 1;

  @media all and (max-width: 1024px) { 
    width: 100%;
  }

 
`;

const StatsCardWrapper = styled.div`
  display: inline-block;
  margin-bottom: 20px;

  :last-child {
    margin-bottom: 0;
  }

  @media all and (max-width: 768px) { 
    flex-direction: column;
    justify-content: space-between;
    
    :last-child {
      margin-bottom: 20px;
    }
  }
`;

const StatsCardColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-right: 24px;

  @media all and (min-width: 768px) and (max-width: 1024px) { 
    flex-direction: row;
    justify-content: space-between;
    margin-right: 0px;
  }

  @media all and (max-width: 768px) { 
    flex-direction: column;
    justify-content: space-between;
    margin-right: 0px;
  }
`;

const ChartLabelWrapper = styled.div`
  padding-left: 47px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

`;

const getOptions = () => {
  return {
    legend: {
      display: false,
      position: "top",
      fontSize: 20
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            color: '#EBEDF4'
          },
          scaleLabel: {
            display: true
          },
          ticks: {
            min: 0
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false
          },
          scaleLabel: {
            display: true
          }
        }
      ]
    }
  };
};

const sumData = (input) => {
  return input.data.reduce((a, b) => a + b, 0)
}

let graphs = {
  clients: [],
  orders: [],
  revenue: [],
}

class StatsSection extends Component {
  state = {
    graphData: {},
    totalClients: 0,
    totalOrders: 0,
    totalRevenues: 0
  };

  componentDidMount() {
  }

 static getDerivedStateFromProps(props) {
    graphs.clients = genericFilter(
        props.selectedPeriod,
        props.totalClients,
        props.customDates,
        'creationDate',
      );

    graphs.orders = genericFilter(
      props.selectedPeriod, 
      props.totalOrders, 
      props.customDates,
      'orderDate');
  
    graphs.revenue = genericFilter(
      props.selectedPeriod, 
      props.totalOrders, 
      props.customDates,
      'orderDate',
      'totalValue');
  
    return {
      graphData: graphs[props.selectedElement],
      totalClients: sumData(graphs.clients),
      totalOrders: sumData(graphs.orders),
      totalRevenues: sumData(graphs.revenue)
    };
  }

  render() {
    const options = getOptions()
    const {
      selectedElement,
      updateSelectedElement
    } = this.props;

    const {
      totalClients,
      totalOrders,
      totalRevenues
    } = this.state; 

    return (
      <StatsWrapper>
        <RadioGroup selectedElement={selectedElement} updateSelectedElement={updateSelectedElement}>
          <StatsCardColumn>
            <StatsCardWrapper>
              <RadioGroup.Element
                Element={StatCard}
                title={`${'$'}${totalRevenues}`}
                subTitle={'Revenue'}
                Icon={Icons.Revenue}
                value={'revenue'}
              />
            </StatsCardWrapper>
            <StatsCardWrapper>
              <RadioGroup.Element
                Element={StatCard}
                title={totalClients}
                subTitle={'Clients'}
                Icon={Icons.Clients}
                value={'clients'}
              />
            </StatsCardWrapper>
            <StatsCardWrapper>
              <RadioGroup.Element
                Element={StatCard}
                title={totalOrders}
                subTitle={'Orders'}
                Icon={Icons.Orders}
                value={'orders'}
              />
            </StatsCardWrapper>
          </StatsCardColumn>
          <ChartWrapper ref={this.ref}>
            <ChartLabelWrapper> {selectedElement.replace(/^\w/, c => c.toUpperCase())} </ChartLabelWrapper>
            <Bar
              ref="chart"
              data={{
                labels: [...(this.state.graphData.labels || [])],
                datasets: [
                  {
                    label: `${selectedElement}`,
                    fill: false,
                    backgroundColor: 'rgba(0, 108, 203,1)',
                    borderColor: 'rgba(0, 108, 203,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    data: [...(this.state.graphData.data || [])],
                  },
                ],
              }}
              options={options}
            />
          </ChartWrapper>
        </RadioGroup>
      </StatsWrapper>
    );
  }
}

export default StatsSection;
