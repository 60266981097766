import Icons from '../views/icons';

export default {
  navBar: [
    {
      Icon: Icons.Dashboard,
      to: '/admin/dashboard',
      text: 'Dashboard',
    },
    {
      Icon: Icons.Clients,
      to: '/admin/clients',
      text: 'Clients',
    },
    {
      Icon: Icons.Info,
      to: '/admin/helpCenter',
      text: 'Help Center',
    },
    {
      Icon: Icons.Chatbot,
      to: '/admin/messenger',
      text: 'Messenger',
    },
  ],
};
