import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import shared from 'app/shared';
import { NavLink } from 'react-router-dom';
import AddClientPopup from './addClientPopup';
import EditClient from './editClient';
import crudTypes from '../../../config/crudTypes';
import { getAll} from '../../../actions/crudActions';
import { connect } from 'react-redux';
import { paginate } from '../../../config/helpers/paginator';
import { getSortState, sortData } from '../../../config/helpers/sorter';
import { filterData } from '../../../config/helpers/searcher';
import { genericFilter } from '../../shared/helpers/filterFunctions';
import MobileFilter from '../../shared/views/mobileFilter/mobileFilter';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';

const {
  views: {
    Table,
    Button,
    Paginator,
    Page,
    Input,
    Icons,
    Card,
    FilterableTableStructure,
    SimpleFilterBar,
  },
  helpers: { resetScroll, colors },
} = shared;

const {
  StatsSectionWrapper,
  ActionBarWrapper,
  SearchWrapper,
  TableWrapper,
  PaginatorWrapper,
} = FilterableTableStructure;

const initialState = {
  filters: {
    selectedPeriod: 'today',
  },
  sort: {
    key: 'creationDate',
    order: -1,
  },
  currentPage: 1,
  customDate: {startDate: null, endDate: null},
  pageSize: 10,
  search: '',
  redirect: false,
  getStatus: ''
};

const TableWrapperTablet = styled(Card)`
  display: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin-top: 25px;
  }
  @media all and (max-width: 768px) {
    display: block;
    margin-top: 25px;
  }
`;

  const ButtonWrapper = styled.div`
    @media all and (max-width: 768px) {
      display: none;
    }
  `;

class ClientsPage extends React.Component {
  state = initialState;

  setGetStatus = (status) => {
    this.setState({getStatus: status})
  }

  componentWillMount() {
    this.props.getAll(crudTypes.client, this.setGetStatus);
    this.props.getAll(crudTypes.order, this.setGetStatus);
  }

  componentDidMount() {
    resetScroll();
  }

  setSearchValue = val => {
    this.setState({ search: val.trim(), currentPage: 1 });
  };

  updateSortState = key => {
    this.setState(prevState => ({
      sort: getSortState(prevState.sort, key),
    }));
  };

  getTableData = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'FULL NAME',
        key: 'fullName',
        renderer: ({ fullName }) => fullName,
        sortable: true,
      },
      {
        label: 'EMAIL',
        key: 'email',
        renderer: ({ email }) => email,
        sortable: true,
      },
      {
        label: 'PHONE',
        key: 'phone',
        renderer: ({ phone }) => phone,
        sortable: true,
      },
      {
        label: 'LOCATION',
        key: 'location',
        renderer: ({ locations }) => (locations.length > 0 && locations[0].locationName),
        sortable: true,
      },
      {
        label: 'DATE CREATED',
        key: 'creationDate',
        sortable: true,
        renderer: ({ creationDate }) => creationDate && moment(creationDate).format('YYYY-MM-DD HH:mm'),
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'action',
        sortable: false,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditClient.path}/${id}`}>
              <Button.Button
                style={{ fontWeight: 600 }}
                text="Edit"
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  getTableDataTablet = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'FULL NAME',
        key: 'fullName',
        renderer: ({ fullName }) => fullName,
        sortable: true,
      },
      {
        label: 'PHONE',
        key: 'phone',
        renderer: ({ phone }) => phone,
        sortable: true,
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'action',
        sortable: false,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditClient.path}/${id}`}>
              <Button.Button
                style={{ fontWeight: 600 }}
                text="Edit"
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  updateSelectedPeriod = selectedPeriod => {
    if (selectedPeriod !== 'custom') {
      this.setState({
        customDate: {
          startDate: null,
          endDate: null
        },
      });
    }
    this.setState(prevState => ({ filters: { ...prevState.filters, selectedPeriod }, currentPage: 1 }));
  };

  updateCustomDate = customDate => {
    this.setState({customDate: {startDate: customDate.startDate, endDate: customDate.endDate}, currentPage: 1 })
  }

  toggleAddPopup = () => this.setState({ showEditPopup: !this.state.showEditPopup });

  clearFilters = () => {
    this.setState({ filters: initialState.filters })
    this.setState({ customDate: {startDate: null, endDate: null}, currentPage: 1 })
  };

  updatePaginatorPage = currentPage => {
    this.setState({ currentPage });
  };

  toggle = () => this.setState({ toggleValue: !this.state.toggleValue });

  createExportCSV = (data) => {
    return () => {
      CSVFileExporter('clients.csv', data);
    };
  };

  render() {
    const {
      state: {
        filters: { selectedPeriod },
        currentPage,
        pageSize,
        sort,
        search,
        showEditPopup,
        customDate,
      },
      updateSelectedPeriod,
      clearFilters,
      updatePaginatorPage,
      toggleAddPopup,
      updateCustomDate,
      createExportCSV,
    } = this;

    const timeFilteredData = genericFilter(
      selectedPeriod, 
      this.props.clients.data, 
      customDate,
      "creationDate",
      null);

    const searchData = filterData(timeFilteredData, search);
    const sortedData = sortData(searchData, sort);
    const paginatedData = paginate(sortedData, currentPage, pageSize);



    return (
      <Page>
        <AddClientPopup show={showEditPopup} toggle={toggleAddPopup} />
        <SimpleFilterBar
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
          updateCustomDate={updateCustomDate}
          customDate={customDate}
        />
        <MobileFilter
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
        />
        <StatsSectionWrapper>
          <ActionBarWrapper>
            <ButtonWrapper>
              <Button.Button
                color={colors.blue}
                text={'Add New'}
                onClick={toggleAddPopup}
                style={{ marginRight: 5 }}
              />
              <Button.WhiteButton
                color={colors.textColor}
                style={{ marginRight: 5 }}
                text={'Export CSV'}
                onClick={createExportCSV(sortedData)}
              />
            </ButtonWrapper>
            <SearchWrapper>
              <Input
                start={<Icons.Search />}
                placeholder={'Search'}
                height={33}
                onChange={e => this.setSearchValue(e.target.value)}
              />
            </SearchWrapper>
          </ActionBarWrapper>
          <TableWrapper>
            <Table.TableView data={this.getTableData(paginatedData.current)} />
          </TableWrapper>
          <TableWrapperTablet>
            <Table.TableView data={this.getTableDataTablet(paginatedData.current)} />
          </TableWrapperTablet>
          <PaginatorWrapper>
            <Paginator
              changePage={updatePaginatorPage}
              numberOfPages={paginatedData.pages}
              page={paginatedData.currentPage}
            />
          </PaginatorWrapper>
        </StatsSectionWrapper>
      </Page>
    );
  }
}

ClientsPage.path = '/clients';

const mapStateToProps = state => ({
  clients: state.clients,
});

export default connect(
  mapStateToProps,
  {
    getAll,
  },
)(ClientsPage);

export { ClientsPage };
