import React from 'react';
import styled from 'styled-components';
import FilterBar from 'app/shared/views/filterBar';
import DatePicker from 'app/shared/views/dateRangeFilter';
import SelectOption from 'app/shared/views/selectOption';
import colors from 'app/shared/helpers/colors';


const FilterByPeriodWrapper = styled.div`
  margin-right: 30px;
  display: inline;
`;

const FilterBarItem = styled.div`
  // margin-left: 20px;
  display: inline-flex;
  align-items: flex-start;
  height: 100%;
`;

// const FilterBarItemName = styled(FilterBar.Components.Title)`
//   font-size: 13px;
//   line-height: 19px;
//   color: #748AA1;
// `;

const FilterBarSelect = styled(SelectOption)`
  width: ${props => props.width ? `${props.width}px` : null};
`;

const FilterBarContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-betweeen;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;

  & > * {
    padding-right: 20px;
  }
`;

const FilterBarDatePicker = styled(DatePicker)`
  margin-top: -5px;
`;

const FilterBarSelectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'unset',
    border: 'none',
    boxShadow: 'none',
    fontSize: '13px',
    lineHeight: '16px',
    alignItems: 'flex-start',
    minHeight: '32px',
    color: colors.darkestGrey,
    marginTop: '-3px',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: '13px',
      lineHeight: '16px',
    };
  },
  indicatorSeparator: (styles) => ({display:'none'}),
};

const PaymentStatusOptions = [
  { value: 'pending', label: 'Pending' },
  { value: 'payed', label: 'Payed' },
  { value: 'cancelled', label: 'Cancelled' },
];

const SimpleFilterBar = ({ selectedPeriod, updateSelectedPeriod = null, clearFilters, updateCustomDate, customDate, selectedPaymentStatus, updateSelectedPaymentStatus }) => {
  return (
    <FilterBar>
      <FilterBar.Start>
        <FilterBarContent>
          <FilterBarItem>
            <FilterBar.Components.RadioGroup
              selectedElement={selectedPeriod}
              updateSelectedElement={updateSelectedPeriod}
            >
              <FilterByPeriodWrapper>
                <FilterBar.Components.Title>Filter by Period</FilterBar.Components.Title>
              </FilterByPeriodWrapper>
              <FilterBar.Components.RadioElement value={'today'}>
                Today
              </FilterBar.Components.RadioElement>
              <FilterBar.Components.RadioElement value={'last-week'}>
                Last week
              </FilterBar.Components.RadioElement>
              <FilterBar.Components.RadioElement value={'last-month'}>
                Last Month
              </FilterBar.Components.RadioElement>
              <FilterBar.Components.RadioElement value={'total'}>
                Total
              </FilterBar.Components.RadioElement>
            </FilterBar.Components.RadioGroup>
          </FilterBarItem>

          <FilterBarItem>
            {/* <FilterBarItemName>Payment Method:</FilterBarItemName> */}
            <FilterBarSelect
              width="140"
              placeholder="Filter By Status"
              customStyles={FilterBarSelectStyles}
              options={PaymentStatusOptions}
              onChange={option => { updateSelectedPaymentStatus(option.value) }}
              isSearchable={'false'}
              name={'revenue'}
              value={PaymentStatusOptions.find(option => option.value === selectedPaymentStatus) || null}
            />
          </FilterBarItem>
        </FilterBarContent>
      </FilterBar.Start>
      <FilterBar.End>
        <FilterBarContent>
          <FilterBarItem>
            <FilterBarDatePicker
              selectedElement={selectedPeriod}
              updateSelectedElement={updateSelectedPeriod}
              updateCustomDate={updateCustomDate}
              customDate={customDate}
              clearFilters={clearFilters}
            />
          </FilterBarItem>
          <FilterBarItem>
            <div style={{marginLeft: 20}}>
              <FilterBar.Components.ClearFilters onClick={clearFilters} />
            </div>
          </FilterBarItem>
        </FilterBarContent>
      </FilterBar.End>
    </FilterBar>
  );
};

export default SimpleFilterBar;
