import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import crossImage from '../../../assets/icons/cross.button.svg';

const{
  views: {
    Text:{ Paragraph: { Paragraph } },
  },
  helpers: {
    colors,
  },
} = shared;

const MainWrapper = styled.div`
  min-height: 50px;
  max-height: 100px;
  display: flex;
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 5px;
`;

const ButtonsWrapper = styled.div`
  flex-wrap: wrap;
  overflow: scroll;
  flex-direction: row;
  overflow: auto;
  display: inline-flex;
  max-height: 150px;
  min-width: 96%;
`;

const ParagraphWrapper = styled.div`
  display: inline-flex;
  width: 4%;
  
`;

const DropDownWrapper = styled.button`
  offset: none;
   background-color: white;
   outline: none;
   position: absolute;
   width: 160px;
   max-width: 500px;
   max-height: 200px; 
   border: 1px solid ${colors.lightGrey};
   overflow: auto;
   flex-grow: 1;
   z-index: 1;
   background-color: white;
   box-shadow: 3px 3px ${colors.lightGrey};
`;


const Input = styled.input`
  ::-webkit-calendar-picker-indicator {
    display: none;
  }
    flex-grow: 1;
    display:flex;
    padding: 10px 5px; 
    flex-direction: column; 
    min-width: 15%;
    outline: none;
    border: none;
    width: 100%;
    font-size: 20px;
    color: ${colors.veryDarkGrey};
`;

const FloatingMenuWrapper =styled.div`
  display: none;
`;

const Label = styled.div`
  :focus-within{
      ${FloatingMenuWrapper}{
        display: block;
      }
   }
`;

const Item = styled.div`
  outline: none;
  border: none;
  background-color: white;
  display: inline-flex;
  font-size: 17px;
  color: ${colors.veryDarkGrey};
  font-family: Inter, sans-serif;
  font-weight: 500;
  flex-wrap: wrap; 
  width: 100%; 
  margin:5px; 
  padding: 5px;
  text-align: center;
  :active{
    background-color: ${colors.lightBlue};
  }
  
`;

const ItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flexGrow: 1;s 
  margin: 5px;
  padding: 5px;
`;

class MessageToSection extends React.Component{

  state={
    focus: true,
    text: '',
    suggestions: []
  };

  constructor(props){
    super(props);
    this.section = React.createRef();
    this.input = React.createRef();
    this.float = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
    this.focusInput();
  }


  componentDidUpdate() {
    this.scrollToBottom();
    // this.focusInput();
  }

  updateText = (text)=>{
    if(text === '')this.input.current.value = '';
    const suggestions = this.props.getSuggestions(text);
    this.setState({text: text, suggestions: suggestions});
  }

 

  scrollToBottom() {
    const offsetTop = this.section.current.offsetTop;
    const offsetHeight = this.section.current.offsetHeight;
    const scrollHeight = this.section.current.scrollHeight;
    this.section.current.scrollTop = scrollHeight;

    if(this.float.current != null){
     this.float.current.style.top = `${offsetTop + offsetHeight}px`;
    }

  }

  focusInput(){
      this.input.current.focus();
  }

  keyPressAction=(event)=>{

     // console.log(event);
      const text = event.target.value;
      this.updateText(text);
      let x = event.which || event.keyCode;

      if(x === 8 && text===''){
        this.props.onClickExecute(this.props.getSelectedNames()[this.props.getSelectedNames().length - 1]);
        return;
      }
      //pushing element
      if(x === 13){
        this.selectDefault();
        this.updateText('');
      }
  }

  selectDefault=()=>{
    if(this.state.suggestions.length === 0)return;
    this.props.onClickExecute(this.state.suggestions[0]);
  }

  

  updateCurrentElement=(el)=>{
    this.updateText('');
    this.props.onClickExecute(el);
  }

  render(){
   console.log('[Selected names ===> ]', this.props.getSelectedNames())
   return (
    <MainWrapper>
        <ParagraphWrapper>
         <Paragraph>To: </Paragraph>
        </ParagraphWrapper>
        <ButtonsWrapper ref={this.section} contenteditable={'true'}>
          {
            this.props.getSelectedNames().map(el =>
              <NameButton
                key={el.id}
                name={el.fullName}
                onClickExecute={()=> this.props.onClickExecute(el)}
              />
            )
          }
          <Label style={{ display: 'block', margin: '2px 10px'}}>
            <Input type={'text'}  ref={this.input} onKeyDown={this.keyPressAction} />
            {this.state.suggestions.length > 0 ?
              <FloatingMenuWrapper>
                <DropDownWrapper ref={this.float}>
                  <ItemList>
                    {this.state.suggestions.map(s =>
                      <Item key={s.id} onClick={()=>this.updateCurrentElement(s)}>{s.fullName}</Item>
                    )}
                  </ItemList>
                </DropDownWrapper>
              </FloatingMenuWrapper> : null
            }
          </Label>
        </ButtonsWrapper>
      </MainWrapper>
   );
  }
};


const Image = styled.img`
  width: 20px;
  margin-left: 4px;
  margin-right: 3px;

  :active{
    opacity: 0.6;
  }
 
`;

const ButtonWrapper = styled.div`
   padding: 0;
   margin: 5px;
   padding: 0px;
   font-family: Muli;
   height: 36px;
   min-height: 12px;
   min-width: 50px;
   display: flex;
   flex-wrap: wrap;
   border: 1px solid ${colors.lightGrey};
   color: ${colors.veryDarkGrey}; 
   vertical-align: middle;
   border-radius: 3px;
`;

const TextWrapper = styled.div`
  display: inline-flex;
  padding: 5px;
`;

const ImageWrapper = styled.div`
  display: flex;
  vertical-align: middle;
  text-align: baseline;
`;

const CrossButton = () => (
  <ImageWrapper>
    <Image src={crossImage} />
  </ImageWrapper>
);

const NameButton = ({name, onClickExecute, ...rest}) => (
  <ButtonWrapper onClick={onClickExecute}>
    <CrossButton />
    <TextWrapper >
      <Paragraph style={{color: colors.veryDarkGrey}}>{name}</Paragraph>
    </TextWrapper>
  </ButtonWrapper>

);

export default MessageToSection;
