import React, {Component} from 'react';
import MessageToSection from '../components/messageTo';
import MessageBodySection from '../components/messageBody';
import MessageInputSection from '../components/messageInput';

class MessageWindow extends Component {
  state={
    messages: []
  };

  getSelectedNames=()=>{

    const vals = [];
    this.props.keys.filter(key => !!key).forEach(e => {
      const val = this.props.allData.find(x => x.id === e);
      if (val) vals.push(val);
    });

    return vals;
  }
  

  getSuggestions=(text)=>
  (text === '')?
    []: this.props.data.filter(e => e.fullName.toLowerCase().indexOf(text.toLowerCase()) >= 0 && this.props.keys.indexOf(e.id) === -1).slice(0, 15);


  addMessage = message => {
    const newMessages = [...this.state.messages, message];
    this.setState({messages: newMessages});
  }

  render() {
    const {
      props: {
        renderTo = true,
      }
    } = this;

    return (
      <div style={{width: '100%', height: '100%'}}>

          {renderTo ? <MessageToSection
            getSuggestions={this.getSuggestions}
            getSelectedNames={this.getSelectedNames}
            {...this.props}
          /> : null}
          <MessageBodySection
            messages={this.state.messages}
          />
          <MessageInputSection 
            send={this.addMessage}
            getSelectedNames={this.getSelectedNames}
          />
      </div>
    );
  }

}

export default MessageWindow;
