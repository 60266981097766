import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const {
  views: {
    MainView,
    Text: {
      Paragraph: { P },
      H3: { H3 },
    }
  },
  helpers: {
    colors,
  }
} = shared;

const InfoWrapper = styled.div`
  height: 79vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 50px;
  margin-bottom: 10px;

  & > :nth-child(1) {
    margin-bottom: 30px;
  }

  & > :nth-child(2) {
    margin-bottom: 40px;
  }

  & > :nth-child(3) {
    margin-bottom: 20px;
  }

  & > :last-child {
    flex-grow: 1;
  }
`;

const TitleWrapper = styled(H3)`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 22px;

  display: flex;
  align-items: center;
  color: ${props => props.success ? colors.green : colors.red}
`;

const Description = styled(P)`
  width: 80%;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: ${colors.darkGrey};
`;

const GraphicsWrapper = styled.div`
  width: 300px;
  height: 225px;
  display: inline-flex;
`;

const ActionsWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > * {
    margin: 5px 0;
  }
`;

const InfoMainView = styled(MainView)`
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

const InfoPage = ({ title, info, graphicSrc, actions, success }) => {
  return (
    <InfoMainView logoPosition="left" logoPadding="15px 12px" logoSize="tiny" boxAlign="bottom">
      <InfoWrapper>
        <TitleWrapper success={success}>{title}</TitleWrapper>
        <Description>{info}</Description>
        <GraphicsWrapper>
          <img src={graphicSrc} alt='graphic' />
        </GraphicsWrapper>
        <ActionsWrapper>
          {actions}
        </ActionsWrapper>
      </InfoWrapper>
    </InfoMainView>
  );
};

export default InfoPage;
