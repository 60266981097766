import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import { NavLink } from 'react-router-dom';
import productImage from 'assets/images/product_image.png';
import { connect } from 'react-redux';
import ProductCard from './productCard';
import AddProductCard from './addProductCard';
import AddProductPopup from './addProductPopup';
import EditProduct from './editProduct';
import { getAll } from '../../../actions/crudActions';
import crudTypes from '../../../config/crudTypes';
import { paginate } from '../../../config/helpers/paginator';
import { getSortState, sortData } from '../../../config/helpers/sorter';
import { filterData } from '../../../config/helpers/searcher';
import { genericFilter } from 'app/shared/helpers/filterFunctions';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';

const {
  views: {
    Table,
    Button,
    Paginator,
    Page,
    Input,
    Icons,
    Toggle,
    Card,
    FilterableTableStructure,
    RadioView,
    Grid,
    SimpleFilterBar,
    Text,
  },
  helpers: { resetScroll, colors, transition },
} = shared;

const {
  StatsSectionWrapper,
  ToggleWrapper,
  ActionBarWrapper,
  SearchWrapper,
  TableWrapper,
  PaginatorWrapper,
} = FilterableTableStructure;

const initialState = {
  filters: {
    selectedPeriod: 'today',
  },
  customDate: {startDate: null, endDate: null},
  sort: {
    key: 'id',
    order: 1,
  },
  currentPage: 1,
  pageSize: 10,
  toggleValue: true,
  search: '',
  redirect: false,
  getProductStatus: ''
};

const GridWrapper = styled.div`
  padding-top: 30px;
`;


const cardWrapper = Element => styled(Element)`
  transition: ${transition};
  transform: translateY(0);

  :hover {
    cursor: pointer;
    transform: translateY(-5px);
  }
`;

const TableWrapperTablet = styled(Card)`
  display: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin-top: 25px;
  }
  @media all and (max-width: 768px) { 
    display: block;
    margin-top: 25px;
  }
`;

const ButtonWrapper = styled.div`
    @media all and (max-width: 768px) { 
      display: none;
    } 
`;

const CategorySectionTitle = styled(Text.H4)`
  color: ${colors.blue};
  height: 22px;
  line-height: 22px;
`;

const CategorySectionWrapper = styled.div`
  border-bottom: 2px solid ${colors.blue};
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const CategorySection = ({ title, ...rest }) => (
  <CategorySectionWrapper {...rest}>
    <CategorySectionTitle>{title}</CategorySectionTitle>
  </CategorySectionWrapper>
);


const ProductCardWrapper = cardWrapper(ProductCard);
const AddProductCardWrapper = cardWrapper(AddProductCard);

class ProductsPage extends React.Component {
  state = initialState;

  componentWillMount() {
    resetScroll();
    this.loadData();
  }

  setGetProductsStatus = (status) => {
    this.setState({getProductsStatus: status})
  }

  loadData = () => {
    this.props.getAll(crudTypes.product, this.setGetProductsStatus);
    this.props.getAll(crudTypes.category,this.setGetProductsStatus);
  };

  updateSortState = key => {
    this.setState(prevState => ({
      sort: getSortState(prevState.sort, key),
    }));
  };

  getTableData = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'NAME',
        key: 'name',
        sortable: true,
        renderer: ({ name }) => name,
      },
      {
        label: 'PRICE',
        key: 'price',
        renderer: ({ price }) => `$${price}`,
        sortable: true,
      },
      {
        label: 'CATEGORY',
        key: 'categoryName',
        renderer: ({ categoryName }) => categoryName,
        sortable: true,
      },
      {
        label: 'STATUS',
        key: 'disabled',
        sortable: false,
        renderer: ({ disabled }) => disabled ? 'Disabled' : 'Enabled',
      },
      {
        label: 'DESCRIPTION',
        key: 'description',
        renderer: ({ description }) => description,
        sortable: false,
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'action',
        sortable: false,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditProduct.path}/${id}`}>
              <Button.Button
                style={{ fontWeight: 600 }}
                text={'View'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
            
          );
        },
      },
    ],
    values: values,
  });

  getTableDataTablet = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'NAME',
        key: 'name',
        sortable: true,
        renderer: ({ name }) => name,
      },
      {
        label: 'PRICE',
        key: 'price',
        renderer: ({ price }) => `$${price}`,
        sortable: true,
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'action',
        sortable: false,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditProduct.path}/${id}`}>
              <Button.Button
                style={{ fontWeight: 600 }}
                text={'View'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  toggleAddPopup = () => this.setState({ showEditPopup: !this.state.showEditPopup });

  clearFilters = () => this.setState({ filters: initialState.filters, currentPage: 1 });

  updatePaginatorPage = currentPage => {
    this.setState({ currentPage });
  };

  setSearchValue = val => {
    this.setState({ search: val.trim(), currentPage: 1 });
  };

  toggle = () => this.setState({ toggleValue: !this.state.toggleValue });

  createExportCSV = (data) => {
    return () => {
      CSVFileExporter('products.csv', data);
    };
  };

  updateSelectedPeriod = selectedPeriod => {
    this.setState(prevState => ({ filters: { ...prevState.filters, selectedPeriod }, currentPage: 1 }));
  };

  updateCustomDate = customDate => {
    this.setState({customDate: {startDate: customDate.startDate, endDate: customDate.endDate}, currentPage: 1})
  }

  clearFilters = () => {
    this.setState({ filters: initialState.filters })
    this.setState({ customDate: {startDate: null, endDate: null}, currentPage: 1 })
  };


  render() {
    const {
      state: {
        currentPage,
        pageSize,
        toggleValue,
        sort,
        search,
        showEditPopup,
        preselectCategory,
        filters: {
          selectedPeriod,
        },
        customDate,
      },
      updatePaginatorPage,
      toggle,
      toggleAddPopup,
      createExportCSV,
      updateSelectedPeriod,
      updateCustomDate,
      clearFilters,
    } = this;

    const data =
      this.props.products.data && this.props.products.data.length > 0
        ? [...this.props.products.data]
        : [];
      
    const timeFilteredData = genericFilter(
      selectedPeriod, 
      data, 
      customDate,
      "creationDate",
      null);

    const searchData = filterData(timeFilteredData, search);
    const sortedData = sortData(searchData, sort);
    const paginatedData = paginate(sortedData, currentPage, pageSize);
    const groupedByCategory = this.props.categories.data.reduce((categories, category) => {
      const products = searchData.filter(product => product.categoryId === category.id);
      categories.push({
        id: category.id,
        name: category.name,
        products,
      });
      return categories;
    }, []);

    return (
      <Page>
        <AddProductPopup show={showEditPopup} toggle={toggleAddPopup} preselectCategory={preselectCategory} />
        <SimpleFilterBar
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
          updateCustomDate={updateCustomDate}
          customDate={customDate}
        />
        <StatsSectionWrapper>
          <ActionBarWrapper>
            <ButtonWrapper>
              <Button.Button
                color={colors.blue}
                text={'Add New'}
                style={{ marginRight: 22 }}
                onClick={() => {
                  this.setState({
                    preselectCategory: null,
                  });
                  toggleAddPopup();
                }}
              />
              <Button.WhiteButton
                color={colors.textColor}
                text={'Export CSV'}
                onClick={createExportCSV(sortedData)}
              />
            </ButtonWrapper>
            <ToggleWrapper>
              <Toggle
                show={toggleValue}
                toggle={toggle}
                showLabel={'Grid'}
                hiddenLabel={'List'}
              />
            </ToggleWrapper>
            <SearchWrapper>
              <Input
                start={<Icons.Search />}
                placeholder={'Search'}
                height={33}
                onChange={e => this.setSearchValue(e.target.value)}
              />
            </SearchWrapper>
          </ActionBarWrapper>
          <RadioView selectedElement={toggleValue === true ? 'grid' : 'table'}>
            <RadioView.Element
              Element={({ reference, }) => (
                <div ref={reference}>
                  <TableWrapper>
                    <Table.TableView data={this.getTableData(paginatedData.current)} />
                  </TableWrapper>
                  <TableWrapperTablet>
                    <Table.TableView data={this.getTableDataTablet(paginatedData.current)} />
                  </TableWrapperTablet>
                  <PaginatorWrapper>
                    <Paginator
                      changePage={updatePaginatorPage}
                      numberOfPages={paginatedData.pages}
                      page={paginatedData.currentPage}
                    />
                  </PaginatorWrapper>
                </div>
              )}
              value={'table'}
            />
            <RadioView.Element
              Element={({ reference, }) => (
                <GridWrapper ref={reference}>
                  {
                    groupedByCategory.map(category => (<div key={category.id}>
                      <CategorySection title={category.name} />
                      <Grid>
                        {category.products.map(product => (
                          <Grid.Section
                            key={product.id}
                            sizes={{
                              desktop: 2,
                              tablet: 4,
                            }}
                          >
                            <NavLink to={`${EditProduct.path}/${product.id}`}>
                              <ProductCardWrapper
                                photo={(product.imagePath || productImage)}
                                price={product.price}
                                sales={product.totalQuantity}
                                title={product.name}
                                disabled={product.disabled}
                              />
                            </NavLink>
                          </Grid.Section>
                        ))}
                        <Grid.Section
                          sizes={{
                            desktop: 2,
                            tablet: 4,
                          }}
                        >
                          <AddProductCardWrapper style={{ minHeight: '130px' }} onClick={() => {
                            this.setState({
                              preselectCategory: category.id,
                            });
                            toggleAddPopup();
                          }} />
                        </Grid.Section>
                      </Grid>
                    </div>))
                  }
                </GridWrapper>
              )}
              value={'grid'}
            />
          </RadioView>
        </StatsSectionWrapper>
      </Page>
    );
  }
}

ProductsPage.path = '/products';

const mapStateToProps = state => ({ products: state.products, facebookPages: state.facebookPages, categories: state.categories });

export default connect(
  mapStateToProps,
  { getAll },
)(ProductsPage);
export { ProductsPage };
