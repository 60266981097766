import React from 'react';
import styled from 'styled-components';
import colors from '../helpers/colors';
import transition from '../helpers/transition';

const RadioElementWrapper = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid ${colors.lightGrey};
  transition: ${transition};
  position: relative;
  border-radius: 9999px;
  box-sizing: border-box;

  :hover {
    border: 2px solid ${colors.blue};
    cursor: pointer;
  }
`;

const Circle = styled.div`
  border-radius: 9999px;
  box-sizing: border-box;
  background-color: ${props => (props.isSelected ? colors.blue : 'transparent')};
  width: ${props => (props.isSelected ? '10px' : '20px')};
  height: ${props => (props.isSelected ? '10px' : '20px')};
  transition: ${transition};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RadioElement = ({
  updateSelectedElement = () => {},
  isSelected = false,
  reference,
  ...rest
}) => (
  <RadioElementWrapper
    className={"radio-element"}
    ref={reference}
    {...rest}
    onClick={updateSelectedElement}
  >
    <Circle isSelected={isSelected} />
  </RadioElementWrapper>
);

export default RadioElement;
